import Close from "@assets/icons/close.svg";
import InputDel from "@assets/icons/input-del.svg";
import Tg from "@assets/icons/tg.svg";
import useNewsletter from "@hooks/useNewsletter";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { NewslettersType } from "@typings/Data";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

function NewsletterRegister({
  choice,
  setChoice,
}: {
  choice: string[];
  setChoice: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const {
    context: {
      payload: { list = [] },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: NewslettersType;
  }> = useRouteContext();
  const [isVisible, setIsVisible] = useState(true);
  const [email, setEmail] = useState("");

  const { message, handleVerifyEmail, setMessage } = useNewsletter();

  const handleRegister = () => {
    const newsletters = choice.join("|");
    handleVerifyEmail(email, newsletters)
      .then(() => {})
      .catch(() => {});
  };

  const handleClick = () => {
    setIsVisible((prev) => !prev);
  };

  const choicedList = list
    .filter((item) => choice.includes(item.oneTrustKey))
    .map((item) => {
      return {
        label: item.label,
        oneTrustKey: item.oneTrustKey,
      };
    });

  useEffect(() => {
    if (!email) {
      setMessage("");
    }
  }, [email, setMessage]);
  return (
    <div
      className={clsx(
        "fixed bottom-0 left-0 right-0 z-[10000] flex w-full flex-col items-center justify-center bg-tertiary",
        "transition-transform duration-1000 ease-out",
      )}
    >
      <button
        className={clsx(
          "box-border flex w-full cursor-pointer items-center justify-center px-[20px] py-[16px] text-header4 text-primary",
          {
            "bg-brand-secondary": !isVisible,
          },
        )}
        onClick={handleClick}
      >
        <p className="flex flex-1 items-center justify-center">
          {isVisible
            ? choice.length
              ? "已选新闻"
              : "您还没有选择任何新闻速递。"
            : `已选${choice.length}个新闻速递`}
        </p>
        <img
          className={clsx("h-[30px] w-[30px]", {
            "rotate-180": !isVisible,
            "transition-transform duration-500 ease-in-out": true,
          })}
          src={Tg}
          alt=""
        />
      </button>
      <section
        className={clsx("flex w-full flex-col items-center", {
          "max-h-[400px] opacity-100": isVisible,
          "max-h-0 opacity-0": !isVisible,
        })}
      >
        <section className="box-border flex flex-wrap justify-center gap-x-[16px] px-[16px] pb-[8px] text-label6 text-secondary lg:w-auto lg:flex-row">
          {choicedList.map((item) => (
            <p
              key={item.oneTrustKey}
              className="flex w-auto flex-nowrap items-center justify-center whitespace-nowrap pb-[8px] text-label6 text-primary"
            >
              {item.label}
              <button
                onClick={() => {
                  setChoice((prev) =>
                    prev.filter((i) => i !== item.oneTrustKey),
                  );
                }}
              >
                <img
                  src={Close}
                  className="h-[24px] w-[24px] cursor-pointer"
                  alt=""
                />
              </button>
            </p>
          ))}
        </section>
        <div
          className={clsx(
            "mb-[16px] box-border flex w-full flex-col  justify-center px-[16px] lg:flex-row",
          )}
        >
          <div className="relative w-full lg:w-[357px]">
            {email && (
              <button
                onClick={() => {
                  setEmail("");
                }}
              >
                <img
                  src={InputDel}
                  className="absolute right-[16px] top-[5px] w-[30px] cursor-pointer"
                  alt="inputDel"
                />
              </button>
            )}
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleRegister();
                }
              }}
              style={{ boxShadow: "0 0 0px 1000px white inset" }}
              placeholder="请输入您的电子邮箱"
              className={clsx(
                "w-full rounded-full border-[1px] bg-[#fff] px-[16px] py-[8px] text-body2 placeholder:text-body2 placeholder:text-input-text-label",
                message && message !== "订阅成功！"
                  ? "border-input-text-error"
                  : "border-divider-primary",
              )}
            ></input>
            <p
              className={clsx(
                "mb-[8px] mt-[4px] box-border w-full px-[16px] text-label5",
                message === "订阅成功！"
                  ? "text-input-text-success"
                  : "text-input-text-error",
              )}
            >
              {message}
            </p>
          </div>

          <button
            onClick={handleRegister}
            className="h-[40px] w-full rounded-full bg-[--button-primary-default] text-button1 text-button-primary-label lg:ml-[8px] lg:w-[115px]"
          >
            免费订阅
          </button>
        </div>
        <p className="box-border flex w-full flex-wrap justify-center border-t border-divider-primary px-[16px] py-[16px] text-label4 text-secondary">
          当您提交以上信息，即表示您已同意我们的
          <a
            href="https://www.sph.com.sg/tnc/"
            className="text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            隐私政策
          </a>
          以及
          <a
            href="https://www.sph.com.sg/tnc/"
            className="text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            条款与条件
          </a>
          。
        </p>
      </section>
    </div>
  );
}

export default NewsletterRegister;
