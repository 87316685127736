import { footerContactItems as defaultStaticPageMenuItems } from "@constants/navigation";
import useParams from "@hooks/useSafeParams";
import viewportState from "@stores/viewport";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSnapshot } from "valtio";

interface StaticPageNavigationProps {
  staticPageMenuItems?: Array<{
    label: string;
    href: string;
    key: number;
  }>;
  handleSelect?: (key: number) => void;
  currentKey?: number;
}

export default function StaticPageNavigation({
  staticPageMenuItems,
  handleSelect,
  currentKey,
}: StaticPageNavigationProps) {
  const menuItems = staticPageMenuItems ?? defaultStaticPageMenuItems; // 使用局部变量，推荐
  const activeItemRef = useRef<HTMLAnchorElement>(null);

  const { level1 = "", level2 = "" } = useParams();
  const levelKey = [level1, level2].filter(Boolean).join("_");
  const { isTouchableDevice } = useSnapshot(viewportState);
  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: "auto",
        block: "nearest",
        inline: "center",
      });
    }
  }, [levelKey]);
  return (
    <div className="relative flex h-16 items-center justify-start overflow-y-hidden lg:h-auto lg:overflow-y-visible">
      <div
        id="menu"
        className="flex overflow-y-hidden py-4 lg:block lg:w-full lg:overflow-y-visible lg:py-0 "
      >
        {menuItems
          .filter(
            (item) => item.label !== "加入我们" && item.label !== "常见问题",
          )
          .map((item, index) => {
            const isActive = levelKey.replace("-", "_") === item.key;
            if (!staticPageMenuItems) {
              return (
                <Link
                  ref={isActive ? activeItemRef : null}
                  to={item.href}
                  key={index}
                  target="_self"
                  className={clsx(
                    "no-scrollbar flex h-16 items-center justify-center whitespace-nowrap border-[1px] border-l-0 border-divider-primary px-[24px] py-[14px] text-center  text-button2 text-secondary first-of-type:rounded-l-button-radius-full last-of-type:rounded-r-button-radius-full  lg:border-b-0 lg:border-l-[1px] first-of-type:lg:rounded-none last-of-type:lg:rounded-none last-of-type:lg:border-b-[1px]",
                    !isTouchableDevice
                      ? "hover:bg-button-segmented-primary-hover hover:!text-secondary "
                      : "hover:!text-emphasis",
                    {
                      "bg-secondary !text-button1 !text-emphasis": isActive,
                    },
                  )}
                >
                  {item.label}
                </Link>
              );
            } else {
              // for "faq" page, donot need href of Link
              return (
                /* eslint-disable-next-line  jsx-a11y/anchor-is-valid */
                <a
                  href="javascript: void(0)"
                  key={index}
                  className={clsx(
                    "no-scrollbar flex h-16 items-center justify-center whitespace-nowrap border-[1px] border-l-0 border-divider-primary px-[24px] py-[14px] text-center text-button2 text-secondary first-of-type:rounded-l-button-radius-full last-of-type:rounded-r-button-radius-full  lg:border-b-0 lg:border-l-[1px] first-of-type:lg:rounded-none last-of-type:lg:rounded-none last-of-type:lg:border-b-[1px]",
                    !isTouchableDevice
                      ? "rounded-[--radius-sm] hover:bg-button-segmented-primary-hover hover:!text-secondary "
                      : "hover:!text-emphasis",
                    {
                      "bg-secondary !text-button1 !text-emphasis":
                        currentKey === item.key,
                    },
                  )}
                  onClick={() => {
                    handleSelect && handleSelect(Number(item.key));
                  }}
                >
                  {item.label}
                </a>
              );
            }
          })}
      </div>
    </div>
  );
}
