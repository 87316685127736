import FacebookIcon from "@assets/icons/facebook.svg";
import TelegramIcon from "@assets/icons/telegram.svg";
import TwitterIcon from "@assets/icons/twitter.svg";
import WechatIcon from "@assets/icons/wechat.svg";
import WhatsappIcon from "@assets/icons/whatsapp.svg";
import Link from "@components/Link/Link";
import useGtmEvent from "@hooks/useGtmEvent";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import { type ReactElement } from "react";

export default function Social({
  handleGive,
  giftShareUrl,
}: {
  handleGive: () => Promise<string | undefined>;
  giftShareUrl?: string;
}): ReactElement {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  type ShareItem = {
    src: string;
    alt: string;
    link: string;
    title?: string;
  };
  const append_url =
    "utm_source=social&utm_medium=social-gift&utm_campaign=gift-article&utm_content=web&";
  const sendGtmData = useGtmEvent();
  const handleClick = async (e: React.MouseEvent, item: ShareItem) => {
    e.preventDefault();
    e.stopPropagation();
    const url = encodeURIComponent((await handleGive()) || "");
    console.log(`${item.link}${url}${item.title || ""}`);
    window.open(`${item.link}${url}${item.title || ""}`, "_blank");
    sendGtmData("custom_event", "gift article", "share", item.alt);
  };

  const SocialList = [
    {
      src: FacebookIcon,
      alt: "facebook",
      link: `https://www.facebook.com/sharer/sharer.php?${append_url}u=`,
    },
    {
      src: TwitterIcon,
      alt: "twitter",
      link: `https://twitter.com/intent/tweet?${append_url}&text=${article?.headline}&url=`,
    },
    {
      src: WechatIcon,
      alt: "wechat",
      link: `https://www.addtoany.com/ext/wechat/share/#${append_url}url=`,
      title: `&title=${article?.headline}`,
    },
    {
      src: WhatsappIcon,
      alt: "whatsapp",
      link: `https://api.whatsapp.com/send?${append_url}text=${article?.headline}%20`,
    },
    {
      src: TelegramIcon,
      alt: "telegram",
      link: `https://t.me/share/url?${append_url}url=`,
    },
  ];
  return (
    <div className="w-full pb-[16px]">
      <ul className="mx-auto flex w-[184px] items-center  justify-between">
        {SocialList.map((item, index) => {
          return (
            <li key={index} className="cursor-pointer">
              {giftShareUrl ? (
                <Link
                  to={giftShareUrl ? `${item.link}${giftShareUrl}` : "#"}
                  target="_blank"
                  onClick={() => {
                    sendGtmData(
                      "custom_event",
                      "gift article",
                      "share",
                      item.alt,
                    );
                  }}
                >
                  <img src={item.src} alt={`Link to ${item.alt}`} />
                </Link>
              ) : (
                /* eslint-disable-next-line  jsx-a11y/anchor-is-valid */
                <a
                  href="javascript: void(0)"
                  onClick={(e) => {
                    void handleClick(e, item);
                  }}
                >
                  <img src={item.src} alt={`Link to ${item.alt}`} />
                </a>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
