import { useCampaignCarouselFromDrupal } from "@hooks/ClientSideFromDrupal/useCampaignCarouselFromDrupal";
import { HomepageCarousel } from "@pages/Home/HomepageCampaignCarousel";
import globalState from "@stores/global";
import { useSnapshot } from "valtio";

export default function NewsletterCarousel() {
  const { campaignCarouselArticles = [] } = useCampaignCarouselFromDrupal();
  const { isClientSideMounted } = useSnapshot(globalState);
  const shouldRender =
    isClientSideMounted &&
    campaignCarouselArticles &&
    campaignCarouselArticles.length > 0 &&
    campaignCarouselArticles.length >= 3;

  return (
    <div>
      {shouldRender ? (
        <section className="homepage-campaign_carousel-section bg-[white]">
          <div className="layout-container">
            <div className="w-full border-divider-primary py-[16px]">
              <HomepageCarousel articles={campaignCarouselArticles} />
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
}
