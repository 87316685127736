/* eslint-disable jsx-a11y/click-events-have-key-events*/
/* eslint-disable  jsx-a11y/no-static-element-interactions */
import NewsPost from "@assets/icons/article/newspost.svg";
import ArticleGiftIcon from "@assets/icons/gift-icon.svg";
import Button from "@components/Button/Button";
import GiftModal from "@components/GiftModal/GiftModal";
import GiftPopover from "@components/GiftModal/GiftPopover";
import Link from "@components/Link/Link";
import { BFF_BASE_URL } from "@constants/bff";
import { CAS_PAYWALL_ID } from "@constants/cas";
import useFontSize from "@hooks/useFontSize";
import useGtmEvent from "@hooks/useGtmEvent";
// import { useIAS } from "@hooks/useIAS";
// import { useWindowSize } from "@hooks/useWindowSize";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import {
  fontSizeClassNameOptions,
  fontSizeClassNameOptions_1,
  fontSizeClassNameOptions_2,
  fontSizeClassNameOptions_3,
  fontSizeClassNameOptions_4,
} from "@stores/font";
import userState, { OKTAUserTypeEnum } from "@stores/user";
import viewportState from "@stores/viewport";
import { ArticleDetailContextType, ArticleDetailNS } from "@typings/Data";
import { DeepPartial } from "@typings/index";
import axios from "axios";
import clsx from "clsx";
import dayjs from "dayjs";
import Cookie from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";

import ArticleAuthor from "../components/ArticleAuthor";
import ArticleFont from "../components/ArticleFont";
import ArticleKicker from "../components/ArticleKicker";
import ArticleMedia from "../components/ArticleMedia";
import ArticlePagination from "../components/ArticlePagination";
import ArticleShare from "../components/ArticleShare";
import ArticleTags from "../components/ArticleTags";
import ArticleTime from "../components/ArticleTime";
import ImageGalleryModal, {
  IImageGalleryModal,
} from "../components/ImageGalleryModal";
import useDataWrapperHeightChange from "../hooks/useDataWrapperHeightChange";
import useEmbedElementStyle from "../hooks/useEmbedElementStyle";
import { useEmbedInit } from "../hooks/useEmbedInit";
import useFBInit from "../hooks/useFBInit";
import useFontSizeChange from "../hooks/useFontSizeChange";
import useInlineFigureEvent from "../hooks/useInlineFigureEvent";
import useInsInit from "../hooks/useInsInit";

interface IContentCenter {
  className?: string;
}
export interface ArticleGiftResponse<T> {
  code?: number;
  message?: string;
  data?: T;
  viewGiftStatus?: boolean;
}
export interface GiftModalRef {
  getGiftCount: () => Promise<number>;
  checkGift: (token: string) => Promise<ArticleGiftResponse<unknown>>;
}

// const httpDomain = import.meta.env.VITE_BFF_ENDPOINT;

export default function ContentCenter({ className = "" }: IContentCenter) {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();
  const giftModalRef = useRef<GiftModalRef | null>(null);
  const { fontSize } = useFontSize();
  const [isOutbrainEnabled, setIsOutbrainEnabled] = useState(false);
  const { search, pathname }: { search: string; pathname: string } =
    useLocation();

  const searchParams = new URLSearchParams(search);
  const giftToken: string | undefined = searchParams.get("gift") || undefined;
  useEffect(() => {
    if (window.outbrain_enable === 1) {
      setIsOutbrainEnabled(true);
    }
  }, []);
  const { isDesktopView } = useSnapshot(viewportState);
  // const [isResize, setResize] = useState<boolean>(false);
  const { user } = useSnapshot(userState);
  const isPaid = article?.paid_mode === "paid";
  // Need to retain for local develop
  // const usertype = Cookie.get("mySPHUserType");
  // const loginid = Cookie.get("mysphw");

  // const isSubscriberedUser = loginid && usertype == "y-sub";
  // const handleCookie = () => {
  //   Cookie.set("mysphw", "test123");
  //   Cookie.set("mySPHUserType", "y-sub");
  // };

  const isSubscriberedUser =
    user?.loginid && user.usertype == OKTAUserTypeEnum.SUBSCRIBER;

  const [isGiftModalOpen, setGiftModalOpen] = useState<boolean>(false);
  const [isGiftPopoverOpen, setGiftPopoverOpen] = useState<boolean>(false);
  const [isBtnLoading, setBtnLoading] = useState<boolean>(false);
  const [renderBodyContent, setRenderBodyContent] = useState(
    article?.body_cn || "",
  );
  const sendGtmData = useGtmEvent();
  const getFullBodyContent = async (giftToken?: string) => {
    if (!article?.url_path) {
      // console.log("no article?.url_path");
      return;
    }
    try {
      const apiUrl = giftToken
        ? `${BFF_BASE_URL}/client${article.url_path}?gift=${giftToken}`
        : `${BFF_BASE_URL}/client${article.url_path}`;
      const res = await axios.get<
        DeepPartial<{ response: { article: ArticleDetailNS.Article } }>
      >(apiUrl, {
        withCredentials: true,
      });
      if (res.data.response?.article?.is_full_body) {
        if (res.data.response.article.body_cn) {
          setRenderBodyContent(res.data.response.article.body_cn || "");
        }
      } else {
        setRenderBodyContent(article.body_cn);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGiftModal = () => {
    sendGtmData("custom_event", "gift article", "click", "gift article");
    const openGiftModal = async () => {
      if (isSubscriberedUser && giftModalRef.current) {
        setBtnLoading(true);
        const res = await giftModalRef.current.getGiftCount();
        setBtnLoading(false);
        setGiftModalOpen(true);
        sendGtmData(
          "custom_event",
          "gift article",
          "visible",
          `gift article:: ${res}`,
        );
      } else {
        setBtnLoading(false);
        setGiftModalOpen(true);
      }
    };
    if (isGiftModalOpen) {
      if (isSubscriberedUser) {
        setBtnLoading(true);
      }
      // console.log(130, "close modal");
      setGiftModalOpen(false);
      setTimeout(() => {
        void openGiftModal();
      }, 1000);
    } else {
      void openGiftModal();
    }
  };

  useEffect(() => {
    if (isPaid) {
      sendGtmData("custom_event", "gift article", "visible", "icon");
      if (!localStorage.getItem("GIVE_GIFT_BUTTON_VIEWED")) {
        setGiftPopoverOpen(true);
        sendGtmData("custom_event", "gift article", "visible", "tooltip");
      }
      localStorage.setItem("GIVE_GIFT_BUTTON_VIEWED", "VIEWED");
    }
    const checkGift = async () => {
      // console.log(123456, Cookie.get("mysphw"));
      const isSubscriber: boolean =
        Cookie.get("mySPHUserType") === "y-sub" && !!Cookie.get("mysphw");

      // 如果URL携带token
      if (giftToken) {
        // 如果user不是订阅用户
        if (!isSubscriber && isPaid) {
          const res = await giftModalRef.current?.checkGift(giftToken);
          // console.log({ res });
          setGiftModalOpen(true);
          if (res && res.code === 200) {
            sendGtmData("custom_event", "gift article", "visible", "read full");
          } else {
            sendGtmData(
              "custom_event",
              "gift article",
              "visible",
              "read partial",
            );
          }
        }
      }
    };
    void checkGift();
  }, [giftToken, isPaid]);

  useEffect(
    () => {
      // console.log(user?.usertype, article?.is_full_body, 9527);
      if (article?.is_full_body) {
        // is_full_body false need to request again
        return;
      }
      if (giftToken) {
        // console.log(178);
        void getFullBodyContent(giftToken);
      } else {
        // console.log(181);
        void getFullBodyContent();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.usertype, article?.is_full_body], // no need to add loginid
  );

  // adjust embed-wrapper iframe style
  useEmbedElementStyle(renderBodyContent);

  useFontSizeChange(renderBodyContent);

  const [modal, setModal] = useState<IImageGalleryModal>({
    open: false,
    activeIndex: 0,
  });

  useFBInit(renderBodyContent);
  useInsInit(renderBodyContent);

  useEmbedInit(renderBodyContent);

  useInlineFigureEvent(renderBodyContent, (item) => {
    setModal({ open: true, images: item.images });
  });

  useDataWrapperHeightChange(renderBodyContent);
  // useIAS({
  //   documentId: article?.document_id || "",
  //   articleUrl: `${httpDomain}${article?.url_path}`,
  // });

  return (
    <>
      <div className={className}>
        {!isDesktopView && (
          <ArticleKicker
            className={clsx("mb-[4px]", fontSizeClassNameOptions_2[fontSize])}
          />
        )}

        <article className="max-w-full">
          <h1
            className={clsx(
              isDesktopView
                ? fontSizeClassNameOptions_3[fontSize]
                : fontSizeClassNameOptions_4[fontSize],
              "my-0 font-medium leading-[150%] text-primary",
            )}
          >
            {article?.headline}
          </h1>

          {isPaid ? (
            <div className="relative flex items-center justify-between pb-[4px] pt-[8px] ">
              {/* <div className="rounded-full bg-subscriber-primary px-[8px] text-center text-[16px] font-medium leading-[150%] text-white">
                订户
              </div> */}
              <Button
                size="small"
                type="subscriber"
                rootClassName="cursor-default"
              >
                <div className="text-label1">订户</div>
              </Button>
              <>
                <div className="flex cursor-pointer font-medium text-brand ">
                  <Button size="small-send" isLoading={isBtnLoading}>
                    <div className="item-center flex text-button3">
                      <img
                        src={ArticleGiftIcon}
                        alt=""
                        className="mb-[4px] mr-[4px] mt-[4px] h-[13.33px] w-[13.33px]"
                      />
                      <div
                        className="pt-[1px]"
                        onClick={() => {
                          handleGiftModal();
                        }}
                      >
                        赠阅文章
                      </div>
                    </div>
                  </Button>
                </div>

                <GiftPopover
                  isVisible={isGiftPopoverOpen}
                  onClose={() => {
                    setGiftPopoverOpen(false);
                    sendGtmData(
                      "custom_event",
                      "gift article",
                      "remove",
                      "tooltip",
                    );
                  }}
                />
              </>
            </div>
          ) : null}

          <GiftModal
            isVisible={isGiftModalOpen}
            onClose={() => {
              console.log("377 close modal");
              setGiftModalOpen(false);
            }}
            ref={giftModalRef}
          />

          {!isDesktopView && (
            <div className="mb-[16px]">
              <ArticleAuthor className={fontSizeClassNameOptions_1[fontSize]} />
              <ArticleTime className={fontSizeClassNameOptions_1[fontSize]} />
            </div>
          )}

          {article?.stand_first ? (
            <div
              className={clsx(
                "my-[16px] rounded-[4px] border border-solid border-divider-primary p-[16px] font-medium text-secondary",
                fontSizeClassNameOptions[fontSize],
              )}
            >
              {article.stand_first}
            </div>
          ) : null}

          {/* main media */}
          <ArticleMedia
            className={clsx("my-[16px]", fontSizeClassNameOptions_1[fontSize])}
          />

          {/* mobile layout  */}
          {!isDesktopView && (
            <div className="my-[16px] flex justify-center gap-[8px]">
              <ArticleShare />
              <ArticleFont />
            </div>
          )}

          <div
            className={clsx("articleBody", fontSizeClassNameOptions[fontSize])}
            dangerouslySetInnerHTML={{ __html: renderBodyContent }}
          />

          <div className=" clear-both">
            {/* paywall */}
            <div className="my-[16px]" id={`sph_cdp_${CAS_PAYWALL_ID}`}></div>

            {/* add imu1 in premium article */}
            {isPaid && !article.is_full_body ? (
              <div
                style={{ display: "none" }}
                id="dfp-ad-imu1-wrapper"
                className="bff-google-ad google-ad my-[16px] flex w-full flex-row justify-center overflow-x-hidden"
              >
                <button className="${buttonClass}" id="dfp-ad-imu1"></button>
              </div>
            ) : (
              <></>
            )}

            {/* tags */}
            <ArticleTags
              className={clsx(
                "my-[16px]",
                fontSizeClassNameOptions_1[fontSize],
              )}
            />

            {/* prevNextArticles */}
            <ArticlePagination className="my-[16px]" />

            {/* new post when copyright is SPH */}
            {article?.copyright == "SPH" ? (
              <Link
                className=" mx-auto my-[16px] flex w-max cursor-auto items-center justify-center gap-[8px] text-[14px] !text-secondary no-underline"
                to={
                  article.create_time && article.headline
                    ? `https://newspost.newslink.sg/?publication=ZB&date=${dayjs
                        .unix(article.create_time)
                        .tz("Asia/Singapore")
                        .format(
                          "MM/DD/YYYY",
                        )}&title=${encodeURIComponent(article.headline)}`
                    : "#"
                }
              >
                <img src={NewsPost} alt="" className="m-0 h-[36px] w-[36px]" />
                <div>购买此文章</div>
              </Link>
            ) : null}

            {/* outbrain */}
            {isOutbrainEnabled && (
              <div className=" mt-[16px]">
                <div
                  className="OUTBRAIN"
                  data-src={`www.zaobao.com.sg${pathname}`}
                  data-widget-id="AR_1"
                  data-ob-template="ZaobaoSingapore"
                />
              </div>
            )}
          </div>
        </article>
      </div>

      {/* for inline image */}
      <ImageGalleryModal
        {...modal}
        onClose={() => {
          setModal({ ...modal, open: false });
        }}
      />
    </>
  );
}
