import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { NewslettersType } from "@typings/Data";
import { useState } from "react";

import { SIGNUP_DESC, SIGNUP_TITLE } from "../constants/newsletter";
import Introduction from "./NewsletterIntroduction";
import Register from "./NewsletterRegister";
import Newsletters from "./NewslettersCardList";

function SignupCom() {
  const [choice, setChoice] = useState<string[]>([]);

  const {
    context: {
      payload: { list = [], title = "" },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: NewslettersType;
  }> = useRouteContext();

  return (
    <section>
      <Introduction title={SIGNUP_TITLE} description={SIGNUP_DESC} />
      <section className="mb-[40px]">
        <Newsletters
          newsletters={{ list, title }}
          choice={choice}
          setChoice={setChoice}
        />
      </section>
      <Register choice={choice} setChoice={setChoice} />
    </section>
  );
}

export default SignupCom;
