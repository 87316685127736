import SideMostReadArticleList from "@components/ArticleCardList/SideMostReadArticleList/SideMostReadArticleList";
import TickerArticleList from "@components/ArticleCardList/TickerArticleList/TickerArticleList";
import Card from "@components/Card/Card";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Tabs from "@components/Tabs/Tabs";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import { navigationDictionary } from "@constants/navigation";
// import { useFurtherReading } from "@hooks/useFurtherReading";
import useParams from "@hooks/useSafeParams";
import { useTickerArticles } from "@hooks/useTickerArticles";
// import ArticleFurtherReading from "@pages/Article/components/ArticleFurtherReading";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import {
  ArticleDetailContextType,
  ArticlePreviewType,
  WebCategoryLevel1ContextType,
} from "@typings/Data";
import { Fragment, ReactNode, useCallback } from "react";
import { useState } from "react";

export default function WebCategorySidebar({
  isArticlePage = false,
  isSticky = false,
}: {
  isArticlePage?: boolean;
  /** @deprecated */
  isSticky?: boolean;
}) {
  const {
    context: {
      payload: { dailyTopArticles, weeklyTopArticles, sidebarBanner },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel1ContextType &
      Pick<ArticleDetailContextType, "article">;
  }> = useRouteContext();
  // console.log(dailyTopArticles, "dailyTopArticles");
  const { level1 = "", level2 = "" } = useParams();
  const sectionAdsName = checkIfListingPage(level1, level2);

  const [computedTopArticles, setComputedTopArticles] =
    useState<ArticlePreviewType[]>(dailyTopArticles);

  const { tickerArticles } = useTickerArticles();
  // const { furtherReadingArticles } = useFurtherReading({
  //   documentId: article?.document_id || "",
  // });

  const StickyContainer = useCallback(
    ({ children }: { children?: ReactNode }) => {
      if (!isSticky) return <>{children}</>;
      return (
        <div className=" min-h-screen w-full">
          <div className="sticky top-20 h-max w-full">{children}</div>
        </div>
      );
    },
    [isSticky],
  );

  // const _furtherReadingArticles = isArticlePage ? furtherReadingArticles : [];

  return (
    <Fragment>
      <StickyContainer>
        <div className="layout-container">
          <GoogleAd
            rootClassName="py-[16px]"
            type={
              !isArticlePage
                ? AdvertisementTypeEnum.IMU1
                : AdvertisementTypeEnum.IMU2
            }
            slot={
              !isArticlePage
                ? GoogleAdsSlotFactory.imu1(sectionAdsName)
                : GoogleAdsSlotFactory.imu2(sectionAdsName)
            }
          />
        </div>
      </StickyContainer>

      {/* {_furtherReadingArticles.length > 0 ? (
        <StickyContainer>
          <Card
            rootClassName="px-8"
            contentClassName=""
            heading="不要错过"
            isLink={false}
          >
            <ArticleFurtherReading
              className="pt-[16px]"
              data={_furtherReadingArticles}
            />
          </Card>
        </StickyContainer>
      ) : null} */}

      <StickyContainer>
        <Card
          rootClassName="px-8"
          contentClassName=""
          heading="即时新闻"
          isBreakingNews={true}
          headingLink={navigationDictionary.realtime.href}
        >
          <TickerArticleList articles={tickerArticles} />
        </Card>
      </StickyContainer>

      <StickyContainer>
        <Card
          rootClassName="px-8"
          heading="热门"
          isLink={false}
          headingComponent={
            <Tabs
              tabs={[
                {
                  label: "单日",
                  key: "popular-desktopday-tab",
                  value: "daily",
                  onClick: () => {
                    setComputedTopArticles(dailyTopArticles);
                  },
                },
                {
                  label: "一周",
                  key: "popular-desktopweek-tab",
                  value: "weekly",
                  onClick: () => {
                    setComputedTopArticles(weeklyTopArticles);
                  },
                },
              ]}
            />
          }
        >
          <SideMostReadArticleList articles={computedTopArticles} />
        </Card>
      </StickyContainer>

      <StickyContainer>
        <div className="layout-container">
          <GoogleAd
            rootClassName="py-[16px]"
            type={
              !isArticlePage
                ? AdvertisementTypeEnum.IMU2
                : AdvertisementTypeEnum.IMU3
            }
            slot={
              !isArticlePage
                ? GoogleAdsSlotFactory.imu2(sectionAdsName)
                : GoogleAdsSlotFactory.imu3(sectionAdsName)
            }
          />
        </div>
      </StickyContainer>

      <StickyContainer>
        {sidebarBanner && sidebarBanner.length > 0 ? (
          <Card
            rootClassName="sidebar-banner px-8 pt-[16px]"
            heading="更多消息"
            isLink={false}
          >
            {sidebarBanner.map((item, index) => {
              return (
                <div key={index} className="w-full rounded-[4px] py-[8px]">
                  <button
                    onClick={() => {
                      window.open(item.sidebarLink, "_blank");
                    }}
                    className="w-full"
                  >
                    <img
                      alt=""
                      className="img-fluid inline aspect-[3/1] w-full rounded-[4px]"
                      data-entity-type=""
                      data-entity-uuid=""
                      src={item.sidebarBannerUrl}
                    />
                  </button>
                </div>
              );
            })}
          </Card>
        ) : null}
      </StickyContainer>
    </Fragment>
  );
}

// Function to check if level1 is a keywords, publication or byline page, if yes, just return level1 and remove level2 string
export const checkIfListingPage = (level1: string, level2: string) => {
  if (
    level1 === "keywords" ||
    level1 === "publication" ||
    level1 === "byline" ||
    level1 === "entertainment"
  ) {
    return level1;
  }

  if (!level2) {
    return level1;
  }

  return `${level1}/${level2}`;
};
