import CatfishGoogleAd from "@components/CatfishGoogleAd/CatfishGoogleAd";
import Footer from "@components/Footer/Footer";
import GAData from "@components/GAData/GAData";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Header from "@components/Header/Header";
import MobileHeader from "@components/Header/MobileHeader";
import MetaTags from "@components/MetaTags/MetaTags";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import useGoogleAdsTargeting from "@hooks/useGoogleAdsTargeting";
import useParams from "@hooks/useSafeParams";
import viewportState from "@stores/viewport";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { useSnapshot } from "valtio";

import UnsubscribeCom from "../components/UnsubscribeCom";
import {
  UNSUBSCRIBE_PAGE_DESC,
  UNSUBSCRIBE_PAGE_TITLE,
} from "../constants/newsletter";

export default function Unsubscribe(): ReactElement {
  useGoogleAdsTargeting({ targeting: { key: "page", value: "listing" } });
  const { level1 = "", level2 = "" } = useParams();
  const { isMobileView, isDesktopView, isInAppBrowser } =
    useSnapshot(viewportState);

  const getAdBlocker = () => {
    if (typeof window !== "undefined") {
      return (window as Window).canRunAds ?? 0;
    }
  };

  // Meta Tag - Title + include author's character name
  const sectionAdsName = `newsletter-unsubscribe/${level2}`;
  const canonical_url = `/newsletter-unsubscribe/${level2}`;

  return (
    <>
      <Helmet>
        <title>{UNSUBSCRIBE_PAGE_TITLE}</title>
      </Helmet>

      <MetaTags
        type="website"
        title={UNSUBSCRIBE_PAGE_TITLE}
        description={UNSUBSCRIBE_PAGE_DESC}
        slug={canonical_url}
      ></MetaTags>

      <GAData
        level2_ga={level1}
        title={UNSUBSCRIBE_PAGE_TITLE}
        adblocker={getAdBlocker()}
      />

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad abm-google-ad"
          type={AdvertisementTypeEnum.ABM}
          slot={GoogleAdsSlotFactory.abm(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad min-h-[50px] justify-center p-[10px]"
          type={AdvertisementTypeEnum.LB1}
          slot={GoogleAdsSlotFactory.lb1(sectionAdsName)}
        />
      )}

      {!isDesktopView ? (
        isInAppBrowser ? null : (
          <MobileHeader rootClassName="lg:hidden" />
        )
      ) : (
        <Header rootClassName="max-lg:hidden" />
      )}

      <UnsubscribeCom></UnsubscribeCom>

      {isInAppBrowser ? null : <Footer />}

      {isMobileView ? (
        isInAppBrowser ? null : (
          <CatfishGoogleAd
            slot={GoogleAdsSlotFactory.catfish(sectionAdsName)}
          />
        )
      ) : null}
    </>
  );
}
