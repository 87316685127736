import { useEffect } from "react";

/**
 * @name useInlineImageEvent
 * @description add click listener for inline image
 */
export default function useInlineImageEvent(
  renderBodyContent: string,
  onClick?: (item: { images: { url?: string; caption?: string }[] }) => void,
) {
  useEffect(() => {
    const handleClick = (event: Event) => {
      // console.log(event, "click element");
      const element = event.currentTarget;
      if (!(element instanceof HTMLElement)) {
        return;
      }
      const parentElement = element.closest("figure");
      // console.log(parentElement, "parentElement");
      if (!parentElement) {
        return;
      }
      const imgElement = parentElement.querySelector("img");
      const figcaptionElement = parentElement.querySelector("figcaption");
      const imgSrc = imgElement ? imgElement.src : "";
      const figcaptionText = figcaptionElement
        ? figcaptionElement.textContent || ""
        : "";

      onClick?.({ images: [{ url: imgSrc, caption: figcaptionText }] });
    };
    // add click event fot inline figure elements
    const inlineFigureMediaElements = document.getElementsByClassName(
      "bff-inline-figure-media",
    );

    for (const element of inlineFigureMediaElements) {
      element.addEventListener("click", handleClick);
    }

    return () => {
      for (const element of inlineFigureMediaElements) {
        element.removeEventListener("click", handleClick);
      }
    };
  }, [renderBodyContent, onClick]);
}
