import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Loading({
  color = "#6f6f6f",
  size = "2x",
}: {
  color?: string;
  size?: SizeProp;
}) {
  return (
    <div className="text-center">
      <FontAwesomeIcon color={color} icon={faSpinner} spin={true} size={size} />
    </div>
  );
}
