import "./style.css";

import Slider, { Settings } from "@ant-design/react-slick";
import ArrowRight from "@assets/icons/arrow-next-white.svg?react";
import ArrowLeft from "@assets/icons/arrow-prev-white.svg?react";
import VerticalArticleCard from "@components/ArticleCard/VerticalArticleCard/VerticalArticleCard";
import {
  WithGtmTracking,
  WithGtmTrackingProps,
} from "@components/WithGtmTrackingHoC";
import globalState from "@stores/global";
import { ArticlePreviewType } from "@typings/Data";
import clsx from "clsx";
import { useState } from "react";
import { useSnapshot } from "valtio";

type VerticalArticleCardProps = {
  article: ArticlePreviewType;
  rootClassName: string;
  hideTimestamp: boolean;
} & WithGtmTrackingProps;

const TrackedVerticalArticleCard =
  WithGtmTracking<VerticalArticleCardProps>(VerticalArticleCard);

export default function AutoRotator({
  articles,
}: {
  articles: ArticlePreviewType[];
}) {
  // Slider doesn't support SSR
  const { isClientSideMounted } = useSnapshot(globalState);

  const [current, setCurrent] = useState(0);
  const sliderSettings: Settings = {
    lazyLoad: "progressive",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    beforeChange(_, nextSlide) {
      setCurrent(nextSlide);
    },
    customPaging: (index) => (
      <button
        className={clsx("!h-[9px] !w-[9px] rounded-full before:!content-none", {
          "!bg-[var(--button-primary-default)]": index === current,
          "!bg-quaternary": index !== current,
        })}
      ></button>
    ),
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    arrows: true,
    prevArrow: <PreArrowButton />,
    nextArrow: <NextArrowButton />,
  };
  return (
    <>
      {isClientSideMounted ? (
        <div className="relative pb-[30px]">
          <Slider {...sliderSettings}>
            {articles.map((article, index) => (
              <div className="w-full" key={article.id}>
                <TrackedVerticalArticleCard
                  gtmTrackingProps={{
                    customEvent: "custom-event",
                    eventCategory: "zbclub",
                    eventAction: "click",
                    eventLabel: `position:${index + 1},title:${article.title}`,
                  }}
                  article={article}
                  rootClassName="override-vertical-card"
                  hideTimestamp
                />
              </div>
            ))}
          </Slider>
        </div>
      ) : null}
    </>
  );
}

const sharedCss =
  "absolute top-[38%] hover:!bg-button-secondary-hover  z-[9] block h-auto w-auto cursor-pointer rounded-full !bg-[var(--color-grey-500)] p-[5px] before:!content-none lg:top-[37%]";

function PreArrowButton(props: { className?: string; onClick?: () => void }) {
  const { className, onClick } = props;

  return (
    <button
      className={clsx(`${className} ${sharedCss} left-[8px]`)}
      onClick={onClick}
    >
      <ArrowLeft className="h-[20px] w-[20px]" />
    </button>
  );
}

function NextArrowButton(props: { className?: string; onClick?: () => void }) {
  const { className, onClick } = props;
  return (
    <button
      className={clsx(`${className} ${sharedCss} right-[8px]`)}
      onClick={onClick}
    >
      <ArrowRight className="h-[20px] w-[20px]" />
    </button>
  );
}
