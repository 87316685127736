function NewsletterTitle({ title }: { title: string }) {
  return (
    <section className="layout-container pt-[16px]">
      <h2 className="text-ellipsis border-t border-t-divider-brand pt-[8px] text-header2">
        {title}
      </h2>
    </section>
  );
}

export default NewsletterTitle;
