// import Accordion from "@components/Accordion/Accordion";

import HomepageFullWidthBanner from "@components/HomepageFullWidthBanner/HomepageFullWidthBanner";
import SectionNavigation from "@components/SectionNavigation/SectionNavigation";
import StaticPageNavigation from "@components/StaticPageNavigation/StaticPageNavigation";
import LandingPageTemplate from "@components/Templates/LandingPageTemplate";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { Fragment, ReactElement, ReactNode } from "react";

import SocialContent from "./SocialContent";
import StaticContent from "./StaticContent";

// import StaticAccordian from "./StaticAccordian/StaticAccordian";

type StaticPageContextType = {
  url_path: string;
  url_history: [];
  body_cn: string;
  headline: string;
};

const AboutUsLayout = (): ReactElement => {
  const {
    context: {
      payload: { body_cn, headline, url_path },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: StaticPageContextType;
  }> = useRouteContext();
  // const [isOpen, setIsOpen] = useState(false);
  const renderFullWidthBanner = () => {
    return <HomepageFullWidthBanner additionalClasses="!mb-0" />;
  };

  const renderSectionNavigation: () => ReactNode = () => {
    return <SectionNavigation level={""} tagName={headline} />;
  };

  const renderPageContent: () => ReactNode = () => {
    console.log({ url_path });
    return (
      <Fragment>
        <div className="layout-container articlePage">
          <div className="row">
            <div className="overflow-x-hidden px-8 py-[16px] lg:w-2/12 lg:overflow-visible">
              <StaticPageNavigation />
            </div>
            <div className="w-full lg:w-10/12">
              <div className="articleBody staticPage box-border max-w-full flex-1 px-[20px] text-body1 lg:px-[20px]">
                <StaticContent htmlString={body_cn} />
                {url_path === "/products" && <SocialContent />}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <LandingPageTemplate
      topSlots={[renderSectionNavigation, renderFullWidthBanner]}
      mainSlots={[renderPageContent]}
    />
  );
};

export default AboutUsLayout;
