import Button from "@components/Button/Button";
import {
  WithGtmTracking,
  WithGtmTrackingProps,
} from "@components/WithGtmTrackingHoC";

function LoginButton({ onClick }: { onClick?: () => void }) {
  const handleClick = () => {
    onClick?.();
    if (window._mySPHObj !== undefined) {
      window._mySPHObj.openLogin();
    }
  };
  return (
    <Button
      rootClassName="ml-[16px] h-[30px] lg:w-[65px]"
      size="login"
      type="tertiary"
      onClick={handleClick}
    >
      登录
    </Button>
  );
}

export default function TrackedLoginButton(props: WithGtmTrackingProps) {
  return WithGtmTracking(LoginButton)(props);
}
