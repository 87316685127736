import { ArticleDetailNS } from "@typings/Data";
import Cookie from "js-cookie";
import { ReactElement } from "react";

import {
  getArticleAuthorNames,
  getArticleChapter1,
  getArticleKeywords,
  getArticleLevel2,
} from "./helper";

export interface articleAuthorName {
  name: string;
  url_path: string;
}
export interface section {
  name: string;
}

interface AnalyticsProps {
  articleId?: string;
  articleCueId?: string;
  articleUrl?: string;
  articleAuthorName: articleAuthorName[];
  contentType: string;
  keywords: ArticleDetailNS.TagType[];
  title?: string;
  pubDate?: string;
  sections: section[];
  storyThreads: string;
}

export default function AmpGAData({
  articleId,
  articleCueId,
  articleUrl,
  articleAuthorName,
  contentType,
  keywords,
  title,
  pubDate,
  sections,
  storyThreads,
}: AnalyticsProps): ReactElement {
  const baseUrl = import.meta.env.VITE_DRUPAL_ENDPOINT;

  // GA Config
  const ga4MeasurementId = import.meta.env.VITE_GA_MEASUREMENT_ID;
  const ga4_json = baseUrl + "/_web2/ga4.json";

  // Permutive Config
  const sph_seg_cookie_val: string | undefined = Cookie.get(
    "sph_sage_cookie_val",
  );

  const sph_seg: string[] = sph_seg_cookie_val
    ? sph_seg_cookie_val.split(",")
    : [];

  //console.log("JON - AmpGAData sections - ", sections);
  //console.log("JON - getArticleLevel2 - ", getArticleLevel2(sections[0]));
  return (
    <>
      {/* Google Analytics */}
      <amp-analytics
        type="googleanalytics"
        config={ga4_json}
        data-credentials="include"
      >
        <script
          type="application/json"
          dangerouslySetInnerHTML={{
            __html: `
           {
              vars: {
                GA4_MEASUREMENT_ID: "${ga4MeasurementId}",
                GA4_ENDPOINT_HOSTNAME: "tagweb.zaobao.com.sg",
                DEFAULT_PAGEVIEW_ENABLED: true,
                GOOGLE_CONSENT_ENABLED: false,
                WEBVITALS_TRACKING: false,
                PERFORMANCE_TIMING_TRACKING: false,
                SEND_DOUBLECLICK_BEACON: false,
              },
              extraUrlParams: {
                event__str_articleId: "${articleId}",
                event__str_cue_articleid: "${articleCueId}",
                event__str_url: "${articleUrl}",
                event__str_articleAuthorName: "${getArticleAuthorNames(articleAuthorName)}",
                event__str_contentCategory: "free",
                event__str_contentType: "amp_article",
                event__str_keyword: "${getArticleKeywords(keywords, "|")}",
                event__str_pageName: "${title}",
                event__str_publicationDate: "${pubDate}",
                event__str_visitorCategory: "anonymous",
                event__str_level2SiteName: "${sections.length > 0 ? getArticleLevel2(sections[0]) : ""}",
                event__str_chapter1: "${sections.length > 0 ? getArticleChapter1(sections[0]) : ""}",
              },
            }
          `,
          }}
        ></script>
      </amp-analytics>

      {/* Permutive */}
      <amp-analytics type="permutive-ampscript">
        <script
          type="application/json"
          dangerouslySetInnerHTML={{
            __html: `
          {
            extraUrlParams: {
              customProperties: {
                articleid: "${articleCueId}",
                chapter1: "${sections.length > 0 ? getArticleChapter1(sections[0]) : ""}",
                chapter2: "${sections.length > 0 ? getArticleLevel2(sections[0]) : ""}",
                chapter3: "",
                contentcat: 1,
                contenttype: "${contentType}",
                keywords: "${getArticleKeywords(keywords)}",
                pagination: 1,
                printcat: "Free Content",
                story_threads: "${storyThreads}",
                title: "${title}",
                visitorcat: "anonymous",
                sph_seg: ${JSON.stringify(sph_seg)},
              },
            },
          }
          `,
          }}
        ></script>
      </amp-analytics>
      <amp-analytics type="chartbeat">
        <script type="application/json">
          {`{
            vars: {
              uid: "52075",
              domain: "zaobao.com.sg",
              sections: ""
          }`}
        </script>
      </amp-analytics>
      <amp-state id="permutiveConfig">
        <script
          type="application/json"
          dangerouslySetInnerHTML={{
            __html: `{
            "apiKey": "ab403253-b305-47fa-a31b-3efb2473166f",
            "projectId": "5f876161-9740-4cc8-9b64-4585990b2690",
            "environment": "production"
          }`,
          }}
        ></script>
      </amp-state>
      <amp-script
        id="permutiveCachedTargeting"
        sandboxed=""
        script="permutiveCachedTargetingScript"
      ></amp-script>
      <script
        id="permutiveCachedTargetingScript"
        type="text/plain"
        {...{ target: "amp-script" as string }}
        dangerouslySetInnerHTML={{
          __html: `
          exportFunction('ct', () => {
            let c = JSON.parse(localStorage.getItem('_pdfps'));
            let i = localStorage.getItem('permutive-id');
            return {
              targeting: {
                permutive: c ? c : [],
                puid: i
              },
              ppid: i
            };
          });
        `,
        }}
      ></script>
      <amp-script sandboxed="" script="pamp-json"></amp-script>
      <script
        id="pamp-json"
        type="text/plain"
        {...{ target: "amp-script" as string }}
        dangerouslySetInnerHTML={{
          __html: `
          fetch('https://cdn.permutive.app/queries/5f876161-9740-4cc8-9b64-4585990b2690-amp.json')
            .then(r => r.json())
            .then(d => AMP.setState({ permutiveConfig: { ampJson: d }}));
        `,
        }}
      ></script>
      <amp-script
        id="permutiveSdk"
        sandboxed=""
        src="https://cdn.permutive.app/ampsdk/5f876161-9740-4cc8-9b64-4585990b2690-ampscript.js"
      ></amp-script>
    </>
  );
}
