import VerticalArticleCard from "@components/ArticleCard/VerticalArticleCard/VerticalArticleCard";
import Card from "@components/Card/Card";
import { ArticlePreviewType } from "@typings/Data";
import clsx from "clsx";

export default function KeywordArticleList({
  articles,
  sectionTitle,
  numberPerRow = 3,
  moreHref,
}: {
  articles: ArticlePreviewType[];
  sectionTitle: string;
  numberPerRow: number;
  moreHref: string;
  moreIcon?: string;
}) {
  return (
    <Card heading={sectionTitle} headingLink={moreHref}>
      <div className="-mx-8 grid grid-cols-12">
        {articles.map((article, index) => {
          const colSpan = 12 / numberPerRow;
          return (
            <div
              className={`col-span-full lg:col-span-${colSpan}`}
              key={article.id}
            >
              <VerticalArticleCard
                rootClassName={clsx("responsive mx-0", {
                  "!border-0": index === 0,
                })}
                article={article}
              />
            </div>
          );
        })}
      </div>
    </Card>
  );
}
