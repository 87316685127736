import Facebook from "@assets/icons/static-pages/facebook.png";
import Instagram from "@assets/icons/static-pages/instagram.png";
import Newsletter from "@assets/icons/static-pages/newsletter.png";
import Telegram from "@assets/icons/static-pages/telegram.png";
import Thread from "@assets/icons/static-pages/thread.png";
import Tiktok from "@assets/icons/static-pages/tiktok.png";
import Weapp from "@assets/icons/static-pages/weapp.png";
import Wechat from "@assets/icons/static-pages/wechat.png";
import X from "@assets/icons/static-pages/x.png";
import Xhs from "@assets/icons/static-pages/xhs.png";
import Youtube from "@assets/icons/static-pages/youtube.png";
import Link from "@components/Link/Link";
import { ReactElement } from "react";
const SocialContent = (): ReactElement => {
  type otherLinkType = {
    icon: string;
    url: string;
  };
  type PlatformLinkType = {
    text: string;
    url: string;
  };

  const otherLink: otherLinkType[] = [
    {
      icon: Tiktok,
      url: "https://www.tiktok.com/@zaobaosg",
    },
    {
      icon: Xhs,
      url: "https://www.instagram.com/zaobaosg",
    },
    {
      icon: Youtube,
      url: "https://twitter.com/zaobaosg",
    },
    {
      icon: Thread,
      url: "https://www.tiktok.com/@zaobaosg",
    },
    {
      icon: Wechat,
      url: "https://www.youtube.com/zaobaosg",
    },
    {
      icon: Telegram,
      url: "https://www.wechat.com/zaobaosg",
    },
    {
      icon: Weapp,
      url: "https://wa.me/zaobaosg",
    },
  ];
  const newsletterLink: PlatformLinkType[] = [
    {
      text: "订阅每日速递",
      url: "https://www.zaobao.com.sg/newsletter-signup",
    },
  ];
  const facebookLink: PlatformLinkType[] = [
    {
      text: "zaobaosg",
      url: "https://www.facebook.com/zaobaosg",
    },
    {
      text: "zaobaosgle",
      url: "https://www.facebook.com/ZaobaoKopitiam",
    },
    {
      text: "zaobaosg.entertainment",
      url: "https://www.facebook.com/zaobaosgLE/",
    },
  ];
  const instagramLink: PlatformLinkType[] = [
    {
      text: "zabaosg",
      url: "https://www.instagram.com/zaobaosg/",
    },
    {
      text: "zaobaosg.lifestyle",
      url: "https://www.instagram.com/zaobaosg.lifestyle/",
    },
    {
      text: "zaobaosg.entertainment",
      url: "https://www.instagram.com/zaobaosg.entertainment",
    },
  ];
  const xLink: PlatformLinkType[] = [
    {
      text: "zaobaosg",
      url: "",
    },
  ];
  return (
    <div className="w-full pb-[16px]">
      <p className="static-header3">其他平台</p>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="left-box">
          <div className="with-icon">
            <img
              src={Newsletter}
              alt="newsletter"
              className="icon-img !top-2 "
            />
            {newsletterLink.map((link, index) => (
              <Link
                key={index}
                to={link.url}
                className="text-blue hover:text-dark-gray flex items-center underline"
              >
                <span>{link.text}</span>
              </Link>
            ))}
          </div>
          <div className="with-icon">
            <img src={Facebook} alt="facebook" className="icon-img" />
            {facebookLink.map((link, index) => (
              <Link
                key={index}
                to={link.url}
                className="text-blue hover:text-dark-gray flex items-center underline"
              >
                <span>{link.text}</span>
              </Link>
            ))}
          </div>
          <div className="with-icon">
            <img src={Instagram} alt="instagram" className="icon-img" />
            {instagramLink.map((link, index) => (
              <Link
                key={index}
                to={link.url}
                className="text-blue hover:text-dark-gray flex items-center underline"
              >
                <span>{link.text}</span>
              </Link>
            ))}
          </div>
          <div className="with-icon">
            <img src={X} alt="x" className="icon-img !top-2" />
            {xLink.map((link, index) => (
              <Link
                key={index}
                to={link.url}
                className="text-blue hover:text-dark-gray flex items-center underline"
              >
                <span>{link.text}</span>
              </Link>
            ))}
          </div>
        </div>
        <div className="other-link">
          {otherLink.map((link, index) => (
            <div
              className={`pt-[8px] ${index === 3 ? "link-mg" : ""}`}
              key={index}
            >
              <Link
                to={link.url}
                className="text-blue hover:text-dark-gray flex items-center underline"
              >
                <img
                  src={link.icon}
                  className="icon-img mr-[8px]"
                  alt={`Link to ${link.url}`}
                />
                <span>zaobaosg</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SocialContent;
``;
