import Button from "@components/Button/Button";
import {
  WithGtmTracking,
  WithGtmTrackingProps,
} from "@components/WithGtmTrackingHoC";

function SignupButton({ onClick }: { onClick?: () => void }) {
  const handleClick = () => {
    onClick?.();
    if (window._mySPHObj !== undefined) {
      window._mySPHObj.openSignUp();
    }
  };
  return (
    <Button
      rootClassName="ml-[16px] h-[30px] justify-center"
      onClick={handleClick}
    >
      免费注册
    </Button>
  );
}

export default function TrackedSignupButton(props: WithGtmTrackingProps) {
  return WithGtmTracking(SignupButton)(props);
}
