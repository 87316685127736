import Info from "./NewsletterInfo";

function NewsletterIntroduction({
  title,
  description,
  addedNews = [],
  removedNews = [],
}: {
  title: string;
  description: string[];
  addedNews?: string[];
  removedNews?: string[];
}) {
  return (
    <section className="layout-container">
      <h2 className="mt-[16px] text-display1 leading-[40px] text-brand">
        {title}
      </h2>
      {addedNews.length ? (
        <Info
          type={removedNews.length ? "add" : "stepOneAdd"}
          news={addedNews}
        ></Info>
      ) : null}
      {addedNews.length && removedNews.length ? (
        <p className="my-[8px] h-[1px] bg-[#EAEAEA]"></p>
      ) : null}
      {removedNews.length ? (
        <Info type="remove" news={removedNews}></Info>
      ) : null}
      {description.length ? (
        <section className="py-[16px] text-body1 text-primary">
          {description.map((item, index) => {
            return (
              <p
                key={index}
                className={
                  index === description.length - 1 ? "mb-0" : "mb-[40px]"
                }
              >
                {item}
              </p>
            );
          })}
        </section>
      ) : null}
    </section>
  );
}

export default NewsletterIntroduction;
