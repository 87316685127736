import esb from "elastic-builder";

type NestedFields = keyof NestedFieldsProp;

type FieldsMapping = {
  path: string;
  queryPath: string;
  nestedPath?: string;
  isKeyword: boolean;
};

/**
 * @param name 字段名
 * @param value 字段值
 * @param option 查询选项
 * @param isFuzzy 是否模糊查询
 * 
 * @example 1： 
 * 
 *  {
        option: "$should",
        name: "webCategory",
        value: ["lifestyle_campus"],
        isFuzzy: true, 
      },
 * 	Caas Query : 
      {
            "bool": {
              "should": {
                "nested": {
                  "query": {
                    "match": {
                      "data.context.sections.uniqueName": {
                        "query": "lifestyle_campus",
                        "minimum_should_match": 2
                      }
                    }
                  },
                  "path": "data.context.sections"
                }
              }
            }
          },
 */
type ShouldCondition = {
  option: "$should";
  name: NestedFields;
  value: string[];
  isFuzzy: boolean;
};

export type ExistsCondition = {
  option: "$exists";
  name: NestedFields;
};

type TimeRangeCondition = {
  option: "$timeRange";
  name: NestedFields;
  gteDate: string;
  lteDate?: string;
  timeZone: "+08:00";
};

type NormalCondition = {
  option: "$must" | "$mustNot";
  name: NestedFields;
  value: string[];
  isFuzzy?: boolean;
};

type Condition =
  | NormalCondition
  | ShouldCondition
  | TimeRangeCondition
  | ExistsCondition;

type NestedFieldsProp = {
  assets: FieldsMapping;
  assetsZbsgFlag: FieldsMapping;
  keyword: FieldsMapping;
  webCategory: FieldsMapping;
  updatedRange: FieldsMapping;
  id: FieldsMapping;
  type: FieldsMapping;
  authorId: FieldsMapping;
  tagId: FieldsMapping;
  tagType: FieldsMapping;
  tagsUrlPath: FieldsMapping;
};

export const nestedFields: NestedFieldsProp = {
  assets: {
    path: "data.context.displaySetting.displayAssetsMultiple",
    queryPath: "data.context.displaySetting.displayAssetsMultiple.keyword",
    isKeyword: true,
  },
  assetsZbsgFlag: {
    path: "data.context.displaySetting.zbsgFlag",
    queryPath: "data.context.displaySetting.zbsgFlag",
    isKeyword: false,
  },
  keyword: {
    path: "data.context.tags.name",
    queryPath: "data.context.tags.name.keyword",
    nestedPath: "data.context.tags",
    isKeyword: true,
  },
  webCategory: {
    path: "data.resolution.section.uniqueName",
    queryPath: "data.resolution.section.uniqueName.keyword",
    isKeyword: true,
  },
  updatedRange: {
    path: "data.context.updated",
    queryPath: "data.context.updated",
    isKeyword: false,
  },
  id: {
    path: "data.context.id",
    queryPath: "data.context.id",
    isKeyword: true,
  },
  type: {
    path: "data.context.type",
    queryPath: "data.context.type",
    isKeyword: true,
  },
  authorId: {
    path: "data.context.authors.profiles.content.id",
    queryPath: "data.context.authors.profiles.content.id",
    nestedPath: "data.context.authors.profiles",
    isKeyword: true,
  },
  tagId: {
    path: "data.context.tags.id",
    queryPath: "data.context.tags.id",
    nestedPath: "data.context.tags",
    isKeyword: true,
  },
  tagType: {
    path: "data.context.tags.type",
    queryPath: "data.context.tags.type",
    nestedPath: "data.context.tags",
    isKeyword: true,
  },
  tagsUrlPath: {
    path: "data.context.tags.urlPath",
    queryPath: "data.context.tags.urlPath.keyword",
    nestedPath: "data.context.tags",
    isKeyword: true,
  },
};

function generateFieldQuery(condition: NormalCondition | ShouldCondition) {
  const defaultSetting = nestedFields[condition.name];
  const queryList = condition.value.map((value) => {
    let tQuery: esb.Query = esb.termQuery(defaultSetting.queryPath, value);
    // 默认keyword 查询，如果是模糊查询，返回match查询语法
    if (!defaultSetting.isKeyword || condition.isFuzzy) {
      tQuery = esb
        .matchQuery(defaultSetting.queryPath.replace(".keyword", ""), value)
        .minimumShouldMatch(
          value
            .split("/")
            .flatMap((it) => it.split("_"))
            .flatMap((it) => it.split("-"))
            .filter(Boolean).length,
        );
    }
    // 如果索引设置是nested，返回 nested 查询语法
    if (defaultSetting.nestedPath) {
      return esb.nestedQuery(tQuery, defaultSetting.nestedPath);
    }
    return tQuery;
  });

  switch (condition.option) {
    case "$must":
      return esb.boolQuery().must(queryList);
    case "$should":
      return esb.boolQuery().should(queryList);
    case "$mustNot":
      return esb.boolQuery().mustNot(queryList);
  }
}

function generateDateRangeQuery(condition: TimeRangeCondition) {
  const defaultSetting = nestedFields[condition.name];

  const timeRange = esb.rangeQuery(defaultSetting.queryPath).timeZone("+08:00");
  if (condition.gteDate) {
    timeRange.gte(condition.gteDate);
  }
  if (condition.lteDate) {
    timeRange.lte(condition.lteDate);
  }

  return esb.boolQuery().must(timeRange);
}

function generateFieldsExistsQuery(condition: ExistsCondition) {
  const defaultSetting = nestedFields[condition.name];
  if (defaultSetting.nestedPath) {
    return esb
      .boolQuery()
      .must(
        esb.nestedQuery(
          esb.existsQuery(defaultSetting.queryPath),
          defaultSetting.nestedPath,
        ),
      );
  }
  return esb.boolQuery().must(esb.existsQuery(defaultSetting.queryPath));
}

function composeSearchBody(conditions: Condition[]): esb.BoolQuery {
  return esb.boolQuery().must(
    [...conditions, mustNotTypeNotContentList(), mustZaobaosg()].map(
      (condition) => {
        return applyConditionQuery(condition);
      },
    ),
  );
}

function applyConditionQuery(condition: Condition) {
  const { option } = condition;
  switch (option) {
    case "$timeRange":
      return generateDateRangeQuery(condition);
    case "$exists":
      return generateFieldsExistsQuery(condition);
    default:
      return generateFieldQuery(condition);
  }
}

function mustKeyword(keyword: Array<string>): Condition {
  return {
    option: "$must",
    name: "keyword",
    value: keyword,
  };
}

function mustNotKeyword(keyword: Array<string>): Condition {
  return {
    option: "$mustNot",
    name: "keyword",
    value: keyword,
  };
}
function shouldKeyword(keyword: Array<string>): Condition {
  return {
    option: "$should",
    name: "keyword",
    value: keyword,
    isFuzzy: false,
  };
}

function mustNotTypeNotContentList(): Condition {
  return {
    option: "$mustNot",
    name: "type",
    value: ["contentList"],
    isFuzzy: false,
  };
}

function mustTagType(tagType: string): Condition {
  return {
    option: "$must",
    name: "tagType",
    value: [tagType],
    isFuzzy: false,
  };
}

function mustZaobaosg(): Condition {
  return { option: "$must", name: "assetsZbsgFlag", value: ["true"] };
}

function mustNotWebCategory(category: Array<string>): Condition {
  return {
    option: "$mustNot",
    name: "webCategory",
    value: category,
  };
}
function mustNotId(idList: Array<string>): Condition {
  return {
    option: "$mustNot",
    name: "id",
    value: idList,
  };
}
function shouldId(idList: Array<string>): Condition {
  return {
    option: "$should",
    name: "id",
    value: idList,
    isFuzzy: false,
  };
}
function shouldAuthorId(idList: Array<string>): Condition {
  return {
    option: "$should",
    name: "authorId",
    value: idList,
    isFuzzy: false,
  };
}
function shouldTagId(idList: Array<string>): Condition {
  return {
    option: "$should",
    name: "tagId",
    value: idList,
    isFuzzy: false,
  };
}

function mustTagsUrlPath(aliases: Array<string>): Condition {
  return {
    option: "$must",
    name: "tagsUrlPath",
    value: aliases,
  };
}

function shouldTagsUrlPath(aliases: Array<string>): Condition {
  return {
    option: "$should",
    name: "tagsUrlPath",
    value: aliases,
    isFuzzy: true,
  };
}

function shouldTagType(aliases: Array<string>): Condition {
  return {
    option: "$should",
    name: "tagType",
    value: aliases,
    isFuzzy: true,
  };
}

function shouldWebCategory(
  category: Array<string>,
  isFuzzy: boolean,
): Condition {
  return {
    option: "$should",
    name: "webCategory",
    value: category,
    isFuzzy: isFuzzy,
  };
}
function mustHasVideo(): Condition {
  return { option: "$must", name: "assets", value: ["has_video"] };
}
function mustHasPodCast(): Condition {
  return { option: "$must", name: "assets", value: ["has_podcast"] };
}

function mustHasImage(): Condition {
  return {
    option: "$should",
    name: "assets",
    value: ["has_main_image", "has_video"],
    isFuzzy: false,
  };
}

function mustInteractiveNews(): Condition {
  return { option: "$must", name: "assets", value: ["interactive_news"] };
}

function getNestedFieldsFullPath(field: NestedFields) {
  return nestedFields[field].path;
}
export type { Condition };

export {
  composeSearchBody,
  getNestedFieldsFullPath,
  mustHasImage,
  mustHasPodCast,
  mustHasVideo,
  mustInteractiveNews,
  mustKeyword,
  mustNotId,
  mustNotKeyword,
  mustNotWebCategory,
  mustTagsUrlPath,
  mustTagType,
  shouldAuthorId,
  shouldId,
  shouldKeyword,
  shouldTagId,
  shouldTagsUrlPath,
  shouldTagType,
  shouldWebCategory,
};
