import Right from "@assets/icons/right-icon.svg";
import { useParams } from "react-router-dom";

import NewsletterCarousel from "./NewsletterCarousel";

function Result() {
  const { level2 = "" } = useParams();
  return (
    <>
      <div className="layout-container">
        <div className=" py-[16px]">
          <section className="box-border flex items-center bg-success-secondary  px-[20px] py-[8px] text-header3">
            <img
              src={Right}
              className="mr-[8px] h-[24px] w-[24px]"
              alt={Right}
            />
            <p>感谢您的反馈，您的新闻电邮速递选项已更新。</p>
          </section>
        </div>
        <div className=" py-[16px] text-body1">
          <p>
            返回
            <a
              href={`/newsletter-unsubscribe/${level2}`}
              className="cursor-pointer text-brand-secondary underline"
            >
              新闻电邮速递管理
            </a>
          </p>
        </div>
      </div>
      <section className="layout-container">
        <p className="my-[8px] border-t border-[divider-primary] !px-[-2em]"></p>
      </section>
      <section className="layout-container">
        <div className=" py-[16px] text-header2 text-primary">
          <p>欢迎您关注我们，以获取更多精彩资讯。</p>
        </div>
      </section>
      <section className="mb-[40px]">
        <NewsletterCarousel />
      </section>
    </>
  );
}

export default Result;
