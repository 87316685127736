import iconEmail from "@assets/footer/email.svg";
import iconFacebook from "@assets/footer/facebook.svg";
import iconInstagram from "@assets/footer/instagram.svg";
import iconTelegram from "@assets/footer/telegram.svg";
import iconTwitter from "@assets/footer/twitter-x.svg";
import iconWechat from "@assets/footer/wechat.svg";
import iconWeibo from "@assets/footer/weibo.svg";
import iconYoutube from "@assets/footer/youtube.svg";
import defaultIconAppGoogle from "@assets/icon-app-google.png";
import defaultIconAppIOS from "@assets/icon-app-ios.png";
import iconWhatsapp from "@assets/icons/whatsup.png";
import { type IconDefinition } from "@fortawesome/free-brands-svg-icons";

export type HotlinksItem = {
  key: string;
  label: string;
  href: string;
};

export type SocialLinkConfig = {
  src?: string;
  faIcon?: IconDefinition;
  link: string;
  alt?: string;
  label?: React.ReactNode;
};

export type CopyrightItem = {
  mci: string;
  name: string;
  cn_vistor: string;
  link_label: string;
  link: string;
  reminder: string;
  warning: string;
};

export type TelphoneItem = {
  title: string;
  central_tel_label: string;
  central_tel: number;
  hotline_label: string;
  hotline_tel: number;
  hotline_ads_label: string;
  hotline_ads_tel: number;
  news_hotline_label: string;
  news_hotline_tel1: string;
  news_hotline_label2: string;
  news_hotline_tel2: number;
};

export const hotlinksList = {
  sph_media: {
    key: "sph_media",
    label: "新报业媒体网站",
    href: "https://www.sph.com.sg/",
  },
  sph_tnc: {
    key: "sph_tnc",
    label: "网络条款",
    href: "https://www.sph.com.sg/legal/website_tnc/",
  },
  sph_privacy: {
    key: "sph_privacy",
    label: "隐私政策",
    href: "https://www.sph.com.sg/legal/sph_privacy/",
  },
  sph_pdpa: {
    key: "sph_pdpa",
    label: "个人资料保护",
    href: "https://www.sph.com.sg/legal/pdpa/",
  },
  newslink: {
    key: "newslink",
    label: "全检索",
    href: "https://newslink.sg/",
  },
  zbshop: {
    key: "zbshop",
    label: "ZShop集品店",
    href: "https://zshop.zaobao.sg/",
  },
  straitstimes: {
    key: "straitstimes",
    label: "海峡时报",
    href: "https://www.straitstimes.com/",
  },
  businesstimes: {
    key: "businesstimes",
    label: "商业时报",
    href: "https://www.businesstimes.com.sg/",
  },
};

const downloadLinks = {
  ios: {
    id: 1,
    src: defaultIconAppIOS,
    link: "https://itunes.apple.com/us/app/lian-he-zao-bao/id654946831?ls=1&mt=8",
    alt: "Download on the Apple Store",
  },
  google: {
    id: 2,
    src: defaultIconAppGoogle,
    link: "https://play.google.com/store/apps/details?id=com.zb.sph.zaobaosingapore",
    alt: "Download on the Google Play Store",
  },
};

const appDownloadLinks = [downloadLinks.ios, downloadLinks.google];

const socialLinks: Record<string, SocialLinkConfig> = {
  fb: {
    src: iconFacebook,
    link: "https://www.facebook.com/zaobaosg",
    alt: "分享联合早报脸书",
  },
  twitter: {
    src: iconTwitter,
    link: "https://twitter.com/zaobaosg",
    alt: "分享联合早报推特",
  },
  youtube: {
    src: iconYoutube,
    link: "https://www.youtube.com/zaobaodotsg",
    alt: "分享早报youtube",
  },
  ig: {
    src: iconInstagram,
    link: "https://www.instagram.com/zaobaosg/",
    alt: "分享联合早报Instagram",
  },
  telegram: {
    src: iconTelegram,
    link: "https://t.me/zaobaosg",
    alt: "分享联合早报Telegram",
  },
  sina: {
    src: iconWeibo,
    link: "https://www.weibo.com/zaobaocom",
    alt: "分享联合早报新浪微博账号",
  },
  wechat: {
    src: iconWechat,
    link: "/products#wechat",
    alt: "分享联合早报微信",
  },
  email: {
    src: iconEmail,
    link: "mailto:zbonline@sph.com.sg",
    alt: "分享联合早报邮箱",
  },
  whatsapp: {
    src: iconWhatsapp,
    link: "https://wa.me",
    alt: "分享联合早报whatsapp",
  },
};

const footerSocialLinks = [
  socialLinks.fb,
  socialLinks.twitter,
  socialLinks.youtube,
  socialLinks.ig,
  socialLinks.telegram,
  socialLinks.sina,
  socialLinks.wechat,
  socialLinks.email,
];

const copyright: CopyrightItem = {
  mci: "MDDI (P) 042/10/2024, MDDI (P) 043/10/2024",
  name: "新报业媒体有限公司版权所有（公司登记号：202120748H）",
  cn_vistor: "在中国的用户请游览",
  link_label: "zaobao.com",
  link: "https://www.zaobao.com",
  reminder: "提醒：新加坡网络业者若未经许可，擅自引用本网内容将面对法律行动。",
  warning:
    "第三方公司可能在早报网站宣传他们的产品或服务。不过您跟第三方公司的任何交易与早报网站无关，早报将不会对可能引起的任何损失负责。",
};

const footerHotlinks: HotlinksItem[] = [
  hotlinksList.sph_media,
  hotlinksList.sph_tnc,
  hotlinksList.sph_privacy,
  hotlinksList.sph_pdpa,
  hotlinksList.newslink,
  hotlinksList.zbshop,
  hotlinksList.straitstimes,
  hotlinksList.businesstimes,
];

const telephones: TelphoneItem = {
  title: "热线",
  central_tel_label: "新报业媒体总机 ",
  central_tel: 63196319,
  hotline_label: "订阅热线 ",
  hotline_tel: 63883838,
  hotline_ads_label: "早报广告联系 ",
  hotline_ads_tel: 63196319,
  news_hotline_label: "新闻热线",
  news_hotline_tel1: "1800-7416388",
  news_hotline_label2: " 或 ",
  news_hotline_tel2: 92288736,
};

export {
  appDownloadLinks,
  copyright,
  footerHotlinks,
  footerSocialLinks,
  socialLinks,
  telephones,
};
