import {
  mustHasImage,
  mustHasPodCast,
  mustHasVideo,
  mustInteractiveNews,
  mustKeyword,
  mustNotKeyword,
  mustNotWebCategory,
  mustTagsUrlPath,
  shouldKeyword,
  shouldTagType,
  shouldWebCategory,
} from "@api/backend/caas-mappings";

import { BackendNavigation } from "./core/navigation-settings.helper";

export const defaultBackendNavigationDictionary: Record<
  string,
  BackendNavigation
> = {
  global: {
    key: "global",
    label: "国际版",
    href: "/global",
    dataSourceConfig: [
      {
        type: "storeQueue",
        envKeyWithQueueId: "VITE_GLOBAL_ROTATOR_LEFT_QUEUE_ID",
        key: "rotator_left",
      },
      {
        type: "storeQueue",
        envKeyWithQueueId: "VITE_GLOBAL_ROTATOR_RIGHT_QUEUE_ID",
        key: "rotator_right",
      },
      {
        type: "storeQueue",
        envKeyWithQueueId: "VITE_GLOBAL_ROTATOR_BOTTOM_QUEUE_ID",
        key: "rotator_bottom",
      },
      {
        type: "refByNavigation",
        key: "news",
        dataSourceConfig: [
          { type: "refByNavigation", key: "news_world" },
          { type: "refByNavigation", key: "news_china" },
          { type: "refByNavigation", key: "news_singapore" },
          { type: "refByNavigation", key: "news_sea" },
        ],
        condition: [
          shouldWebCategory(["news_china", "news_world", "news_sea"], false),
        ],
        size: 5,
      },
      { type: "refByNavigation", key: "forum_zaodian", size: 6 },
      {
        type: "refByNavigation",
        key: "forum_comic",
        condition: [mustKeyword(["王锦松漫画"])],
        size: 3,
      },
      { type: "refByNavigation", key: "wencui", size: 9 },
      {
        type: "refByNavigation",
        key: "finance",
        dataSourceConfig: [
          { type: "refByNavigation", key: "finance_world" },
          { type: "refByNavigation", key: "finance_china" },
          { type: "refByNavigation", key: "finance_usstock" },
          { type: "refByNavigation", key: "finance_invest" },
          { type: "refByNavigation", key: "finance_real-estate" },
          { type: "refByNavigation", key: "finance_singapore" },
        ],
        condition: [
          shouldWebCategory(["finance_world"], false),
          mustKeyword(["美市"]),
        ],
        size: 3,
      },
      {
        type: "refByNavigation",
        key: "lifestyle",
        dataSourceConfig: [
          { type: "refByNavigation", key: "lifestyle_trending" },
          { type: "refByNavigation", key: "lifestyle_feature" },
          { type: "refByNavigation", key: "lifestyle_travel" },
          { type: "refByNavigation", key: "lifestyle_gadget" },
          { type: "refByNavigation", key: "lifestyle_health" },
          { type: "refByNavigation", key: "lifestyle_fashion-beauty" },
          { type: "refByNavigation", key: "lifestyle_food" },
          { type: "refByNavigation", key: "lifestyle_culture" },
          { type: "refByNavigation", key: "entertainment" },
        ],
        condition: [
          shouldWebCategory(["lifestyle", "entertainment"], true),
          mustKeyword(["美市"]),
        ],
        size: 3,
      },
      { type: "refByNavigation", key: "news_sports", size: 3 },
      { type: "refByNavigation", key: "videos", dataSourceConfig: [], size: 3 },
    ],
    size: undefined,
  },
  wencui: {
    key: "wencui",
    label: "各地文萃",
    href: "/wencui",
    condition: [
      shouldWebCategory(["webcui_political", "wencui_social"], false),
    ],
    size: undefined,
  },
  zbclub: {
    key: "zbclub",
    label: "早报俱乐部",
    href: "/zbclub",
    noSideBar: true,
    // this will result into a 'singleCarouselArticles' data
    envKeyWithQueueId: "VITE_ZBCLUB_ROTATOR_QUEUE_ID",
    // zbclub allows an empty story queue
    allowEmptyQueue: true,
    dataSourceEnvironment: {
      contentListEnvKey: "VITE_ZBCLUB_CONTENT_LIST_ID",
      type: "config",
      settings: {
        type: "mustKeyword",
        level1Condition: [], // zbclub listing page should show articles without main_image as well
        level2Condition: [],
        // seo is hardcoded in page template
        baseSeoTitle: "",
        baseSeoDescription: "",
      },
    },
    // size is paired with condition, for example `/zbclub` is referenced by other nav
    size: undefined,
  },
  ["readersclub_events"]: {
    key: "readersclub_events",
    label: "活动",
    href: "/readersclub/events",
    condition: [mustHasImage(), mustKeyword(["早报俱乐部", "活动"])],
    size: 8,
  },

  home: {
    key: "home",
    label: "首页",
    href: "/",
    hasPodCast: true,
    hasNewsTicker: true,
    dataSourceConfig: [
      {
        type: "storeQueue",
        envKeyWithQueueId: "VITE_HOMEPAGE_QUEUE_ID",
        key: "today_recommend_article",
      },
      {
        type: "refByNavigation",
        key: "news",
        mainArticle: {
          size: 1,
          condition: [
            shouldWebCategory(
              ["news_singapore", "news_china", "news_world", "news_sea"],
              false,
            ),
            mustHasImage(),
          ],
        },
        dataSourceConfig: [
          {
            type: "refByNavigation",
            key: "news_singapore",
            label: "新加坡",
            displaySettings: { cardDisplayMode: "ImmediateArticleList" },
          },
          { type: "refByNavigation", key: "news_world" },
          { type: "refByNavigation", key: "news_china" },
          { type: "refByNavigation", key: "news_sea" },
          { type: "refByNavigation", key: "special" },
        ],
        size: 7,
      },
      {
        type: "refByNavigation",
        key: "finance",
        mainArticle: {
          size: 1,
          condition: [
            shouldWebCategory(
              ["finance_singapore", "finance_china", "finance_world"],
              false,
            ),
            mustHasImage(),
          ],
        },
        dataSourceConfig: [
          {
            type: "refByNavigation",
            key: "finance_singapore",
            label: "新加坡",
          },
          { type: "refByNavigation", key: "finance_world", label: "全球" },
          { type: "refByNavigation", key: "finance_china", label: "中国" },
          { type: "refByNavigation", key: "finance_invest" },
          { type: "refByNavigation", key: "finance_real-estate" },
          { type: "refByNavigation", key: "finance_usstock" },
          { type: "refByNavigation", key: "finance_sme" },
          { type: "refByNavigation", key: "finance_startup" },
          { type: "refByNavigation", key: "finance_personalities" },
          { type: "refByNavigation", key: "finance_stock" },
        ],
        size: 7,
      },
      {
        type: "drupal",
        options: {
          url: "/web2/homepage-special-topic",
          params: {},
        },
        key: "homepage_special_topic",
      },
      {
        type: "drupal",
        options: {
          url: "/web2/homepage-campaign-carousel",
          params: {},
        },
        key: "homepage_campaign_carousel",
      },
      {
        type: "refByNavigation",
        key: "forum",
        mainArticle: {
          size: 1,
          condition: [
            shouldWebCategory(["forum_editorial"], false),
            mustHasImage(),
          ],
        },
        size: 5,
      },
      {
        type: "refByNavigation",
        key: "entertainment",
        dataSourceConfig: [
          { type: "refByNavigation", key: "entertainment_celebs" },
          { type: "refByNavigation", key: "entertainment_movies-and-tv" },
          { type: "refByNavigation", key: "entertainment_music" },
          { type: "refByNavigation", key: "entertainment_k-pop" },
          { type: "refByNavigation", key: "entertainment_giveaway" },
        ],
        size: 3,
      },
      {
        type: "refByNavigation",
        key: "videos",
        size: 4,
        displaySettings: { cardDisplayMode: "darkCard" },
      },
      {
        type: "refByNavigation",
        key: "lifestyle",
        dataSourceConfig: [
          { type: "refByNavigation", key: "lifestyle_trending" },
          { type: "refByNavigation", key: "lifestyle_feature" },
          { type: "refByNavigation", key: "lifestyle_gadget" },
          { type: "refByNavigation", key: "lifestyle_fashion-beauty" },
          { type: "refByNavigation", key: "lifestyle_familynlove" },
          { type: "refByNavigation", key: "lifestyle_culture" },
          { type: "refByNavigation", key: "lifestyle_columns" },
          { type: "refByNavigation", key: "lifestyle_latenightreads" },
        ],
        size: 4,
      },
      {
        type: "refByNavigation",
        key: "lifestyle_health",
        condition: [mustHasImage()],
        size: 4,
      },
      {
        type: "refByNavigation",
        key: "lifestyle_food",
        condition: [mustHasImage()],
        size: 4,
      },
      {
        type: "refByNavigation",
        key: "lifestyle_travel",
        condition: [mustHasImage()],
        size: 4,
      },
      {
        type: "refByNavigation",
        key: "news_sports",
        condition: [mustHasImage()],
        size: 4,
      },
    ],
    size: undefined,
  },
  news: {
    key: "news",
    label: "新闻",
    href: "/news",
    seoTitle: "早报新闻 | 联合早报 - 享誉新加坡与国际的新闻媒体",
    seoDescription:
      "早报新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡、中国、东南亚、亚洲和国际新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",
    dataSourceConfig: [
      {
        type: "refByNavigation",
        key: "realtime",
        displaySettings: { cardDisplayMode: "ImmediateArticleList" },
        size: 9,
      },
      {
        type: "refByNavigation",
        key: "news_singapore",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "news_world",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "news_china",
        condition: [mustHasImage()],
      },
      { type: "refByNavigation", key: "news_sea", condition: [mustHasImage()] },
      {
        type: "refByNavigation",
        key: "news_sports",
        condition: [mustHasImage()],
        displaySettings: { isHiddenInNavigation: true },
      },
    ],
    condition: [
      shouldWebCategory(
        ["news_singapore", "news_china", "news_world", "news_sea"],
        false,
      ),
    ],
    rss: {
      days: 1,
      condition: [shouldWebCategory(["news"], true)],
    },
    size: 6,
  },
  news_singapore: {
    key: "news_singapore",
    label: "新加坡",
    href: "/news/singapore",
    seoTitle: "新加坡新闻 | 联合早报 - 享誉新加坡与国际的新闻媒体",
    seoDescription:
      "新加坡新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡的最新新闻和热门新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["news_singapore"], false)],
    size: 3,
  },
  news_world: {
    key: "news_world",
    label: "国际",
    href: "/news/world",
    seoTitle: "国际新闻 | 联合早报 - 享誉新加坡与国际的新闻媒体",
    seoDescription:
      "国际新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供国际最新新闻和热门新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["news_world"], false)],
    size: 3,
  },
  news_china: {
    key: "news_china",
    label: "中国",
    href: "/news/china",
    seoTitle: "中国新闻 | 联合早报 - 享誉新加坡与国际的新闻媒体",
    seoDescription:
      "中国新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供中国的最新新闻和热门新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["news_china"], false)],
    size: 3,
  },
  news_sea: {
    key: "news_sea",
    label: "东南亚",
    href: "/news/sea",
    seoTitle: "东南亚新闻 | 联合早报 - 享誉新加坡与国际的新闻媒体",
    seoDescription:
      "东南亚新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供东南亚的最新新闻和热门新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["news_sea"], false)],
    size: 3,
  },

  news_sports: {
    key: "news_sports",
    label: "体育",
    href: "/news/sports",
    seoTitle: "国际与新加坡体育新闻 | 联合早报",
    seoDescription:
      "国际与新加坡体育新闻 -《联合早报》每天为你提供最新体育新闻与评论。内容包括足球（英超、意甲、西甲、德甲、欧冠）、篮球（NBA）、F1、羽毛球、乒乓、游泳、网球等项目。",

    condition: [shouldWebCategory(["news_sports"], false)],
    size: 3,
  },
  realtime: {
    key: "realtime",
    label: "即时",
    href: "/realtime",
    seoTitle: "即时新闻 | 联合早报",
    seoDescription:
      "即时新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡国内外的即时新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    dataSourceConfig: [
      {
        type: "refByNavigation",
        key: "realtime_singapore",
        displaySettings: { cardDisplayMode: "TimestampArticleCard" },
        size: 20,
      },
      {
        type: "refByNavigation",
        key: "realtime_china",
        displaySettings: { cardDisplayMode: "TimestampArticleCard" },
        size: 20,
      },
      {
        type: "refByNavigation",
        key: "realtime_world",
        displaySettings: { cardDisplayMode: "TimestampArticleCard" },
        size: 20,
      },
      {
        type: "refByNavigation",
        key: "realtime_finance",
        displaySettings: { cardDisplayMode: "TimestampArticleCard" },
        size: 20,
      },
    ],
    condition: [
      shouldWebCategory(
        ["realtime_singapore", "realtime_china", "realtime_world"],
        false,
      ),
    ],
    rss: {
      days: 1,
      condition: [shouldWebCategory(["realtime"], true)],
    },
    size: 9,
  },
  realtime_singapore: {
    key: "realtime_singapore",
    label: "新加坡",
    label_en: "Singapore",
    href: "/realtime/singapore",
    seoTitle: "新加坡即时新闻 | 联合早报",
    seoDescription:
      "新加坡即时新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡的即时新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["realtime_singapore"], false)],
    size: 20,
  },
  realtime_china: {
    key: "realtime_china",
    label: "中国",
    label_en: "China",
    href: "/realtime/china",
    seoTitle: "中国即时新闻 | 联合早报",
    seoDescription:
      "中国即时新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供中国的即时新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["realtime_china"], false)],
    size: 20,
  },
  realtime_world: {
    key: "realtime_world",
    label: "国际",
    label_en: "World",
    href: "/realtime/world",
    seoTitle: "国际即时新闻 | 联合早报",
    seoDescription:
      "国际即时新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供全球国际的即时新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["realtime_world"], false)],
    size: 20,
  },
  realtime_finance: {
    key: "realtime_finance",
    label: "财经即时",
    label_en: "Finance",
    href: "/realtime/finance",
    condition: [
      shouldWebCategory(
        ["realtime_singapore", "realtime_china", "realtime_world"],
        false,
      ),
      mustKeyword(["财经即时"]),
    ],
    size: 20,
  },

  finance: {
    key: "finance",
    label: "财经",
    href: "/finance",
    seoTitle: "财经新闻 | 联合早报",
    seoDescription:
      "财经新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你更新新加坡、中国、东南亚、亚洲和国际的财经新闻。深入了解最新的市场趋势和经济分析, 尽在zaobao.com.sg。",

    dataSourceConfig: [
      { type: "refByNavigation", key: "finance_stock" },
      {
        type: "refByNavigation",
        key: "realtime_finance",
        displaySettings: {
          isHiddenInNavigation: true,
          cardDisplayMode: "ImmediateArticleList",
        },
        size: 9,
      },
      { type: "refByNavigation", key: "finance_singapore" },
      { type: "refByNavigation", key: "finance_world" },
      { type: "refByNavigation", key: "finance_china" },
      { type: "refByNavigation", key: "finance_invest" },
      { type: "refByNavigation", key: "finance_real-estate" },
      { type: "refByNavigation", key: "finance_usstock" },
      { type: "refByNavigation", key: "finance_sme" },
      { type: "refByNavigation", key: "finance_startup" },
      { type: "refByNavigation", key: "finance_personalities" },
    ],
    condition: [
      shouldWebCategory(
        ["finance_singapore", "finance_china", "finance_world"],
        false,
      ),
    ],
    rss: {
      days: 1,
      condition: [shouldWebCategory(["finance"], true)],
    },
    size: 6,
  },
  finance_stock: {
    key: "finance_stock",
    label: "新加坡股市",
    href: "https://stock.zaobao.com.sg/",
    size: undefined,
  },
  finance_singapore: {
    key: "finance_singapore",
    label: "新加坡财经",
    href: "/finance/singapore",
    seoTitle: "新加坡财经与经济新闻 | 联合早报",
    seoDescription:
      "新加坡财经新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你更新新加坡的财经新闻。深入了解新加坡最新的市场趋势、经济分析和理财技巧, 尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["finance_singapore"], false)],
    size: 3,
  },
  finance_world: {
    key: "finance_world",
    label: "全球财经",
    href: "/finance/world",
    seoTitle: "全球财经、经济与股市新闻 | 联合早报",
    seoDescription:
      "全球财经新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你更新全球经济与股市的新闻。深入了解全球最新的市场趋势、经济分析和理财技巧, 尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["finance_world"], false)],
    size: 3,
  },
  finance_china: {
    key: "finance_china",
    label: "中国财经",
    href: "/finance/china",
    seoTitle: "中国经济与财经新闻 | 联合早报",
    seoDescription:
      "中国财经新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你更新中国的财经新闻。深入了解中国最新的市场趋势、经济分析和理财技巧, 尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["finance_china"], false)],
    size: 3,
  },
  finance_invest: {
    key: "finance_invest",
    label: "投资理财",
    href: "/finance/invest",
    condition: [
      shouldWebCategory(
        ["finance_singapore", "finance_china", "finance_world"],
        false,
      ),
      mustKeyword(["投资理财"]),
    ],
    seoTitle: "股票、投资与理财 | 联合早报",
    seoDescription:
      "股票、投资与理财 -《联合早报》是全球华文用户信任的媒体，每天即时为你更新关于股票投资与理财的新闻。深入了解最新的市场趋势、经济分析和理财技巧, 尽在zaobao.com.sg。",

    size: 3,
  },
  "finance_real-estate": {
    key: "finance_real-estate",
    label: "房产",
    href: "/finance/real-estate",
    condition: [
      shouldWebCategory(
        ["finance_singapore", "finance_china", "finance_world"],
        false,
      ),
      mustKeyword(["房产"]),
    ],
    seoTitle: "房地产新闻 | 联合早报",
    seoDescription:
      "房地产新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供关于投资房产与房地产的新闻。深入了解最新的市场趋势、经济分析和投资技巧, 尽在zaobao.com.sg。",

    size: 3,
  },
  finance_usstock: {
    key: "finance_usstock",
    label: "美国股市",
    href: "/finance/usstock",
    condition: [
      shouldWebCategory(
        [
          "realtime",
          "realtime_singapore",
          "realtime_world",
          "realtime_china",
          "finance_singapore",
          "finance_china",
          "finance_world",
        ],
        false,
      ),
      mustKeyword(["美市"]),
    ],
    seoTitle: "美国股市新闻 | 联合早报",
    seoDescription:
      "美国股市新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供美国的股市与财经新闻。深入了解最新的市场趋势、经济分析和理财技巧, 尽在zaobao.com.sg。",

    size: 3,
  },
  finance_sme: {
    key: "finance_sme",
    label: "中小企业",
    href: "/finance/sme",
    condition: [
      shouldWebCategory(
        ["finance_singapore", "finance_china", "finance_world"],
        false,
      ),
      mustKeyword(["中小企业"]),
    ],
    seoTitle: "中小企业新闻 | 联合早报",
    seoDescription:
      "中小企业新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你更新关于中小企业的新闻。深入了解最新的市场趋势、经济分析和投资技巧, 尽在zaobao.com.sg。",

    size: 3,
  },
  finance_startup: {
    key: "finance_startup",
    label: "起步创新",
    href: "/finance/startup",
    condition: [
      shouldWebCategory(
        [
          "finance_singapore",
          "finance_china",
          "finance_world",
          "realtime",
          "realtime_singapore",
          "realtime_world",
          "realtime_china",
        ],
        false,
      ),
      mustKeyword(["起步创新"]),
    ],
    seoTitle: "初创公司：创业故事与新闻 | 联合早报",
    seoDescription:
      "初创公司新闻 -《联合早报》是全球华文用户信任的媒体，每天即时为你更新关于初创公司的新闻。深入了解最新的市场趋势、经济分析和投资技巧, 尽在zaobao.com.sg。",

    size: 3,
  },
  finance_personalities: {
    key: "finance_personalities",
    label: "财经人物",
    href: "/finance/personalities",
    condition: [
      shouldWebCategory(
        ["finance_singapore", "finance_china", "finance_world"],
        false,
      ),
      mustKeyword(["财经人物"]),
    ],
    seoTitle: "投资与财经人物 | 联合早报",
    seoDescription:
      "投资与财经人物-《联合早报》是全球华文用户信任的媒体。我们专访各界金融领袖，分享他们的成功经历与策略洞察。更多投资家与财经人物的专访，尽在zaobao.com.sg。",

    size: 3,
  },
  forum: {
    key: "forum",
    label: "言论",
    href: "/forum",
    seoTitle: "言论与观点 | 联合早报",
    seoDescription:
      "言论与观点 -《联合早报》是全球华文用户信任的媒体，每天与你分享关于政治、社会、文化等热门议题的言论与观点。深入探讨各种议题, 尽在zaobao.com.sg。",

    dataSourceConfig: [
      { type: "refByNavigation", key: "forum_editorial" },
      { type: "refByNavigation", key: "forum_views" },
      { type: "refByNavigation", key: "forum_talk" },
      { type: "refByNavigation", key: "forum_comic" },
    ],
    condition: [shouldWebCategory(["forum_views"], false)],
    rss: {
      days: 1,
      condition: [shouldWebCategory(["forum"], true)],
    },
    size: 4,
  },
  forum_editorial: {
    key: "forum_editorial",
    href: "/forum/editorial",
    label: "社论",
    seoTitle: "社论文章 | 联合早报",
    seoDescription:
      "社论文章 -《联合早报》是全球华文用户信任的媒体，每天与你分享关于政治、社会、文化等热门议题的社论文章。深入探讨各种议题, 尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["forum_editorial"], false)],
    size: 3,
  },
  forum_views: {
    key: "forum_views",
    label: "评论",
    href: "/forum/views",
    seoTitle: "时事与新闻评论 | 联合早报",
    seoDescription:
      "时事与新闻评论 -《联合早报》是全球华文用户信任的媒体，每天与你共享关于政治、社会、文化等热门议题的时事评论。深入探讨各种议题, 尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["forum_views"], false)],
    size: 3,
  },
  forum_talk: {
    key: "forum_talk",
    label: "交流站",
    href: "/forum/talk",
    seoTitle: "观点交流站 | 联合早报",
    seoDescription:
      "观点交流站 -《联合早报》是全球华文用户信任的媒体，每天与你共享关于政治、社会、文化等热门议题的观点。深入探讨各种议题, 尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["forum_talk"], false)],
    size: 3,
  },
  forum_comic: {
    key: "forum_comic",
    label: "漫画",
    href: "/forum/comic",
    seoTitle: "漫画 | 联合早报",
    seoDescription:
      "早报在线漫画 -《联合早报》是全球华文用户信任的媒体，每天与你分享关于政治、社会、文化等热门议题的漫画。深入探讨各种议题, 尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["forum_comic"], false)],
    size: 3,
  },
  forum_zaodian: {
    key: "forum_zaodian",
    label: "中国早点",
    href: "/forum/zaodian",
    condition: [mustTagsUrlPath(["/forum/zaodian"])],
    size: undefined,
  },
  forum_expert: {
    key: "forum_expert",
    label: "名家专评",
    href: "/forum/expert",
    size: undefined,
  },
  entertainment: {
    key: "entertainment",
    label: "娱乐",
    href: "/entertainment",
    seoTitle: "最新娱乐新闻 | 联合早报",
    seoDescription:
      "最新娱乐新闻 -《联合早报》是全球华文用户信任的媒体，每天为你带来最新、最热门的娱乐新闻。掌握大中华、东南亚、欧美、日韩等娱乐动态, 尽在zaobao.com.sg。",

    dataSourceConfig: [
      {
        type: "refByNavigation",
        key: "entertainment_has_video",
        displaySettings: {
          cardDisplayMode: "darkCard",
          isHiddenInNavigation: true,
        },
      },
      {
        type: "refByNavigation",
        key: "entertainment_celebs",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "entertainment_movies-and-tv",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "entertainment_music",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "entertainment_k-pop",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "entertainment_giveaway",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "entertainment_news",
        condition: [mustHasImage()],
        displaySettings: { isHiddenInNavigation: true },
      },
    ],
    condition: [shouldWebCategory(["entertainment"], false), mustHasImage()],
    envKeyWithQueueId: "VITE_ENTERTAINMENT_QUEUE_ID",
    rss: {
      days: 1,
      condition: [shouldWebCategory(["entertainment"], true)],
    },
    size: 3,
  },
  entertainment_has_video: {
    key: "entertainment_has_video",
    label: "精选视频",
    href: undefined,
    condition: [shouldWebCategory(["entertainment"], false), mustHasVideo()],
    size: 6,
  },
  entertainment_celebs: {
    key: "entertainment_celebs",
    label: "明星",
    href: "/entertainment/celebs",
    seoTitle: "最新明星新闻 | 联合早报",
    seoDescription:
      "最新明星新闻 -《联合早报》是全球华文用户信任的媒体，每天为你带来最新的明星新闻。掌握大中华、东南亚、欧美、日韩等娱乐新闻与动态, 尽在zaobao.com.sg。",

    condition: [
      shouldWebCategory(["entertainment"], false),
      mustKeyword(["明星"]),
    ],
    size: 3,
  },
  "entertainment_movies-and-tv": {
    key: "entertainment_movies-and-tv",
    label: "影视",
    href: "/entertainment/movies-and-tv",
    seoTitle: "最新电影、影视新闻 | 联合早报",
    seoDescription:
      "最新电影、影视新闻 -《联合早报》是全球华文用户信任的媒体，每天为你带来最新、最热门的电影与影视新闻。掌握大中华、东南亚、欧美、日韩等娱乐动态, 尽在zaobao.com.sg。",

    condition: [
      shouldWebCategory(["entertainment"], false),
      mustKeyword(["影视"]),
    ],
    size: 3,
  },
  entertainment_music: {
    key: "entertainment_music",
    label: "音乐",
    href: "/entertainment/music",
    seoTitle: "最新音乐新闻 | 联合早报",
    seoDescription:
      "最新音乐新闻 -《联合早报》是全球华文用户信任的媒体，每天为你带来最新、最热门的音乐新闻。掌握大中华、东南亚、欧美、日韩等娱乐动态, 尽在zaobao.com.sg。",

    condition: [
      shouldWebCategory(["entertainment"], false),
      mustKeyword(["音乐"]),
    ],
    size: 3,
  },
  "entertainment_k-pop": {
    key: "entertainment_k-pop",
    label: "韩流",
    href: "/entertainment/k-pop",
    seoTitle: "最新 K-pop 韩流新闻 | 联合早报",
    seoDescription:
      "最新K-Pop韩流新闻 -《联合早报》是全球华文用户信任的媒体，每天为你带来最新、最热门的韩星与韩剧新闻。掌握大中华、东南亚、欧美、日韩等娱乐动态, 尽在zaobao.com.sg。",

    condition: [
      shouldWebCategory(["entertainment"], false),
      mustKeyword(["韩流"]),
    ],
    size: 3,
  },
  entertainment_giveaway: {
    key: "entertainment_giveaway",
    label: "送礼",
    href: "/entertainment/giveaway",
    seoTitle: "送票送礼 | 联合早报",
    seoDescription:
      "早报送票送礼 -《联合早报》是全球华文用户信任的媒体，为你准备了精彩的新加坡演唱会门票、电影票等礼品。参加送礼活动赢得礼物, 尽在zaobao.com.sg。",

    condition: [
      shouldWebCategory(["entertainment"], false),
      mustKeyword(["送礼"]),
    ],
    size: 3,
  },
  entertainment_news: {
    key: "entertainment_news",
    label: "星闻",
    href: "/entertainment/news",
    condition: [
      shouldWebCategory(["entertainment"], false),
      mustNotKeyword(["明星", "影视", "音乐", "韩流", "送礼"]),
    ],
    size: 3,
  },

  lifestyle: {
    key: "lifestyle",
    label: "生活",
    href: "/lifestyle",
    seoTitle: "生活 | 联合早报",
    seoDescription:
      "生活 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的生活资讯。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",
    dataSourceConfig: [
      {
        type: "refByNavigation",
        key: "specials_active-aging",
        displaySettings: { isHiddenInLayout: true },
      },
      {
        type: "refByNavigation",
        key: "lifestyle_has_video",
        displaySettings: {
          cardDisplayMode: "darkCard",
          isHiddenInNavigation: true,
        },
        size: 6,
      },
      {
        type: "refByNavigation",
        key: "lifestyle_feature",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_food",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_travel",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_culture",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_history-heritage",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_columns",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_gen",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_eco",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_fashion-beauty",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_design-decor",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_photography",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_gadget",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_tech-science",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_motoring",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_familynlove",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_others",
        condition: [],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_supplement",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_campus",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_trending",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_life-hacks",
        condition: [mustHasImage()],
      },
      {
        type: "refByNavigation",
        key: "lifestyle_horoscope",
        displaySettings: { isHiddenInLayout: true },
      },
    ],
    envKeyWithQueueId: "VITE_LIFESTYLE_QUEUE_ID",
    condition: [
      shouldWebCategory(["lifestyle"], true),
      mustNotWebCategory([
        "lifestyle_health",
        "lifestyle_food",
        "lifestyle_travel",
        "lifestyle",
      ]),
      mustHasImage(),
    ],
    rss: {
      days: 1,
      condition: [shouldWebCategory(["lifestyle"], true)],
    },
    size: 4,
  },

  lifestyle_has_video: {
    key: "lifestyle_has_video",
    label: "精选视频",
    href: undefined,
    condition: [shouldWebCategory(["lifestyle"], true), mustHasVideo()],
    size: 3,
  },

  lifestyle_trending: {
    key: "lifestyle_trending",
    label: "热门",
    href: "/lifestyle/trending",
    seoTitle: "热门话题 | 联合早报",
    seoDescription:
      "热门话题 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的热门生活资讯。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_trending"], false)],
    size: 3,
  },
  lifestyle_food: {
    key: "lifestyle_food",
    label: "美食",
    href: "/lifestyle/food",
    seoTitle: "新加坡生活美食 | 联合早报",
    seoDescription:
      "新加坡生活美食 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的美食资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_food"], false)],
    size: 3,
  },
  lifestyle_feature: {
    key: "lifestyle_feature",
    label: "特写",
    href: "/lifestyle/feature",
    seoTitle: "生活特写 | 联合早报",
    seoDescription:
      "生活特写 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的生活资讯。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_feature"], false)],
    size: 3,
  },
  lifestyle_gen: {
    key: "lifestyle_gen",
    label: "@世代",
    href: "/lifestyle/gen",
    seoTitle: "世代 | 联合早报",
    seoDescription:
      "@世代 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于新加坡青少年与Z时代的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_gen"], false)],
    size: 3,
  },
  "lifestyle_life-hacks": {
    key: "lifestyle_life-hacks",
    label: "生活贴士",
    href: "/lifestyle/life-hacks",
    seoTitle: "生活小贴士 | 联合早报",
    seoDescription:
      "生活小贴士 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的生活贴士。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_life-hacks"], false)],
    size: 3,
  },
  lifestyle_travel: {
    key: "lifestyle_travel",
    label: "旅行",
    href: "/lifestyle/travel",
    seoTitle: "旅行、旅游 | 联合早报",
    seoDescription:
      "旅行、旅游 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于旅行、旅游的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_travel"], false)],
    size: 3,
  },
  lifestyle_columns: {
    key: "lifestyle_columns",
    label: "专栏",
    href: "/lifestyle/columns",
    seoTitle: "生活专栏 | 联合早报",
    seoDescription:
      "生活专栏 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的生活专栏。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_columns"], false)],
    size: 3,
  },
  lifestyle_culture: {
    key: "lifestyle_culture",
    label: "文化艺术",
    href: "/lifestyle/culture",
    seoTitle: "文化艺术 | 联合早报",
    seoDescription:
      "文化艺术 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于文化艺术与书评的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_culture"], false)],
    size: 3,
  },
  "lifestyle_history-heritage": {
    key: "lifestyle_history-heritage",
    label: "人文史地",
    href: "/lifestyle/history-heritage",
    seoTitle: "人文历史、文化遗产 | 联合早报",
    seoDescription:
      "人文历史、文化遗产 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于历史人文地理的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_history-heritage"], false)],
    size: 3,
  },
  lifestyle_motoring: {
    key: "lifestyle_motoring",
    label: "汽车",
    href: "/lifestyle/motoring",
    seoTitle: "汽车与电动车 | 联合早报",
    seoDescription:
      "汽车与电动车 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于汽车与电动车产业的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_motoring"], false)],
    size: 3,
  },
  lifestyle_gadget: {
    key: "lifestyle_gadget",
    label: "科玩",
    href: "/lifestyle/gadget",
    seoTitle: "科技科玩 | 联合早报",
    seoDescription:
      "科技科玩 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于科技与电子产品的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_gadget"], false)],
    size: 3,
  },
  lifestyle_familynlove: {
    key: "lifestyle_familynlove",
    label: "心事家事",
    href: "/lifestyle/familynlove",
    seoTitle: "家庭生活与育儿 | 联合早报",
    seoDescription:
      "家庭生活与育儿 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于家庭与育儿的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_familynlove"], false)],
    size: 3,
  },
  lifestyle_eco: {
    key: "lifestyle_eco",
    label: "生态与环保",
    href: "/lifestyle/eco",
    condition: [shouldWebCategory(["lifestyle_eco"], false)],
    size: 3,
  },
  "lifestyle_fashion-beauty": {
    key: "lifestyle_fashion-beauty",
    label: "时尚与美容",
    href: "/lifestyle/fashion-beauty",
    seoTitle: "时尚与美容 | 联合早报",
    seoDescription:
      "时尚与美容新闻 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于时尚与美容的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_fashion-beauty"], false)],
    size: 3,
  },
  "lifestyle_design-decor": {
    key: "lifestyle_design-decor",
    label: "设计与家居",
    href: "/lifestyle/design-decor",
    seoTitle: "室内设计与家居 | 联合早报",
    seoDescription:
      "室内设计与家居 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于室内设计与装修设计的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_design-decor"], false)],
    size: 3,
  },
  lifestyle_photography: {
    key: "lifestyle_photography",
    label: "光影",
    href: "/lifestyle/photography",
    condition: [shouldWebCategory(["lifestyle_photography"], false)],
    seoTitle: "光影 - 探索摄影与视觉艺术的奥秘 | 联合早报",
    seoDescription:
      "光影 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于摄影与视觉艺术的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    size: 3,
  },
  "lifestyle_tech-science": {
    key: "lifestyle_tech-science",
    label: "科普",
    href: "/lifestyle/tech-science",
    condition: [shouldWebCategory(["lifestyle_tech-science"], false)],
    seoTitle: "科普 - 拓宽知识视野，简易了解科学奥秘 | 联合早报",
    seoDescription:
      "科普 - 一站式获取最新科学知识。《联合早报》每天为您提供易于理解的科学解释和趣味科学事实，让科学对每个人都变得亲近和有趣，尽在zaobao.com.sg。",

    size: 3,
  },
  lifestyle_supplement: {
    key: "lifestyle_supplement",
    label: "特辑",
    href: "/lifestyle/supplement",
    seoTitle: "生活特辑 | 联合早报",
    seoDescription:
      "生活特辑 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的生活资讯。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_supplement"], false)],
    size: 3,
  },
  lifestyle_campus: {
    key: "lifestyle_campus",
    label: "早报校园",
    href: "/lifestyle/campus",
    seoTitle: "早报校园 | 联合早报",
    seoDescription:
      "早报校园 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于校园的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_campus"], false)],
    size: 3,
  },
  lifestyle_others: {
    key: "lifestyle_others",
    label: "精选",
    href: "/lifestyle/others",
    seoTitle: "精选 | 联合早报",
    seoDescription:
      "精选 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的生活资讯。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle"], false), mustHasImage()],
    size: 3,
  },
  // todo hidden layout
  lifestyle_horoscope: {
    key: "lifestyle_horoscope",
    label: "星座与生肖",
    href: "/horoscope",
    cancelLoadDataProcess: true,
    size: undefined,
  },
  // todo hidden layout
  ["specials_active-aging"]: {
    key: "specials_active-aging",
    label: "壮龄go!",
    label_en: "Active Aging",
    href: "/specials/active-aging",
    cancelLoadDataProcess: true,
    size: undefined,
  },
  lifestyle_health: {
    key: "lifestyle_health",
    label: "保健",
    href: "/lifestyle/health",
    seoTitle: "健康保健 | 联合早报",
    seoDescription:
      "健康保健 -《联合早报》是全球华文用户信任的媒体，每天为你提供关于健康与保健的资讯与报道。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [shouldWebCategory(["lifestyle_health"], false)],
    size: 3,
  },
  lifestyle_latenightreads: {
    key: "lifestyle_latenightreads",
    label: "深夜好读",
    href: "/lifestyle/latenightreads",
    condition: [
      shouldWebCategory(["lifestyle"], true),
      mustKeyword(["深夜好读"]),
    ],
    size: undefined,
  },

  videos: {
    key: "videos",
    label: "视频",
    href: "/videos",
    dataSourceConfig: [
      { type: "refByNavigation", key: "videos_news" },
      { type: "refByNavigation", key: "videos_entertainment" },
      { type: "refByNavigation", key: "videos_lifestyle" },
      { type: "refByNavigation", key: "video-series" },
      { type: "refByNavigation", key: "livestream" },
      {
        type: "refByNavigation",
        key: "lifestyle_campus",
        displaySettings: { isHiddenInNavigation: true },
        condition: [mustHasVideo(), mustNotKeyword(["系列节目"])],
      },
    ],
    condition: [mustHasVideo()],
    rss: {
      days: 7,
      condition: [mustHasVideo()],
    },
    size: 4,
  },
  videos_news: {
    key: "videos_news",
    label: "新闻",
    href: "/videos/news",
    condition: [
      shouldWebCategory(["news", "finance", "realtime"], true),
      mustNotWebCategory(["realtime_finance"]),
      mustNotKeyword(["新闻抢先看", "直播讲座", "现场直击", "系列节目"]),
      mustHasVideo(),
    ],
    size: 3,
  },
  videos_entertainment: {
    key: "videos_entertainment",
    label: "娱乐",
    href: "/videos/entertainment",
    condition: [
      shouldWebCategory(["entertainment"], true),
      mustNotKeyword(["系列节目"]),
      mustHasVideo(),
    ],
    size: 3,
  },
  videos_lifestyle: {
    key: "videos_lifestyle",
    label: "生活",
    href: "/videos/lifestyle",
    condition: [
      shouldWebCategory(["lifestyle"], true),
      mustNotWebCategory(["lifestyle_campus"]),
      mustNotKeyword(["系列节目"]),
      mustHasVideo(),
    ],
    size: 3,
  },
  "video-series": {
    key: "video-series",
    label: "系列节目",
    href: "/video-series",
    seoTitle: "视频系列节目 | 联合早报",
    seoDescription:
      "视频系列节目 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的热门新闻，包括视频。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    dataSourceEnvironment: {
      contentListEnvKey: "VITE_VIDEO_SERIES_CONTENT_LIST_ID",
      type: "config",
      settings: {
        type: "mustKeyword",
        level2Condition: [mustHasVideo(), mustKeyword(["系列节目"])],
        level1Condition: [],
        baseSeoTitle: "- 视频系列节目 | 联合早报",
        baseSeoDescription:
          "视频系列节目 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的系列视频。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",
      },
    },
    condition: [mustKeyword(["系列节目"]), mustHasVideo()],
    size: 3,
  },
  livestream: {
    key: "livestream",
    label: "直播",
    href: "/livestream",
    seoTitle: "在线直播 | 联合早报",
    seoDescription:
      "在线直播 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的热门新闻，包括直播。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    dataSourceEnvironment: {
      contentListEnvKey: "VITE_LIVESTREAM_CONTENT_LIST_ID",
      type: "tag",
      settings: {
        type: "shouldTagId",
        level1Condition: [mustHasVideo()],
        level2Condition: [],
        basePath: "/livestream",
        baseSeoTitle: "- 在线直播 | 联合早报",
        baseSeoDescription:
          "在线直播 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的热门播客。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",
      },
    },

    condition: [
      shouldKeyword(["新闻抢先看", "直播讲座", "现场直击"]),
      mustHasVideo(),
    ],
    size: 3,
  },

  podcast: {
    key: "podcast",
    label: "早报播客",
    href: "/podcast",
    size: 3,
    seoTitle: "早报播客 | 联合早报",
    seoDescription: `早报播客 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的热门播客。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。`,

    dataSourceEnvironment: {
      contentListEnvKey: "VITE_PODCAST_CONTENT_LIST_ID",
      type: "config",
      settings: {
        type: "mustKeyword",
        level2Condition: [mustHasPodCast()],
        level1Condition: [],
        baseSeoTitle: "- 早报播客 | 联合早报",
        baseSeoDescription:
          "早报播客 -《联合早报》是全球华文用户信任的媒体，每天为你提供新加坡国内外的热门新闻，包括播客。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",
      },
    },
  },
  "interactive-graphics": {
    key: "interactive-graphics",
    label: "互动新闻",
    href: "/interactive-graphics",
    seoTitle: "互动新闻 | 联合早报",
    seoDescription:
      "互动新闻 -《联合早报》是全球华文用户信任的媒体，每天为你提供最新的互动新闻。获取最新的健康、美食、旅行、艺术等信息，尽在zaobao.com.sg。",

    condition: [mustInteractiveNews(), mustKeyword(["互动新闻"])],
    size: 24,
  },

  special: {
    key: "special",
    label: "专题特稿",
    href: "/special",
    seoTitle: "专题特稿 | 早报",
    seoDescription:
      "专题特稿 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡国内外的特稿新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

    dataSourceEnvironment: {
      contentListEnvKey: "VITE_SPECIAL_CONTENT_LIST_ID",
      type: "tag",
      settings: {
        type: "shouldTagsUrlPath",
        level2Condition: [shouldTagType(["storythread"])],
        level1Condition: [],
        baseSeoTitle: "- 专题特稿 | 联合早报",
        baseSeoDescription:
          "专题特稿 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡国内外的特写和专题新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",
      },
    },
    rss: {
      days: 3,
      condition: [shouldTagType(["storythread"])],
    },
    size: undefined,
  },

  // specials: {
  //   key: "specials",
  //   label: "专题特稿",
  //   href: "/special",
  //   seoTitle: "专题特稿 | 早报",
  //   seoDescription:
  //     "专题特稿 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡国内外的特稿新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",

  //   // dataSourceEnvironment: {
  //   //   contentListEnvKey: "VITE_SPECIAL_CONTENT_LIST_ID",
  //   //   type: "tag",
  //   //   settings: {
  //   //     type: "mustTagsUrlPath",
  //   //     condition: [],
  //   //     baseSeoTitle: "- 专题特稿 | 联合早报",
  //   //     baseSeoDescription:
  //   //       "专题特稿 -《联合早报》是全球华文用户信任的媒体，每天即时为你提供新加坡国内外的特写和专题新闻。从财经、体育、生活娱乐资讯到评论分析，尽在zaobao.com.sg。",
  //   //   },
  //   // },
  //   size: undefined,
  // },
  today_recommend_article: {
    key: "today_recommend_article",
    label: "今日要闻",
    href: undefined,
    size: undefined,
  },
  homepage_campaign_carousel: {
    key: "homepage_campaign_carousel",
    label: "首页活动轮播",
    href: undefined,
    size: undefined,
  },
  homepage_special_topic: {
    key: "homepage_special_topic",
    label: "专题",
    href: undefined,
    size: undefined,
  },
} as const;
