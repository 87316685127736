import Slider, { Settings } from "@ant-design/react-slick";
import ArrowRight from "@assets/icons/arrow-next-white.svg?react";
import ArrowLeft from "@assets/icons/arrow-prev-white.svg?react";
import CloseIcon from "@assets/icons/article/close-icon.svg?react";
import ExpandIcon from "@assets/icons/article/expand-icon.svg?react";
import GallaryIcon from "@assets/icons/article-gallery-icon.svg?react";
import ClockIcon from "@assets/icons/clock.svg?react";
import FacebookLogo from "@assets/icons/facebook.svg?react";
import VotesIcon from "@assets/icons/votes.svg?react";
import ZBClubLogo from "@assets/logos/zbclub-gallary-logo.svg?react";
import Card from "@components/Card/Card";
import Link from "@components/Link/Link";
import Loading from "@components/Loading";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const CAROUSEL_COUNT = 8;

const contentUrl =
  "https://static.zaobao.com/s3fs-public/facebook-api/zb-club-facebook-page.json";

type FacebookDataType = {
  posts: {
    data: Post[];
  };
};
type Post = {
  id: string;
  full_picture?: string; // this is used to filter data with images and as main image
  message: string; // used for main text body
  permalink_url: string; // used for facebook icon link
  created_time: string; // "2024-10-25T10:44:47+0000"
  reactions: {
    summary: {
      total_count: number; // votes
    };
  };
  attachments?: {
    data: {
      subattachments: {
        data: {
          type: string;
          url: string;
          media: {
            image: {
              height: number;
              width: number;
              src: string; // this is used for thumbnail gallery
            };
          };
        }[];
      };
    }[];
  };
};
export default function FacebookWall() {
  // fetch json data, "No data available." if error
  const [posts, setPosts] = useState<Post[]>();
  // ssr with FontAwesomeIcon has flickering issue
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    setIsLoading(true);
    fetch(`${contentUrl}?v=${Date.now()}`)
      .then((response) => response.json())
      .then((jsonData: FacebookDataType) => {
        // we only need posts with images and hashtag
        setPosts(filterTopNPosts(jsonData.posts.data, CAROUSEL_COUNT));
      })
      .catch((error: Error) => {
        console.error("Error fetching facebook data:", error);
        setError("facebook data fetch failed");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // current active post index
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const handlePostClick = (index: number) => {
    setActiveIndex(index);
    setModalOpen(true);
  };

  const handlePostClose = () => {
    setActiveIndex(0);
    setModalOpen(false);
  };

  return (
    <section className="-mx-8">
      <Card
        heading={"早报俱乐部官方脸书网页"}
        headingLink={"https://www.facebook.com/ZaobaoClub"}
      >
        {isLoading ? (
          <Loading />
        ) : error ? (
          <div className="pt-[16px] text-header3 text-primary">
            No data available
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-[40px] py-8 lg:grid-cols-4">
            {posts
              ? posts.map((post, index) => (
                  <FacebookPostCard
                    index={index}
                    post={post}
                    key={post.id}
                    onClick={handlePostClick}
                  />
                ))
              : null}
          </div>
        )}
      </Card>

      {/* modal */}
      {posts && modalOpen ? (
        <FacebookCarouselModal
          initialSlide={activeIndex}
          posts={posts}
          handlePostClose={handlePostClose}
        />
      ) : null}
    </section>
  );
}

const sharedCss =
  "absolute top-[50%]  block h-auto w-auto cursor-pointer rounded-full !bg-button-secondary-default p-[8px] before:!content-none hover:!bg-button-secondary-hover";
function PreArrowButton(props: {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  currentSlide: number;
}) {
  const { className, onClick, currentSlide } = props;
  return (
    <button
      className={clsx(`${className}  -left-[60px] ${sharedCss}`, {
        hidden: currentSlide === 0,
      })}
      // style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    >
      <ArrowLeft className="h-[24px] w-[24px]" />
    </button>
  );
}

function NextArrowButton(props: {
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  currentSlide: number;
}) {
  const { className, onClick, currentSlide } = props;
  return (
    <button
      className={clsx(`${className}  -right-[60px]  ${sharedCss}`, {
        hidden: currentSlide === CAROUSEL_COUNT - 1,
      })}
      onClick={onClick}
    >
      <ArrowRight className="h-[24px] w-[24px]" />
    </button>
  );
}

const FacebookCarouselModal = ({
  initialSlide,
  posts,
  handlePostClose,
}: {
  initialSlide: number;
  posts: Post[];
  handlePostClose: () => void;
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialSlide);
  const sliderSettings: Settings = {
    lazyLoad: "progressive",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlide,
    dots: false,
    autoplay: false,
    infinite: false,
    //  seems more reliable than afterChange
    beforeChange(_, nextSlide) {
      setCurrentIndex(nextSlide);
    },

    prevArrow: <PreArrowButton currentSlide={currentIndex} />,
    nextArrow: <NextArrowButton currentSlide={currentIndex} />,
  };

  return (
    <>
      {/* header is z-index:50 */}
      <div className="fixed inset-0 z-[120]  bg-black">
        <div className="flex h-[50px] w-full items-center justify-end border-b-[#EAEAEA] lg:border-b-[1px] ">
          <CloseIcon
            className="mr-8 h-[30px] w-[30px] cursor-pointer rounded-full bg-button-secondary-default p-[5px] hover:bg-button-secondary-hover"
            onClick={handlePostClose}
          />
        </div>
        <div className="flex h-[calc(100%-50px)] items-center justify-center">
          <div className="relative h-full w-full max-w-[1280px]  rounded  bg-white lg:h-auto lg:w-[80%] landscape:h-auto landscape:w-[80%]">
            <Slider {...sliderSettings}>
              {posts.map((post) => (
                <GallaryCard post={post} key={post.id} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

function GallaryCard({ post }: { post: Post }) {
  const images = post.attachments
    ? post.attachments.data
        .map((it) => it.subattachments.data.map((it) => it.media.image.src))
        .flat()
    : [post.full_picture];

  const [mainImageSrc, setMainImageSrc] = useState(images[0]);
  const handleThumbnailClick = (imgSrc: string) => {
    setMainImageSrc(imgSrc);
  };

  return (
    <div className="h-[calc(100vh-50px)] justify-center  overflow-auto lg:grid lg:h-auto lg:grid-cols-2 landscape:grid landscape:h-auto landscape:grid-cols-2">
      <div className="relative aspect-square w-full flex-grow-0 lg:h-auto">
        <img
          src={mainImageSrc}
          className="absolute h-full w-full !rounded-none object-cover "
          alt="Main"
        />
        {images.length > 1 ? (
          <ThumbnailGallary
            onClick={handleThumbnailClick}
            images={images as string[]}
          />
        ) : null}
      </div>
      <div className="relative grid h-[calc(100vh-100vw-50px)] min-h-[200px] flex-auto grid-rows-[auto_1fr_auto] lg:aspect-square lg:h-auto landscape:aspect-square landscape:h-auto">
        <header className="flex justify-between px-8 py-6">
          <div className="flex items-center">
            <ZBClubLogo />
            <span className="pl-[8px] text-label2 text-secondary">
              早报俱乐部
            </span>
          </div>

          <Link to={post.permalink_url} className="flex items-center">
            <FacebookLogo />
          </Link>
        </header>
        <main className="overflow-y-auto px-8 text-body2 text-primary">
          {post.message}
        </main>
        <footer className=" flex w-full justify-between px-8 py-6">
          <div className="flex items-center">
            <ClockIcon />
            <span className="pl-[8px] text-label4 text-secondary">
              {dayjs(post.created_time).locale("en").format("DD MMMM YYYY")}
            </span>
          </div>
          <div className="flex items-center">
            <VotesIcon />
            <span className="pl-[8px] text-label4  text-secondary">
              {post.reactions.summary.total_count}
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
}

// TODO, these images are too big for thumbnails
function ThumbnailGallary({
  images,
  onClick,
}: {
  images: string[];
  onClick: (src: string) => void;
}) {
  const [activeImg, setActiveImg] = useState<string>(images[0]);

  return (
    <div className="absolute bottom-0 flex  w-full justify-center  overflow-x-auto bg-gradient-to-b from-black/0 via-black/80 to-black/80 py-[16px]">
      {images.map((image) => (
        <button
          key={image}
          onClick={() => {
            setActiveImg(image);
            onClick(image);
          }}
        >
          <img
            src={image}
            alt={""}
            style={{
              width: "40px",
              height: "auto",
              aspectRatio: "1 / 1",
              objectFit: "cover",
              borderRadius: "4px",
              cursor: "pointer",
              border: "2px transparent solid",
              borderColor: activeImg === image ? " white " : "transparent",
              padding: "2px",
              boxSizing: "border-box",
            }}
          />
        </button>
      ))}
    </div>
  );
}

function FacebookPostCard({
  post,
  onClick,
  index,
}: {
  index: number;
  post: Post;
  onClick: (index: number) => void;
}) {
  return (
    // eslint-disable-next-line
    <div
      className="relative cursor-pointer"
      onClick={() => {
        onClick(index);
      }}
    >
      <img
        src={
          post.attachments
            ? post.attachments.data[0]?.subattachments?.data[0].media.image.src
            : post.full_picture
        }
        alt={""}
        style={{
          width: "100%",
          height: "auto",
          aspectRatio: "1 / 1",
          objectFit: "cover",
          borderRadius: "4px",
        }}
      />
      <div
        className={`absolute right-0 top-0 m-2 flex h-[30px] items-center rounded-full bg-black/40 p-3 ${post.attachments ? "w-[56px]" : "w-[30px]"}`}
      >
        <ExpandIcon />
        {post.attachments ? <GallaryIcon className="ml-4" /> : null}
      </div>
    </div>
  );
}

function filterTopNPosts(posts: Post[], n: number) {
  let cnt = 0;
  const result: Post[] = [];
  const len = posts.length;
  for (let i = 0; i < len; i++) {
    if (posts[i].full_picture && posts[i].message.includes("#")) {
      result.push(posts[i]);
      cnt++;
    }
    if (cnt === n) {
      break;
    }
  }
  return result;
}
