import Button from "@components/Button/Button";
import { NewslettersType } from "@typings/Data";

function NewsletterReNew({
  sendNewsletter,
  scrollToTop,
  setHasSubscribed,
  setAddedNews,
  choice,
  previousChoice,
  setRemovedNews,
  list,
}: {
  isLoading: boolean;
  sendNewsletter: (fn: () => void) => void;
  scrollToTop: () => void;
  setHasSubscribed: React.Dispatch<React.SetStateAction<boolean>>;
  setAddedNews: React.Dispatch<React.SetStateAction<string[]>>;
  choice: string[];
  previousChoice: string[];
  setRemovedNews: React.Dispatch<React.SetStateAction<string[]>>;
  list: NewslettersType["list"];
}) {
  const getLabels = (source: string[], target: string[]) =>
    source
      .filter((item) => !target.includes(item))
      .map(
        (item) =>
          list.find((i: { oneTrustKey: string }) => i.oneTrustKey === item)
            ?.label || "",
      );

  const handleClick = () => {
    const removedItems = getLabels(previousChoice, choice);
    setAddedNews(getLabels(choice, previousChoice));

    if (!removedItems.length) {
      sendNewsletter(() => {
        setHasSubscribed(true);
      });
      return;
    }
    setRemovedNews(removedItems);
    scrollToTop();
  };

  return (
    <section className="layout-container mb-[40px] box-border px-[20px] py-[16px]">
      <Button size="large" onClick={handleClick}>
        更新
      </Button>
    </section>
  );
}
export default NewsletterReNew;
