import { type Condition } from "@api/backend/caas-mappings";
import {
  type BackendNavigation,
  type NavigationSourceOption,
  type NavRefOption,
} from "@api/core/navigation-settings.helper";
import { defaultBackendNavigationDictionary } from "@api/navigation-settings";

import { siteMenu } from "../gen/site-menu";

export type NavigationProps = {
  key: string;
  href: string | undefined;
  label: string;
  label_en: string;
  target: string;
  children: Array<NavigationChildren>;
  displaySettings: DisplaySettings;
  podcast: boolean;
};

export type NavigationItem = Required<
  Pick<NavigationProps, "key" | "label" | "href">
> &
  Partial<Exclude<NavigationProps, "key" | "label" | "href">>;

export type NavigationChildren = Required<Pick<NavigationProps, "key">> &
  Partial<Exclude<NavigationProps, "key">>;

export type DisplaySettings = {
  cardDisplayMode?:
    | "darkCard"
    | "lightCard"
    | "singleCarouselArticles"
    | "ImmediateArticleList"
    | "TimestampArticleCard";
  isHiddenInNavigation?: boolean;
  isHiddenInLayout?: boolean;
  hasMainArticle?: boolean;
};

export const dataLayoutRecord: Record<string, NavigationItem> = {
  today_recommend_article: {
    key: "today_recommend_article",
    label: "今日要闻",
    href: undefined,
  },
  homepage_campaign_carousel: {
    key: "homepage_campaign_carousel",
    label: "首页活动轮播",
    href: undefined,
  },
  homepage_special_topic: {
    key: "homepage_special_topic",
    label: "专题",
    href: undefined,
  },
};

export const siteNavigationLink: Record<string, NavigationItem> = {
  // ToDo publication
  // page title and page description should be replaced with real text

  "publication_xin-ming-ri-bao": {
    key: "publication_xin-ming-ri-bao",
    label: "新明日报",
    href: "/publication/xin-ming-ri-bao",
  },
  "publication_zao-bao-xiao-yuan": {
    key: "publication_zao-bao-xiao-yuan",
    label: "早报校园",
    href: "/publication/zao-bao-xiao-yuan",
  },
  "publication_lian-he-zao-bao": {
    key: "publication_lian-he-zao-bao",
    label: "联合早报",
    href: "/publication/lian-he-zao-bao",
  },
  zbclub: {
    key: "zbclub",
    label: "早报俱乐部",
    href: "/zbclub",
  },
  epapers: {
    key: "epapers",
    label: "电子报",
    href: "/epapers",
    label_en: "Epapers",
  },

  site_global: {
    key: "site_global",
    label: "国际版",
    href: "https://www.zaobao.com.sg/global",
  },
  site_cn: {
    key: "site_cn",
    label: "中国版",
    href: "https://www.zaobao.com.sg/cn",
  },
  site_sg: {
    key: "site_sg",
    label: "新加坡版",
    href: "https://www.zaobao.com.sg/",
  },
};

export const newsletterNavigation: Record<string, NavigationItem> = {
  "newsletter-signup": {
    key: "newsletter-signup",
    label: "订阅新闻速递",
    href: "/newsletter-signup",
    label_en: "Newsletters Signup",
  },
  "newsletter-unsubscribe": {
    key: "newsletter-unsubscribe",
    label: "新闻速递电邮管理",
    href: "/newsletter-unsubscribe",
    label_en: "Newsletters Unsubscribe",
  },
};

export const navigationDictionary: Record<string, NavigationItem> = {
  ...dataLayoutRecord,
  ...siteNavigationLink,
  ...newsletterNavigation,
  home: {
    ...buildNavigationItem("home"),
    label_en: "Home",
  },

  // Navigation NEWS
  news: {
    ...buildNavigationItem("news"),
    label_en: "News",
  },
  news_singapore: {
    key: "news_singapore",
    label: "新加坡",
    href: "/news/singapore",
  },
  news_world: {
    key: "news_world",
    label: "国际",
    href: "/news/world",
  },
  news_china: {
    key: "news_china",
    label: "中国",
    href: "/news/china",
  },
  news_sea: {
    key: "news_sea",
    label: "东南亚",
    href: "/news/sea",
  },
  news_sports: {
    key: "news_sports",
    label: "体育",
    href: "/news/sports",
  },

  // Navigation REALTIME
  realtime: {
    ...buildNavigationItem("realtime"),
    label_en: "Realtime",
  },
  realtime_singapore: {
    key: "realtime_singapore",
    label: "新加坡",
    label_en: "Singapore",
    href: "/realtime/singapore",
  },
  realtime_china: {
    key: "realtime_china",
    label: "中国",
    label_en: "China",
    href: "/realtime/china",
  },
  realtime_world: {
    key: "realtime_world",
    label: "国际",
    label_en: "World",
    href: "/realtime/world",
  },
  realtime_finance: {
    key: "realtime_finance",
    label: "财经即时",
    label_en: "Finance",
    href: "/realtime/finance",
  },

  // Navigation FINANCE
  finance: {
    ...buildNavigationItem("finance"),
    label_en: "Finance",
  },
  finance_stock: {
    key: "finance_stock",
    label: "新加坡股市",
    label_en: "Singapore Stock",
    href: "https://stock.zaobao.com.sg/",
  },
  finance_singapore: {
    key: "finance_singapore",
    label: "新加坡财经",
    label_en: "Singapore Finance",
    href: "/finance/singapore",
  },
  finance_world: {
    key: "finance_world",
    label: "全球财经",
    label_en: "World Finance",
    href: "/finance/world",
  },
  finance_china: {
    key: "finance_china",
    label: "中国财经",
    label_en: "China Finance",
    href: "/finance/china",
  },
  finance_invest: {
    key: "finance_invest",
    label: "投资理财",
    label_en: "Finance Invest",
    href: "/finance/invest",
  },
  "finance_real-estate": {
    key: "finance_real-estate",
    label: "房产",
    label_en: "Real Estate",
    href: "/finance/real-estate",
  },
  finance_usstock: {
    key: "finance_usstock",
    label: "美国股市",
    label_en: "US Stock",
    href: "/finance/usstock",
  },
  finance_sme: {
    key: "finance_sme",
    label: "中小企业",
    label_en: "SME",
    href: "/finance/sme",
  },
  finance_startup: {
    key: "finance_startup",
    label: "起步创新",
    label_en: "Finance Startup",
    href: "/finance/startup",
  },
  finance_personalities: {
    key: "finance_personalities",
    label: "财经人物",
    label_en: "Finance Personalities",
    href: "/finance/personalities",
  },

  // Navigation FORUM
  forum: {
    ...buildNavigationItem("forum"),
    label_en: "Forum",
  },
  forum_editorial: {
    key: "forum_editorial",
    label: "社论",
    label_en: "Editorial",
    href: "/forum/editorial",
  },
  forum_views: {
    key: "forum_views",
    label: "评论",
    label_en: "Views",
    href: "/forum/views",
  },
  forum_talk: {
    key: "forum_talk",
    label: "交流站",
    label_en: "Talk",
    href: "/forum/talk",
  },
  forum_comic: {
    key: "forum_comic",
    label: "漫画",
    label_en: "Comic",
    href: "/forum/comic",
  },
  forum_zaodian: {
    key: "forum_zaodian",
    label: "中国早点",
    label_en: "Zaodian",
    href: "/forum/zaodian",
  },
  forum_expert: {
    key: "forum_expert",
    label: "名家专评",
    label_en: "Expert",
    href: "/forum/expert",
  },

  // Navigation ENTERTAINMENT
  entertainment: {
    ...buildNavigationItem("entertainment"),
    label_en: "Entertainment",
  },
  entertainment_has_video: {
    key: "entertainment_has_video",
    label: "精选视频",
    label_en: "Entertainment Video",
    href: undefined,
  },
  entertainment_celebs: {
    key: "entertainment_celebs",
    label: "明星",
    label_en: "Celebs",
    href: "/entertainment/celebs",
  },
  "entertainment_movies-and-tv": {
    key: "entertainment_movies-and-tv",
    label: "影视",
    label_en: "Movies & TV",
    href: "/entertainment/movies-and-tv",
  },
  entertainment_music: {
    key: "entertainment_music",
    label: "音乐",
    label_en: "Music",
    href: "/entertainment/music",
  },
  "entertainment_k-pop": {
    key: "entertainment_k-pop",
    label: "韩流",
    label_en: "K-pop",
    href: "/entertainment/k-pop",
  },
  entertainment_giveaway: {
    key: "entertainment_giveaway",
    label: "送礼",
    label_en: "Giveaway",
    href: "/entertainment/giveaway",
  },
  entertainment_news: {
    key: "entertainment_news",
    label: "星闻",
    label_en: "News",
    href: "/entertainment/news",
  },

  // Navigation LIFESTYLE
  lifestyle: {
    ...buildNavigationItem("lifestyle"),
    label_en: "Lifestyle",
  },
  lifestyle_has_video: {
    key: "lifestyle_has_video",
    label: "精选视频",
    href: undefined,
  },
  lifestyle_trending: {
    key: "lifestyle_trending",
    label: "热门",
    label_en: "Trending",
    href: "/lifestyle/trending",
  },
  lifestyle_food: {
    key: "lifestyle_food",
    label: "美食",
    label_en: "Food",
    href: "/lifestyle/food",
  },
  lifestyle_feature: {
    key: "lifestyle_feature",
    label: "特写",
    label_en: "Feature",
    href: "/lifestyle/feature",
  },
  lifestyle_gen: {
    key: "lifestyle_gen",
    label: "@世代",
    label_en: "Gen",
    href: "/lifestyle/gen",
  },
  "lifestyle_life-hacks": {
    key: "lifestyle_life-hacks",
    label: "生活贴士",
    label_en: "Life Hacks",
    href: "/lifestyle/life-hacks",
  },
  lifestyle_travel: {
    key: "lifestyle_travel",
    label: "旅行",
    label_en: "Travel",
    href: "/lifestyle/travel",
  },
  lifestyle_columns: {
    key: "lifestyle_columns",
    label: "专栏",
    label_en: "Columns",
    href: "/lifestyle/columns",
  },
  lifestyle_culture: {
    key: "lifestyle_culture",
    label: "文化艺术",
    label_en: "Culture",
    href: "/lifestyle/culture",
  },
  "lifestyle_history-heritage": {
    key: "lifestyle_history-heritage",
    label: "人文史地",
    label_en: "History & Heritage",
    href: "/lifestyle/history-heritage",
  },
  lifestyle_motoring: {
    key: "lifestyle_motoring",
    label: "汽车",
    label_en: "Motoring",
    href: "/lifestyle/motoring",
  },
  lifestyle_gadget: {
    key: "lifestyle_gadget",
    label: "科玩",
    label_en: "Gadget",
    href: "/lifestyle/gadget",
  },
  lifestyle_familynlove: {
    key: "lifestyle_familynlove",
    label: "心事家事",
    label_en: "Family & Love",
    href: "/lifestyle/familynlove",
  },
  "lifestyle_fashion-beauty": {
    key: "lifestyle_fashion-beauty",
    label: "时尚与美容",
    label_en: "Fashion & Beauty",
    href: "/lifestyle/fashion-beauty",
  },
  "lifestyle_design-decor": {
    key: "lifestyle_design-decor",
    label: "设计与家居",
    label_en: "Design & Decor",
    href: "/lifestyle/design-decor",
  },
  lifestyle_supplement: {
    key: "lifestyle_supplement",
    label: "特辑",
    label_en: "Supplement",
    href: "/lifestyle/supplement",
  },
  lifestyle_campus: {
    key: "lifestyle_campus",
    label: "早报校园",
    label_en: "Campus",
    href: "/lifestyle/campus",
  },
  lifestyle_others: {
    key: "lifestyle_others",
    label: "精选",
    label_en: "Others",
    href: "/lifestyle/others",
  },
  lifestyle_photography: {
    key: "lifestyle_photography",
    label: "光影",
    label_en: "Photography",
    href: "/lifestyle/photography",
  },
  "lifestyle_tech-science": {
    key: "lifestyle_tech-science",
    label: "科普",
    label_en: "Tech & Science",
    href: "/lifestyle/tech-science",
  },
  lifestyle_eco: {
    key: "lifestyle_eco",
    label: "生态与环保",
    label_en: "Eco",
    href: "/lifestyle/eco",
  },
  lifestyle_horoscope: {
    key: "lifestyle_horoscope",
    label: "星座与生肖",
    label_en: "Horoscope",
    href: "/horoscope",
    displaySettings: {}, // todo hidden layout
  },
  ["specials_active-aging"]: {
    key: "specials_active-aging",
    label: "壮龄go!",
    label_en: "Active Aging",
    href: "/specials/active-aging",
  },
  lifestyle_health: {
    key: "lifestyle_health",
    label: "保健",
    label_en: "Health",
    href: "/lifestyle/health",
  },
  lifestyle_latenightreads: {
    key: "lifestyle_latenightreads",
    label: "深夜好读",
    label_en: "Late Night Reads",
    href: "/lifestyle/latenightreads",
  },

  // Navigation VIDEOS
  videos: {
    ...buildNavigationItem("videos"),
    label_en: "Videos",
  },
  videos_news: {
    key: "videos_news",
    label: "新闻",
    label_en: "News",
    href: "/videos/news",
  },
  videos_entertainment: {
    key: "videos_entertainment",
    label: "娱乐",
    label_en: "Entertainment",
    href: "/videos/entertainment",
  },
  videos_lifestyle: {
    key: "videos_lifestyle",
    label: "生活",
    label_en: "Lifestyle",
    href: "/videos/lifestyle",
  },

  // Navigation INTERACTIVE GRAPHICS
  "interactive-graphics": {
    key: "interactive-graphics",
    label: "互动新闻",
    label_en: "Interactive Graphics",
    href: "/interactive-graphics",
  },

  // Navigation PODCAST
  podcast: {
    key: "podcast",
    label: "早报播客",
    label_en: "Podcast",
    href: "/podcast",
  },

  // Navigation SPECIAL
  special: {
    key: "special",
    label: "专题",
    label_en: "Special",
    href: "/special",
  },

  // Navigation LIVESTREAM
  livestream: {
    ...buildNavigationItem("livestream"),
    label_en: "Livestream",
  },

  // Navigation VIDEO SERIES
  "video-series": {
    ...buildNavigationItem("video-series"),
    label_en: "Video Series",
  },
} as const;

type NavigationLink = {
  key: string;
  label: string;
  label_en?: string;
  href: string;
  target?: string;
};

const contactUs: Record<string, NavigationLink> = {
  about_us: {
    key: "about_us",
    label: "关于我们",
    label_en: "About Us",
    href: "/about-us",
  },
  faq: {
    key: "faq",
    label: "常见问题",
    label_en: "FAQ",
    href: "/faq",
  },
  contact_us: {
    key: "contact_us",
    label: "联络我们",
    label_en: "Contact Us",
    href: "/contact-us",
  },
  products: {
    key: "products",
    label: "关注我们",
    label_en: "Products",
    href: "/products",
  },
  advertise: {
    key: "advertise",
    label: "广告联络",
    label_en: "Advertise",
    href: "/advertise",
  },
  contribute: {
    key: "contribute",
    label: "投函/投稿",
    label_en: "Contribute",
    href: "/contribute",
  },
  join_us: {
    key: "join_us",
    label: "加入我们",
    label_en: "Join Us",
    href: "https://www.sph.com.sg/careers-and-scholarships",
    target: "_blank",
  },
};

export const footerContactItems = [
  contactUs.about_us,
  contactUs.faq,
  contactUs.products,
  contactUs.contact_us,
  contactUs.advertise,
  contactUs.contribute,
  contactUs.join_us,
];

export const ampFooterContactItems = [
  contactUs.about_us,
  contactUs.contact_us,
  contactUs.products,
  contactUs.advertise,
  contactUs.contribute,
];

export const headerNavigation: NavigationItem[] = siteMenu;

export const headerExtraNavigation: NavigationItem[] = [
  navigationDictionary["publication_xin-ming-ri-bao"],
  navigationDictionary.zbclub,
  navigationDictionary.epapers,
];

export function buildNavigationItem(key: string): NavigationItem {
  function extractChildrenFromDataSource(
    dataSourceList: Array<NavigationSourceOption> | undefined,
  ): NavigationChildren[] {
    return (
      dataSourceList?.map((it) => {
        switch (it.type) {
          case "storeQueue": {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            const { type, envKeyWithQueueId, ...other } = it;
            return other;
          }
          case "drupal": {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            const { type, options, ...other } = it;
            return other;
          }
          case "refByNavigation": {
            const {
              type,
              mainArticle,
              dataSourceConfig,
              condition,
              seoDescription,
              ...other
            } = it;
            return {
              ...other,
              children: extractChildrenFromDataSource(dataSourceConfig),
            };
          }
        }
      }) || []
    );
    // .filter((it) => !!it);
  }

  const { dataSourceConfig, condition, ...otherProp } =
    buildRichNavigationSetting(key);

  return {
    ...otherProp,
    children: extractChildrenFromDataSource(dataSourceConfig),
  };
}

export function buildRichNavigationSetting(
  key: string,
  otherCondition?: Array<Condition>,
): BackendNavigation {
  const fullBackendNavigation = defaultBackendNavigationDictionary;
  const level1Nav = fullBackendNavigation[key];

  // backendLog.warn(JSON.stringify(level1Nav.dataSourceConfig));
  // 富华 DataSource 数据源属性.
  const dataSourceList = [...(level1Nav.dataSourceConfig || [])].map(
    (config) => {
      const navSetting = fullBackendNavigation[key];

      // console.log(config.key, JSON.stringify(config));
      switch (config.type) {
        case "drupal":
          return { ...navSetting, ...config };
        case "storeQueue":
          return { ...navSetting, ...config };
        case "refByNavigation":
          // 尽可能富华所有属性
          // backendLog.trace(config);
          return {
            ...navSetting,
            ...config,
            condition: [
              ...(navSetting.condition || []),
              ...(config.condition || []),
              ...(otherCondition || []), // 其他的条件
            ],
            dataSourceConfig:
              (config.dataSourceConfig ?? navSetting.dataSourceConfig)?.map(
                ({ key, ...other }) => {
                  const chSource = buildRichNavigationSetting(key);
                  return {
                    ...chSource,
                    ...other,
                  };
                },
              ) || [],
          } as NavRefOption;
      }
    },
  );

  return { ...level1Nav, dataSourceConfig: dataSourceList };
}
