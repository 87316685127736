import useGtmEvent from "@hooks/useGtmEvent";
import { ComponentType } from "react";

type GtmTrackingProps = {
  customEvent: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
};

export interface WithGtmTrackingProps {
  gtmTrackingProps: GtmTrackingProps;
  onClick?: () => void;
}

export const WithGtmTracking = <P extends WithGtmTrackingProps>(
  WrappedComponent: ComponentType<P>,
) => {
  return function WithGtmTrackingComponent(props: P) {
    const sendGtmData = useGtmEvent();

    const handleClick = () => {
      const {
        gtmTrackingProps: {
          customEvent,
          eventCategory,
          eventAction,
          eventLabel,
        },
      } = props;
      sendGtmData(customEvent, eventCategory, eventAction, eventLabel);
      props.onClick?.();
    };

    return <WrappedComponent {...props} onClick={handleClick} />;
  };
};
