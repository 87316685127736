import LogoMarkIcon from "@assets/icons/logo-mark.svg";
import SearchIcon from "@assets/icons/search-gray.svg?react";
import Button from "@components/Button/Button";
import Dropdown from "@components/Dropdown/Dropdown";
import Link from "@components/Link/Link";
import Modal from "@components/Modal/Modal";
import Popover from "@components/Popover/Popover";
import { headerNavigation, type NavigationItem } from "@constants/navigation";
import useSticky from "@hooks/useSticky";
import globalState from "@stores/global";
import userState, { OKTAUserTypeEnum } from "@stores/user";
import viewportState from "@stores/viewport";
import clsx from "clsx";
import { type ReactElement, useEffect, useState } from "react";
import { useSnapshot } from "valtio";

import ExtraNavigationItems from "./ExtraNavigationItems";
import HeaderLogo from "./HeaderLogo";
import LanguageSwitch from "./LanguageSwitch";
import LoggedInPopover from "./LoggedInPopover";
import NonLoggedInPopover from "./NonLoggedInPopover";
import SearchInput from "./SearchInput";
import SubscriberButton from "./SubscriberButton";
import WebsiteDropdown from "./WebsiteDropdown";

export type HeaderProps = {
  rootClassName?: string;
};

export default function Header({
  rootClassName = "",
}: HeaderProps): ReactElement {
  const { isMobileView } = useSnapshot(viewportState);
  const { user } = useSnapshot(userState);
  const { isHeaderStuck } = useSnapshot(globalState);
  const [isSearchBoxVisible, setIsSearchBoxVisible] = useState<boolean>(false);
  const { refPassthrough, sticky } = useSticky({
    threshold: 0.01,
  });

  const isAnonymousUser = !user?.display_name;
  const isRegisteredUser =
    !!user?.display_name && user.usertype === OKTAUserTypeEnum.REGISTETERED;
  const isSubscriber =
    !!user?.display_name && user.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  useEffect(() => {
    globalState.isHeaderStuck = sticky;
  }, [sticky]);

  return (
    <>
      <div
        id="header"
        aria-label="header"
        className={clsx("bg-white", rootClassName)}
      >
        <div className="layout-container py-[15px]">
          <div className="row justify-between px-[15px]">
            <HeaderLogo />

            <div className="flex-col">
              <div className="flex justify-end" aria-label="language website">
                <LanguageSwitch />
                <WebsiteDropdown />
              </div>

              <div className="flex flex-row items-center space-x-[30px] pt-[10px]">
                <ExtraNavigationItems />

                {!isSubscriber ? (
                  <Button
                    rootClassName="!px-[16px]"
                    label="订阅"
                    onClick={() => {
                      window.open(
                        "https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&utm_medium=sph-publication&utm_source=zb&utm_content=subscribebutton-header",
                      );
                    }}
                  />
                ) : null}

                <Popover
                  placement="bottomRight"
                  rootContentClassName={clsx("!top-[unset]", {
                    hidden: isHeaderStuck,
                    "-bottom-[461px]": isAnonymousUser,
                    "-bottom-[550px]": isRegisteredUser,
                    "-bottom-[487px]": isSubscriber,
                  })}
                  content={
                    isAnonymousUser ? (
                      <NonLoggedInPopover />
                    ) : (
                      <LoggedInPopover />
                    )
                  }
                >
                  <SubscriberButton hasLabel={!!isAnonymousUser} />
                </Popover>
              </div>
            </div>
          </div>
          {/* <hr className="layout-container mt-[15px]" /> */}
        </div>
        <div className="layout-container">
          <div className="row">
            <hr className="layout-container mx-8" />
          </div>
        </div>
        <div id="sticky_header_ref" ref={refPassthrough}></div>
        <div
          className={clsx(
            "z-[100] flex w-full flex-row items-center justify-center",
            {
              "fixed left-0 top-0 bg-brand-primary": isHeaderStuck,
            },
          )}
        >
          <div
            id="js-header-desktop"
            data-testid="js-header-desktop"
            className={clsx("layout-container", {
              "h-[44px] bg-transparent": isHeaderStuck,
            })}
          >
            <div className="row mx-0 h-full justify-between border-b border-b-control-brand-primary-default">
              <div className="flex flex-row">
                {isHeaderStuck ? (
                  <Link
                    className="topnav-link logo mr-4 flex items-center"
                    to={"/"}
                    target="_self"
                  >
                    <img
                      width="28"
                      height="28"
                      src={LogoMarkIcon}
                      alt="zaobao logo mark"
                    />
                  </Link>
                ) : null}

                <StickyNavigationMenu
                  rootClassName={clsx({
                    hidden: !isHeaderStuck,
                  })}
                />

                <MainNavigationMenu
                  rootClassName={clsx({
                    hidden: isHeaderStuck,
                  })}
                />
              </div>

              <div className="flex flex-row space-x-[28px]">
                {/* search icon */}
                <button
                  id="js-topnavbar-search"
                  data-testid="js-topnavbar-search"
                  className="flex items-center"
                  onClick={() => {
                    setIsSearchBoxVisible(true);
                  }}
                >
                  <span
                    className={clsx(
                      "h-[20px] w-[21px] text-icon-secondary-default",
                      {
                        "brightness-0 invert": isHeaderStuck,
                      },
                    )}
                  >
                    <SearchIcon />
                  </span>
                </button>

                {/* header stuck subscribe button */}
                <div
                  className={clsx(
                    "border-control-default-primary hover:border-control-brand-primary group relative flex flex-row items-center px-[10px] py-[6px] text-navigation-primary text-white",
                    {
                      hidden: !isHeaderStuck || isSubscriber,
                    },
                  )}
                >
                  <Link
                    aria-label="订阅"
                    to="https://subscribe.sph.com.sg/zh/publications-zb/?utm_campaign=zb_subscription&utm_medium=sph-publication&utm_source=zb&utm_content=subscribebutton-header"
                    className="tracking-[2px] hover:text-inherit"
                    target={isMobileView ? "_self" : "_blank"}
                  >
                    订阅
                  </Link>
                </div>

                {/* {isHeaderStuck ? (
                  <SubscriberButton type="white" hasLabel={false} />
                ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        onClose={() => {
          setIsSearchBoxVisible(false);
        }}
        isVisible={isSearchBoxVisible}
      >
        <SearchInput
          onSubmit={() => {
            setIsSearchBoxVisible(false);
          }}
        />
      </Modal>
    </>
  );
}

// function HeaderWrapper({ children }: { children: ReactElement }) {
//   const [shouldRenderHeaderTag, setShouldRenderHeaderTag] = useState(true);

//   useEffect(() => {
//     if (document.querySelector('#adzAdDivWrapper')) {
//       setShouldRenderHeaderTag(false);
// }
//   }, [])

//   return shouldRenderHeaderTag ? <header>{ children}</header> : <div id="header" aria-label="header">{ children}</div>;
// }

function MainNavigationMenu({
  rootClassName,
}: {
  rootClassName?: string;
}): ReactElement {
  return (
    <nav className={clsx("flex", rootClassName)}>
      {headerNavigation
        .filter((it) => it.key !== "home")
        .map((navigationItem: NavigationItem) => (
          <Dropdown
            key={navigationItem.key}
            rootClassName="px-[10px] py-[9px] text-navigation-primary text-secondary"
            menuItem={navigationItem}
          />
        ))}
    </nav>
  );
}

function StickyNavigationMenu({
  rootClassName,
}: {
  rootClassName?: string;
}): ReactElement {
  return (
    <nav className={clsx("flex", rootClassName)}>
      {headerNavigation
        .filter((it) => it.key !== "home")
        .map((navigationItem: NavigationItem) => (
          <Dropdown
            key={navigationItem.key}
            rootClassName="px-[10px] py-[6px] text-navigation-primary text-white"
            rootChildrenClassName=""
            menuItem={navigationItem}
          />
        ))}
    </nav>
  );
}
