/* eslint-disable jsx-a11y/click-events-have-key-events*/
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import CloseIcon from "@assets/icons/gift-close.svg";
import GiftGray from "@assets/icons/gift-gray.svg";
import GiftSuccess from "@assets/icons/gift-success.svg";
import Button from "@components/Button/Button";
import SocialLink from "@components/GiftModal/SocialLink";
import { BFF_BASE_URL, BFF_ENDPOINT } from "@constants/bff";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGtmEvent from "@hooks/useGtmEvent";
import { useViewportSize } from "@hooks/useViewportSize";
// import { GIFT_ARTICLE_SHARE_LIMIT } from "@constants/gift";
import globalState from "@stores/global";
import userState, { OKTAUserTypeEnum } from "@stores/user";
import axios from "axios";
// import Cookie from "js-cookie";
import clsx from "clsx";
import React, {
  type ReactElement,
  type ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";

import {
  ANONY_USER,
  LOGIN_USER,
  MODAL_TITLE,
  SUB_HREF,
  SUBSCIBE_USER,
} from "./const";
import ReadMore from "./ReadMore";

export type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
};
const GiftModal = React.forwardRef(
  ({ isVisible, onClose }: ModalProps, ref) => {
    const { user } = useSnapshot(userState);

    // Need to retain for local develop
    // const usertype = Cookie.get("mySPHUserType");
    // const loginid = Cookie.get("mysphw");

    const { search, pathname }: { search: string; pathname: string } =
      useLocation();
    // const { search, pathname: } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [giftToken, setGiftToken] = useState<string>("");
    const [isResize, setResize] = useState<boolean>(false);
    const { isClientSideMounted } = useSnapshot(globalState);
    const [isExpired, setExpired] = useState<boolean>(false);
    const [isTokenValid, setTokenValid] = useState<boolean>(true);
    const [currentCount, setCurrentCount] = useState<number>(5);
    const [previousCount, setPreviousCount] = useState<number>(0);
    const [isCounted, setCounted] = useState<boolean>(false);
    const [isNewGiftUrl, setNewGiftUrl] = useState<boolean>(false);
    const [isCopyToClipboard, setCopyToClipboard] = useState<boolean>(false);
    const [giftShareUrl, setGiftShareUrl] = useState<string>("");
    const [isBtnLoading, setBtnLoading] = useState<boolean>(false);
    const [isModalLoading, setModalLoading] = useState<boolean>(false);
    const sendGtmData = useGtmEvent();

    const { width, height } = useViewportSize();

    const isLogin = user?.loginid ? true : false;
    const isSubscriberedUser =
      user?.loginid && user.usertype === OKTAUserTypeEnum.SUBSCRIBER;
    // const isLogin = loginid ? true : false;
    // const isSubscriberedUser = loginid && usertype == "y-sub";

    const [isSafari, setIsSafari] = useState(false);
    useEffect(() => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent,
      );
      setIsSafari(isSafari);
    }, [isVisible]);

    useEffect(() => {
      if (isClientSideMounted) {
        // console.log(1111);
        const handleResize = () => {
          // console.log("c185", isResize);
          setResize(true);
          setTimeout(() => {
            setResize(false);
          }, 1000);
        };
        handleResize();
        // window.addEventListener("resize", handleResize);
        // return () => {
        //   window.removeEventListener("resize", handleResize);
        // };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height]);

    useEffect(() => {
      const _token = searchParams.get("gift");
      // console.log(78, isSubscriberedUser); //for y-anoy user, this value always be undefined
      if (
        _token &&
        (isSubscriberedUser === false || isSubscriberedUser === undefined)
      ) {
        setGiftToken(_token);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenMySPHLightbox = () => {
      sendGtmData("custom_event", "gift article", "click", "login");
      if (window._mySPHObj !== undefined) {
        window._mySPHObj.openLogin();
      }
    };
    const handleSubscribe = (): void => {
      window.open(SUB_HREF, "_blank");
    };
    const checkGift = async (giftToken: string) => {
      const data = {
        articleUrl: `${BFF_ENDPOINT}${pathname}`,
        token: giftToken,
      };
      try {
        const res = await axios.post(`${BFF_BASE_URL}/gift/view`, data, {
          withCredentials: true,
        });
        if (res.data.code !== 200) {
          setTokenValid(false);
        }
        if (res.data.isExpired) {
          setExpired(true);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.data;
      } catch (error) {
        console.log({ error });
      }
    };
    const getGiftCount = async () => {
      // console.log("gift counted");
      const data = {
        articleUrl: `${BFF_ENDPOINT}${pathname}`,
      };
      const GIFT_ARTICLE_SHARE_LIMIT = 5;
      try {
        const res = await axios.post(`${BFF_BASE_URL}/gift/count`, data);
        if (res.data && (res.data.giftCount > 0 || res.data.giftCount === 0)) {
          setPreviousCount(GIFT_ARTICLE_SHARE_LIMIT - currentCount);
          setCurrentCount(
            Math.max(0, GIFT_ARTICLE_SHARE_LIMIT - res.data.giftCount),
          );
          setCounted(true);
          setExpired(res.data.isExpired as boolean);
        }
        if (res.data.giftUrl === "") {
          setNewGiftUrl(true);
        } else {
          setNewGiftUrl(false);
        }
        return Math.max(0, GIFT_ARTICLE_SHARE_LIMIT - res.data.giftCount);
        // subscriber don't need verify that whether article is expired
      } catch (error) {
        console.log("Get gift count error", error);
      }
    };
    const handleGive = async (): Promise<string | undefined> => {
      try {
        const data = {
          articleUrl: `${BFF_ENDPOINT}${location.pathname}`,
        };
        const res = await axios.post(`${BFF_BASE_URL}/gift/gen`, data);

        if (res.data) {
          const urlWithoutQuotes: string = (res.data.data as string).replace(
            /(^")|("$)/g,
            "",
          );
          // console.log({ urlWithoutQuotes });
          setGiftShareUrl(urlWithoutQuotes);
          return urlWithoutQuotes;
        }
      } catch (error) {
        console.error("Error on generate gift url", error);
      }
    };

    function copyToClipboardFallback() {
      const textArea = document.createElement("textarea");
      textArea.value = giftShareUrl;
      textArea.style.position = "fixed"; // Avoid scrolling to bottom
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        setIsSafari(false);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    }

    const handleCopyToClipboard = async (fromSocial: boolean = false) => {
      if (fromSocial) {
        setModalLoading(true);
      } else {
        setBtnLoading(true);
      }
      const url = await handleGive();
      setBtnLoading(false);
      setModalLoading(false);
      if (url) {
        if (!isSafari) {
          navigator.clipboard
            .writeText(url)
            .then(async () => {
              setCopyToClipboard(true);
              await getGiftCount();
            })
            .catch((error) => {
              console.error("Error copying to clipboard: ", error);
            });
        } else {
          setGiftShareUrl(url);
          setCopyToClipboard(true);
          await getGiftCount();
        }
        return url;
      }
    };

    const getTitle = (): string => {
      if (giftToken && !isSubscriberedUser) {
        return MODAL_TITLE.MAIN_TITLE_WITH_TOKEN;
      } else {
        return MODAL_TITLE.MAIN_TITLE;
      }
    };
    const getMainText = (): React.ReactNode => {
      if (!isLogin) {
        if (giftToken) {
          if (isExpired || !isTokenValid) {
            return LOGIN_USER.MAIN_TXT_WITH_EXPIRED;
          } else {
            return LOGIN_USER.MAIN_TXT_WITH_TOKEN;
          }
        }
        return ANONY_USER.MAIN_TXT;
      }

      if (!isSubscriberedUser) {
        if (giftToken) {
          if (isExpired || !isTokenValid) {
            return LOGIN_USER.MAIN_TXT_WITH_EXPIRED;
          }
          return LOGIN_USER.MAIN_TXT_WITH_TOKEN;
        } else {
          const login_user_main_txt = LOGIN_USER.MAIN_TXT.split("，");
          return (
            <>
              <div>{login_user_main_txt[0]}，</div>
              <div className="mt-2">
                {login_user_main_txt[1]}，{login_user_main_txt[2]}
              </div>
            </>
          );
        }
      }
      // for subscriber
      if (!isCounted) {
        return <div>{SUBSCIBE_USER.MAIN_TXT}</div>;
      }

      if (currentCount === 0 && isNewGiftUrl) {
        return <>这个月您还可赠阅 {currentCount} 篇订户专享文章。</>;
      }
      if (!isNewGiftUrl) {
        //no new gift url genarated,
        if (isExpired) {
          return (
            <>
              这个月您还可赠阅 {currentCount} 篇订户专享文章。
              <div className="mt-4">{SUBSCIBE_USER.MAIN_TXT_HAS_EXPIRED}</div>
            </>
          );
        } else {
          return (
            <>
              这个月您还可赠阅 {currentCount} 篇订户专享文章。
              <div className="mt-4">{SUBSCIBE_USER.MAIN_TXT_NO_CHANGE}</div>
            </>
          );
        }
      } else {
        if (isExpired) {
          return (
            <>
              这个月您还可赠阅 {currentCount} 篇订户专享文章。
              <div className="mt-4">{SUBSCIBE_USER.MAIN_TXT_HAS_EXPIRED}</div>
            </>
          );
        }
        return (
          <>
            这个月您还可赠阅 {currentCount} 篇订户专享文章。
            <div className="mt-4">{SUBSCIBE_USER.MAIN_TXT}</div>
          </>
        );
      }
    };
    const getDescription = () => {
      if (!isLogin) {
        if (giftToken) {
          return <>{ANONY_USER.DESCRIPTION_WITH_TOKEN}</>;
        } else {
          return <>{ANONY_USER.DESCRIPTION}</>;
        }
      }

      if (!isSubscriberedUser) {
        if (giftToken) {
          return <>{ANONY_USER.DESCRIPTION_WITH_TOKEN}</>;
        } else {
          return <>{LOGIN_USER.DESCRIPTION}</>;
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (isSubscriberedUser) {
        if (isCopyToClipboard && currentCount + previousCount === 5) {
          return <>{SUBSCIBE_USER.DESCRIPTION_COUNT_NO_CHANGE}</>;
        }

        if (isCopyToClipboard) {
          return null;
        }
        if (!isNewGiftUrl) {
          if (isExpired) {
            return <>{SUBSCIBE_USER.DESCRIPTION}</>;
          } else {
            return <>{SUBSCIBE_USER.DESCRIPTION_COUNT_NO_CHANGE}</>;
          }
        }
        if (currentCount <= 0) {
          return <>{SUBSCIBE_USER.DESCRIPTION_NO_REMAINING}</>;
        }
        return <>{SUBSCIBE_USER.DESCRIPTION}</>;
      }

      if (giftToken) {
        return <>{LOGIN_USER.DESCRIPTION}</>;
      }

      return <>{LOGIN_USER.DESCRIPTION}</>;
    };

    const getBtns = (): ReactElement | null => {
      if (!isLogin) {
        if (giftToken) {
          if (isExpired || !isTokenValid) {
            return (
              <Button
                onClick={() => {
                  handleSubscribe();
                  sendGtmData(
                    "custom_event",
                    "gift article",
                    "click",
                    "read partial subscribe",
                  );
                }}
              >
                立即订阅
              </Button>
            );
          } else {
            return (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                  sendGtmData(
                    "custom_event",
                    "gift article",
                    "click",
                    "start read",
                  );
                }}
              >
                开始阅读
              </Button>
            );
          }
        } else {
          return (
            <div className="flex h-[36px] w-[206px] justify-between  text-button1">
              <Button
                size="login"
                type="tertiary"
                onClick={handleOpenMySPHLightbox}
              >
                登录
              </Button>
              <Button
                size="login"
                onClick={() => {
                  handleSubscribe();
                  sendGtmData(
                    "custom_event",
                    "gift article",
                    "click",
                    "subscribe",
                  );
                }}
              >
                立即订阅
              </Button>
            </div>
          );
        }
      }

      if (!isSubscriberedUser && giftToken) {
        if (isExpired || !isTokenValid) {
          return (
            <Button
              onClick={() => {
                handleSubscribe();
                sendGtmData(
                  "custom_event",
                  "gift article",
                  "click",
                  "read partial subscribe",
                );
              }}
            >
              立即订阅
            </Button>
          );
        }
        return (
          <Button
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            开始阅读
          </Button>
        );
      }

      if (isSubscriberedUser) {
        if (currentCount <= 0 && isNewGiftUrl) {
          return null;
        }
        return (
          <Button
            isLoading={isBtnLoading}
            onClick={() => {
              void handleCopyToClipboard();
              sendGtmData(
                "custom_event",
                "gift article",
                "click",
                `copy link::${currentCount}`,
              );
            }}
          >
            赠阅文章
          </Button>
        );
      }

      return (
        <Button
          onClick={() => {
            handleSubscribe();
            sendGtmData(
              "custom_event",
              "gift article",
              "click",
              "read partial subscribe",
            );
          }}
        >
          立即订阅
        </Button>
      );
    };
    const PreLoader: () => ReactNode = () => {
      return (
        <div
          className={clsx(
            "absolute left-1/2 top-1/2 z-50 mx-auto flex h-[28px]  w-[50px] -translate-x-1/2 -translate-y-1/2 justify-center pb-[16px] lg:h-[26px]",
          )}
        >
          <FontAwesomeIcon
            color="#464646"
            icon={faSpinner}
            spin={true}
            size={"3x"}
            className={"mx-auto"}
          />
        </div>
      );
    };

    const [prevIsVisible, setPrevIsVisible] = useState(isVisible);
    useEffect(() => {
      // need reset two status when gift modal was reopen
      // prevIsVisible is status to check weather this is from open to close
      if (prevIsVisible && !isVisible) {
        setTimeout(() => {
          setGiftToken("");
          setCounted(false);
        }, 500);
      }
      setPrevIsVisible(isVisible);
    }, [isVisible, prevIsVisible]);

    useEffect(() => {
      // console.log(406, giftToken);
    }, [giftToken]);

    useEffect(() => {
      setTimeout(() => {
        setCopyToClipboard(false);
      }, 600);
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
      getGiftCount,
      checkGift,
    }));

    return (
      <>
        {isClientSideMounted
          ? ReactDOM.createPortal(
              <div
                className={`fixed transform ${isVisible ? "translate-y-0" : "translate-y-full"} ${isResize ? "hidden" : ""}  bottom-0 z-50 h-auto w-full bg-tertiary  text-center shadow-[rgba(0,0,0,0.15)_2px_-15px_10px_-10px] transition-transform  duration-1000 ease-in-out`}
              >
                <img
                  src={CloseIcon}
                  className="absolute right-10 top-6 z-40 cursor-pointer"
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      setGiftToken("");
                      setNewGiftUrl(false);
                      setGiftShareUrl("");
                      setExpired(false);
                    }, 1000);
                    sendGtmData(
                      "custom_event",
                      "gift article",
                      "remove",
                      "gift article",
                    );
                  }}
                  alt="Close gift modal"
                />
                {isModalLoading ? <PreLoader /> : null}
                {isCopyToClipboard ? (
                  <>
                    <div className="flex justify-center py-[16px] text-header4  font-medium text-primary">
                      {isSafari ? null : (
                        <img
                          src={GiftSuccess}
                          className="mr-[8px] h-[24px] w-[24px] pt-[6px]"
                          alt="Copy to clipboard"
                        />
                      )}
                      {isSafari ? "请点击复制链接" : "链接复制成功"}
                    </div>
                    <div className="text-label6 text-secondary">
                      {!isSafari && <>您现在可以发送给您的亲友。</>}
                      {isSafari && currentCount + previousCount !== 5 && (
                        <>链接生成成功, 请点击复制按钮分享文章。</>
                      )}
                      <div className="pb-[16px]">
                        这个月您还可赠阅 {currentCount} 篇订户专享文章。
                      </div>
                      {isSafari && (
                        <div className="flex justify-center pb-[16px]">
                          <Button onClick={copyToClipboardFallback}>
                            复制链接
                          </Button>
                        </div>
                      )}
                      {!isSafari && (
                        <SocialLink
                          handleGive={handleCopyToClipboard}
                          giftShareUrl={giftShareUrl}
                        />
                      )}
                    </div>
                    {currentCount + previousCount === 5 && (
                      <div className="h-auto w-full border-t-[1px] border-solid border-[#EBEBEB] px-[20px] py-[16px] text-label4  text-[#6f6f6f]">
                        {getDescription()}
                        <ReadMore />
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className={`border-b-1 mx-auto  h-auto w-full border-solid border-[#EAEAEA] pt-[16px] ${isModalLoading ? "opacity-50" : ""}`}
                  >
                    <div className="flex items-center justify-center text-header4 font-medium text-primary">
                      <img
                        src={GiftGray}
                        className="mr-2 h-[20px] w-[20px]  "
                        alt="Gift subscribe icon"
                      />
                      {getTitle()}
                    </div>
                    <div
                      className={clsx(
                        "mx-auto px-[20px] pt-[16px] text-label6 text-secondary",
                        {
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          "pb-[16px]": currentCount === 0 && isNewGiftUrl,
                        },
                      )}
                    >
                      {getMainText()}
                    </div>

                    <div
                      className={clsx("flex justify-center", {
                        "pb-[16px] pt-[16px]":
                          currentCount !== 0 || !isNewGiftUrl,
                      })}
                    >
                      {getBtns()}
                    </div>
                    {(currentCount > 0 ||
                      (currentCount === 0 && !isNewGiftUrl)) &&
                      isSubscriberedUser && (
                        <>
                          {!isSafari && (
                            <div className="mx-auto xs:w-[70%] md:w-[20%]">
                              <SocialLink
                                handleGive={() => handleCopyToClipboard(true)}
                                giftShareUrl={giftShareUrl}
                              />
                            </div>
                          )}
                        </>
                      )}
                    <div className="h-auto w-full border-t-[1px] border-solid border-[#EBEBEB] px-[20px] py-[16px]  text-label4  text-[#6f6f6f]">
                      {getDescription()}
                      <ReadMore />
                    </div>
                  </div>
                )}
              </div>,
              document.body,
            )
          : null}
      </>
    );
  },
);
GiftModal.displayName = "GiftModal";
export default GiftModal;
