import ExpandIcon from "@assets/icons/article/expand-icon.svg";
import GalleryIcon from "@assets/icons/article/gallery-icon.svg";
// import PlayIconRound from "@assets/icons/icon-play-solid-round-corners.svg";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import useGtmEvent from "@hooks/useGtmEvent";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import globalState from "@stores/global";
import viewportState from "@stores/viewport";
import { ArticleDetailContextType } from "@typings/Data";
import { isValidArray } from "@utils/article";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";

import ImageGalleryModal, {
  IImageGalleryModal,
} from "../components/ImageGalleryModal";

interface IArticleMedia {
  className?: string;
}

export default function ArticleMedia({ className = "" }: IArticleMedia) {
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  const { isClientSideMounted } = useSnapshot(globalState);
  const { isDesktopView } = useSnapshot(viewportState);
  const [modal, setModal] = useState<IImageGalleryModal>({
    open: false,
    activeIndex: 0,
    images: article?.images || [],
  });

  const showVideo = isValidArray(article?.videos) && isClientSideMounted;
  const showImage =
    (article?.videos?.length || 0) <= 0 &&
    isValidArray(article?.images) &&
    isClientSideMounted;
  const firstImage = article?.images?.[0];

  const [scrollDistance, setScrollDistance] = useState(999);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const distance = containerRef.current.getBoundingClientRect().top;
        setScrollDistance(distance);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const zIndexClass = scrollDistance < 48 ? "z-[50]" : "z-[44]";
  const sendGtmData = useGtmEvent();

  return (
    <div
      ref={containerRef}
      className={clsx(
        className,
        !isDesktopView && showVideo && "sticky top-[45px]",
        !isDesktopView && showVideo && zIndexClass,
      )}
    >
      {showVideo && (
        <div className={clsx("brightcove-container rounded-none")}>
          <ReactPlayerLoader
            accountId="4802324430001"
            videoId={article?.videos?.[0]?.id}
            // poster={article?.videos?.[0]?.poster}
            options={{ autoplay: false, playsinline: true }}
            attrs={{ className: "brightcove-video-main articlePage" }}
          />
        </div>
      )}

      {/* main images */}
      {showImage && firstImage && (
        <>
          <div className="relative flex flex-col ">
            <button
              className=" w-full"
              onClick={(e) => {
                // SEND GTM EVENT ON ARTICLE IMAGE CLICK
                sendGtmData(
                  "custom_event",
                  "photo",
                  "click",
                  article.headline ? article.headline : "",
                );
                e.stopPropagation();
                setModal({ ...modal, open: true });
              }}
            >
              <img
                title={firstImage.caption || ""}
                alt={firstImage.caption || ""}
                src={`${firstImage.url}?o=zbimg&oloc=se`}
                className="mx-auto my-0 w-full rounded-[4px] object-contain"
              />
            </button>
            {firstImage.caption ? (
              <div className="mt-[16px] text-center text-secondary">
                {firstImage.caption}
              </div>
            ) : null}

            <button
              className=" absolute right-[8px] top-[8px] max-h-max cursor-pointer"
              onClick={(e) => {
                // SEND GTM EVENT ON ARTICLE IMAGE ICON CLICK
                sendGtmData(
                  "custom_event",
                  "photo",
                  "click",
                  article.headline ? article.headline : "",
                );
                e.stopPropagation();
                setModal({ ...modal, open: true });
              }}
            >
              <div className="flex items-center gap-[8px] rounded-full bg-overlay-secondary p-[4px] ">
                <img
                  src={ExpandIcon}
                  alt=""
                  className="my-0 h-[22px] w-[22px]"
                />
                {(article.images?.length || 0) > 1 && (
                  <img
                    src={GalleryIcon}
                    alt=""
                    className="my-0 h-[22px] w-[22px]"
                  />
                )}
              </div>
            </button>
          </div>

          <ImageGalleryModal
            {...modal}
            showWatermark
            onClose={() => {
              setModal({ ...modal, open: false });
            }}
          />
        </>
      )}
    </div>
  );
}
