import Checkbox from "@assets/icons/checkbox.svg";
import Button from "@components/Button/Button";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGtmEvent from "@hooks/useGtmEvent";
import clsx from "clsx";
import { useState } from "react";

import { REASON_DATA } from "../constants/newsletter";

function NewsletterReason({
  setResultShow,
  sendNewsletter,
  isLoading,
  removedNews,
  initData,
}: {
  setResultShow: React.Dispatch<React.SetStateAction<boolean>>;
  sendNewsletter: (fn: () => void) => void;
  isLoading: boolean;
  removedNews: string[];
  initData: () => void;
}) {
  const [reason, setReason] = useState(REASON_DATA);
  const [tipsShow, setTipsShow] = useState(false);

  const sendGtmData = useGtmEvent();

  const sendReasonGa = () => {
    const eventsActions = removedNews.join(",");
    const eventsLabel = reason
      .filter((item) => item.checked)
      .map((item) => item.label)
      .join(",");
    sendGtmData("custom_event", "newsletter unsub", eventsActions, eventsLabel);
  };

  const handleUpdate = () => {
    const checknum = reason.every((item) => {
      return !item.checked;
    });
    if (checknum) {
      setTipsShow(true);
      return;
    }
    //通过校验
    sendNewsletter(() => {
      setResultShow(true);
    });
    sendReasonGa();
  };

  const clear = () => {
    initData();
    setReason(REASON_DATA);
    setTipsShow(false);
  };

  const loadingView = (
    <div
      className={clsx("absolute inset-0 flex items-center justify-center", {
        "bg-white/50": isLoading,
      })}
    >
      <FontAwesomeIcon
        color="#6f6f6f"
        icon={faSpinner}
        spin={true}
        size={"2x"}
        className={"mx-auto"}
      />
    </div>
  );

  return (
    <div className="layout-container">
      <section className="py-[16px] text-header2 text-primary">
        请告诉我们您退订的原因。
      </section>
      {tipsShow && (
        <p className="box-border py-[8px] pr-[20px] text-label5 text-alert">
          请至少选择一个选项
        </p>
      )}
      <section className="mb-[26px] flex flex-wrap">
        {reason.map((item, index) => {
          return (
            <section
              key={index}
              className="flex w-full items-center py-[16px] text-header3 text-link-headline-default lg:w-1/3"
            >
              <button
                onClick={() => {
                  setReason(
                    reason.map((item) => {
                      if (item.index === index) {
                        return {
                          ...item,
                          checked: !item.checked,
                        };
                      }
                      return item;
                    }),
                  );
                }}
                className="mr-[8px] flex h-[24px] w-[24px] cursor-pointer items-center justify-center"
              >
                {item.checked ? (
                  <img
                    src={Checkbox}
                    className="h-[24px] w-[24px]"
                    alt=""
                  ></img>
                ) : (
                  <div className="h-[18px] w-[18px] rounded-[2px] border-[2px] border-[#464646]"></div>
                )}
              </button>
              {item.label}
            </section>
          );
        })}
      </section>
      <section className="mb-[40px] flex gap-[8px] py-[16px]">
        <Button
          onClick={clear}
          size="large"
          type="subscriber"
          rootClassName="!hover:bg-[--button-secondary-hover] !bg-[--button-secondary-default]"
        >
          取消
        </Button>
        <Button
          onClick={handleUpdate}
          size="large"
          clickable={!isLoading}
          rootClassName="!overflow-hidden"
        >
          {isLoading ? loadingView : null}
          更新
        </Button>
      </section>
    </div>
  );
}

export default NewsletterReason;
