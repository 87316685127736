import "./style.css";

import userState, { OKTAUserTypeEnum } from "@stores/user";
import viewportState from "@stores/viewport";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";

import blackArrowClose from "./assets/arrow-down-black.svg";
import whiteArrowClose from "./assets/arrow-down-white.svg";
import blackArrowOpen from "./assets/arrow-up-black.svg";
import BlackLogo from "./assets/black-logo.svg?react";
import bookmarkSVG from "./assets/bookmark.svg";
import bookmarkDisableSVG from "./assets/bookmark-disable.svg";
import closeSVG from "./assets/close.svg";
import customSVG from "./assets/customize.svg";
import customDisableSVG from "./assets/customize-disable.svg";
import freeSVG from "./assets/free.svg";
import giftSVG from "./assets/gift.svg";
import giftDisableSVG from "./assets/gift-disable.svg";
import lockSVG from "./assets/lock.svg";
import lockDisableSVG from "./assets/lock-disable.svg";
import mailSVG from "./assets/mail.svg";
import mailDisableSVG from "./assets/mail-disable.svg";
import readSVG from "./assets/read.svg";
import readDisableSVG from "./assets/read-disable.svg";
import vipSVG from "./assets/vip.svg";
import vipDisableSVG from "./assets/vip-disable.svg";
import WhiteLogo from "./assets/white-logo.svg?react";
import TrackedLoginButton from "./components/LoginButton";
import RulesPopup from "./components/RulesPopup";
import TrackedSignupButton from "./components/SignupButton";
import { gtmCommonProps, LG_SCREEN_BREAK_POINT } from "./constants";

enum TAB_AND_USER_TYPE {
  ANONYMOUS = "anonymous",
  REGISTERED = "registered",
  SUBSCRIBED = "subscribed",
}

type Benefit = {
  key: number;
  iconPath: string;
  disableIconPath?: string;
  iconAlt?: string;
  textLines: string[];
  availableGroups: TAB_AND_USER_TYPE[];
};

const tabs: {
  tabIndex: TAB_AND_USER_TYPE;
  tabLabel: string;
}[] = [
  {
    tabIndex: TAB_AND_USER_TYPE.SUBSCRIBED,
    tabLabel: "订户",
  },
  {
    tabIndex: TAB_AND_USER_TYPE.REGISTERED,
    tabLabel: "注册用户",
  },
  {
    tabIndex: TAB_AND_USER_TYPE.ANONYMOUS,
    tabLabel: "访客",
  },
];
const benefitList: Array<Benefit> = [
  {
    key: 1,
    iconPath: vipSVG,
    disableIconPath: vipDisableSVG,
    textLines: ["早报VIP会员", "专享福利"],
    availableGroups: [TAB_AND_USER_TYPE.SUBSCRIBED],
  },
  {
    key: 2,
    iconPath: lockSVG,
    disableIconPath: lockDisableSVG,
    textLines: ["解锁早报订户", "专享内容*"],
    availableGroups: [TAB_AND_USER_TYPE.SUBSCRIBED],
  },
  {
    key: 3,
    iconPath: readSVG,
    disableIconPath: readDisableSVG,
    textLines: ["阅读长达两周", "的电子报*"],
    availableGroups: [TAB_AND_USER_TYPE.SUBSCRIBED],
  },
  {
    key: 4,
    iconPath: giftSVG,
    disableIconPath: giftDisableSVG,
    textLines: ["早报会员", "专享福利"],
    availableGroups: [
      TAB_AND_USER_TYPE.SUBSCRIBED,
      TAB_AND_USER_TYPE.REGISTERED,
    ],
  },
  {
    key: 5,
    iconPath: bookmarkSVG,
    disableIconPath: bookmarkDisableSVG,
    textLines: ["一键收藏", "(只限应用程序)"],
    availableGroups: [
      TAB_AND_USER_TYPE.SUBSCRIBED,
      TAB_AND_USER_TYPE.REGISTERED,
    ],
  },
  {
    key: 6,
    iconPath: customSVG,
    disableIconPath: customDisableSVG,
    textLines: ["自设内容栏目", "(只限应用程序)"],
    availableGroups: [
      TAB_AND_USER_TYPE.SUBSCRIBED,
      TAB_AND_USER_TYPE.REGISTERED,
    ],
  },
  {
    key: 7,
    iconPath: mailSVG,
    disableIconPath: mailDisableSVG,
    textLines: ["精选新闻", "电邮速递"],
    availableGroups: [
      TAB_AND_USER_TYPE.SUBSCRIBED,
      TAB_AND_USER_TYPE.REGISTERED,
    ],
  },
  {
    key: 8,
    iconPath: freeSVG,
    textLines: ["阅读免费", "文章"],
    availableGroups: [
      TAB_AND_USER_TYPE.SUBSCRIBED,
      TAB_AND_USER_TYPE.REGISTERED,
      TAB_AND_USER_TYPE.ANONYMOUS,
    ],
  },
];

function BenefitCard({
  benefit,
  currentTab,
  className,
}: {
  benefit: Benefit;
  currentTab: TAB_AND_USER_TYPE | undefined;
  className?: string;
}) {
  const active = benefit.availableGroups.includes(
    currentTab as TAB_AND_USER_TYPE,
  );
  return (
    <div className={`flex flex-col items-center ${className || ""}`}>
      <div className="flex items-center justify-center rounded-full bg-secondary p-2">
        <img
          className="h-[24px] w-[24px]"
          src={active ? benefit.iconPath : benefit.disableIconPath}
          alt={`VIP Benefits ${benefit.key}`}
        />
      </div>
      <div className="flex-col items-center">
        {benefit.textLines.map((line) => (
          <p
            className={clsx("text-center text-label2 text-primary", {
              "text-primary opacity-40": !active,
            })}
            key={line}
          >
            {line}
          </p>
        ))}
      </div>
    </div>
  );
}

function TabButton({
  currentTab,
  tabIndex,
  onClick,
  tabLabel,
  benefitShow,
}: {
  currentTab: TAB_AND_USER_TYPE | undefined;
  tabIndex: TAB_AND_USER_TYPE;
  tabLabel: string;
  onClick: (clickedTab: TAB_AND_USER_TYPE) => void;
  benefitShow: boolean;
}) {
  const [arrow, setArrow] = useState(blackArrowClose);
  useEffect(() => {
    // If it's the subscribed tab, use white arrow unless it's active and benefits are shown
    if (tabIndex === TAB_AND_USER_TYPE.SUBSCRIBED) {
      setArrow(
        currentTab === tabIndex && benefitShow
          ? blackArrowOpen
          : whiteArrowClose,
      );
      return;
    }
    // For other tabs, use black arrow (open if active and benefits shown, closed otherwise)
    setArrow(
      currentTab === tabIndex && benefitShow ? blackArrowOpen : blackArrowClose,
    );
  }, [currentTab, tabIndex, benefitShow]);
  return (
    <button
      className={clsx(
        "tab flex w-full justify-center text-label2 lg:w-[145px]",
        {
          "tab-active": currentTab === tabIndex && benefitShow,
          "tab-subscriber": tabIndex === TAB_AND_USER_TYPE.SUBSCRIBED,
          "tab-subscriber-active":
            currentTab === tabIndex &&
            benefitShow &&
            tabIndex === TAB_AND_USER_TYPE.SUBSCRIBED,
        },
      )}
      onClick={() => {
        onClick(tabIndex);
      }}
    >
      <span>{tabLabel}</span>
      <img src={arrow} alt="Tab Arrow" className="lg:hidden" />
    </button>
  );
}

export default function MembershipCard() {
  const { user } = useSnapshot(userState);
  // test
  // const user = { loginid: 111, usertype: "" };
  const { isInAppBrowser } = useSnapshot(viewportState);
  const [userType, setUserType] = useState<TAB_AND_USER_TYPE>(
    TAB_AND_USER_TYPE.ANONYMOUS,
  );
  // TODO, remove debug log before go live
  console.log("======user", user);
  useEffect(() => {
    if (user?.loginid) {
      if (String(user.usertype) === String(OKTAUserTypeEnum.SUBSCRIBER)) {
        setUserType(TAB_AND_USER_TYPE.SUBSCRIBED);
      } else if (
        String(user.usertype) === String(OKTAUserTypeEnum.REGISTETERED)
      ) {
        setUserType(TAB_AND_USER_TYPE.REGISTERED);
      }
    }
  }, [user]);

  // tabs
  const [tab, setTab] = useState<TAB_AND_USER_TYPE | undefined>(
    TAB_AND_USER_TYPE.SUBSCRIBED,
  );
  const handleTabClick = (tab: TAB_AND_USER_TYPE) => {
    setTab(tab);
    setBenefitShow(true);
  };

  // popup
  const [showPop, setShowPop] = useState(false);
  const [popupAnchor, setPopupAnchor] = useState<HTMLElement | null>(null);
  const handleRulesClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setPopupAnchor(event.currentTarget);
    setShowPop(!showPop);
  };

  // mobile view benefits close
  const [benefitShow, setBenefitShow] = useState(true);
  const handleBenefitsCloseClick = () => {
    setBenefitShow(false);
    // this is to set bg-img to sub one
    setTab(TAB_AND_USER_TYPE.SUBSCRIBED);
  };

  // Add media query listener, tricky part
  useEffect(() => {
    const mediaQuery = window.matchMedia(
      `(min-width: ${LG_SCREEN_BREAK_POINT}px)`,
    );

    // Set initial benefit show state based on screen size
    setBenefitShow(mediaQuery.matches);
    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      // Show benefits by default on desktop
      setBenefitShow(event.matches);
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <div
      className={clsx(
        "w-full rounded-[4px] shadow-[0_0_10px_0_rgba(56,56,56,0.15)]",
        {
          "header-bg-sub": tab === TAB_AND_USER_TYPE.SUBSCRIBED,
          "header-bg-reg": tab === TAB_AND_USER_TYPE.REGISTERED,
          "header-bg-anoy": tab === TAB_AND_USER_TYPE.ANONYMOUS,
        },
      )}
    >
      {/* Header */}
      <div className={"rounded-t-[4px] py-[12px] pb-0"}>
        {/* logo */}
        <div className="px-[20px] pt-2">
          {tab === TAB_AND_USER_TYPE.SUBSCRIBED ? (
            <>
              <WhiteLogo className="m-auto block lg:m-0" />
            </>
          ) : (
            <>
              <BlackLogo className="m-auto block lg:m-0" />
            </>
          )}
        </div>
        <div className="flex flex-col justify-between  lg:flex-row">
          <div className="flex items-center justify-center px-[20px]">
            <p
              className={clsx("pt-1 text-center text-label2 font-normal", {
                "text-primary]": tab !== TAB_AND_USER_TYPE.SUBSCRIBED,
                "text-white": tab === TAB_AND_USER_TYPE.SUBSCRIBED,
              })}
            >
              乐享精彩内容、商家优惠、好礼赠送与早报活动。
            </p>
          </div>
          {/* Tabs */}
          <div className="flex justify-evenly px-[2px] pt-4 lg:px-[20px]">
            {tabs.map((tabx) => (
              <TabButton
                key={tabx.tabIndex}
                tabLabel={tabx.tabLabel}
                tabIndex={tabx.tabIndex}
                currentTab={tab}
                onClick={handleTabClick}
                benefitShow={benefitShow}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Feature Grid */}
      <div
        className={clsx(
          "relative rounded-t-[4px] bg-white px-[20px] py-[16px] lg:block",
          {
            hidden: !benefitShow,
          },
        )}
      >
        <button
          onClick={handleBenefitsCloseClick}
          className={clsx(
            "absolute right-4 top-4 cursor-pointer lg:invisible",
            {
              hidden: !benefitShow,
            },
          )}
        >
          <img className="w-[26px]" src={closeSVG} alt="Close Icon"></img>
        </button>
        <div
          className={clsx(
            "grid auto-rows-fr grid-cols-6  gap-y-[8px] bg-white lg:flex lg:flex-nowrap lg:justify-evenly",
          )}
        >
          {benefitList.map((benefit) => (
            <BenefitCard
              key={benefit.key}
              benefit={benefit}
              currentTab={tab}
              className="col-span-2 last:col-span-3 [&:nth-last-child(2)]:col-span-3"
            />
          ))}
        </div>
        {/* rules */}
        <div
          className={clsx(
            "pb-[16px] pt-[8px] text-center text-label4 text-secondary",
          )}
        >
          *在
          <button
            className="cursor-pointer text-brand-secondary underline"
            onClick={handleRulesClick}
          >
            这里
          </button>
          了解更多条件与条款
        </div>

        {userType !== TAB_AND_USER_TYPE.SUBSCRIBED && (
          <div className="flex flex-col justify-center border-t bg-white pt-[16px] lg:flex-row">
            <div className="flex flex-col items-center justify-center pb-[12px] lg:pb-0">
              {userType === TAB_AND_USER_TYPE.REGISTERED ? (
                [
                  "您好，欢迎登录早报会员！升级成为早报VIP会员，可享更多福利！",
                  "马上订阅早报，解锁更多精彩内容和功能",
                ].map((line) => (
                  <p className="text-body2 text-primary" key={line}>
                    {line}
                  </p>
                ))
              ) : tab === TAB_AND_USER_TYPE.SUBSCRIBED ? (
                [
                  "升级成为早报VIP会员，可享更多福利！",
                  "马上订阅早报，解锁更多精彩内容和功能",
                ].map((line) => (
                  <p key={line} className="text-body2 text-primary">
                    {line}
                  </p>
                ))
              ) : (
                <p className="text-body2 text-primary">
                  解锁成为早报会员，享受更多会员权益
                </p>
              )}
            </div>
            {/* mobile app can also open this page, but no buttons */}
            {isInAppBrowser ? null : (
              <div className="flex items-center justify-center ">
                {userType === TAB_AND_USER_TYPE.ANONYMOUS ? (
                  tab === TAB_AND_USER_TYPE.SUBSCRIBED ? (
                    <TrackedLoginButton
                      gtmTrackingProps={{
                        ...gtmCommonProps,
                        eventLabel: "vip login",
                      }}
                    />
                  ) : tab === TAB_AND_USER_TYPE.REGISTERED ? (
                    <div className="grid grid-cols-2 gap-y-[2rem] lg:flex">
                      <TrackedLoginButton
                        gtmTrackingProps={{
                          ...gtmCommonProps,
                          eventLabel: "registered login",
                        }}
                      />
                      <TrackedSignupButton
                        gtmTrackingProps={{
                          ...gtmCommonProps,
                          eventLabel: "registered sign up",
                        }}
                      />
                    </div>
                  ) : (
                    <TrackedSignupButton
                      gtmTrackingProps={{
                        ...gtmCommonProps,
                        eventLabel: "anonymous sign up",
                      }}
                    />
                  )
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
      {showPop && (
        <RulesPopup
          anchor={popupAnchor}
          onClose={() => {
            setShowPop(false);
          }}
        />
      )}
    </div>
  );
}
