import { navigationDictionary } from "@constants/navigation";
import axios from "axios";
import { type ReactNode } from "react";

import Level1WebCategory from "./Level1WebCategory";
import Level2WebCategory from "./Level2WebCategory";

export type ListingPageTemplateProps = {
  topSlots?: Array<() => ReactNode>;
  mainSlots?: Array<() => ReactNode>;
  sideSlots?: Array<() => ReactNode>;
};

// export const StaticPages: { [key: string]: () => JSX.Element } = {
//   "about-us": AboutUs,
//   products: AboutUs,
//   "contact-us": AboutUs,
//   advertise: AboutUs,
//   contribute: AboutUs,
//   faq: Faq,
// };

const renderLevel1ThoughLevel2Path = (level1: string, level2: string) => {
  const fullPath = level1 + "/" + level2;
  if (fullPath === "forum/expert" || fullPath === "forum/zaodian") {
    return true;
  }
  return false;
};

export function getLayout({ level1 = "", level2 = "" }): () => ReactNode {
  if (level2 && !renderLevel1ThoughLevel2Path(level1, level2)) {
    return Level2WebCategory;
  }

  // if (level1 in StaticPages) {
  //   return StaticPages[level1];
  // }

  return Level1WebCategory;
}

export function isAbleToRenderUrl({
  level1 = "",
  level2 = "",
  level3 = "",
  level4 = "",
}): boolean {
  const uniqueName = [level1, level2, level3, level4].filter(Boolean).join("_");

  if (level1 !== "keywords" && !(uniqueName in navigationDictionary)) {
    return false;
  }

  return true;
}

export async function getContextJsonByUrl({
  categoryUrlPath,
}: {
  categoryUrlPath: string;
}): Promise<Record<string, unknown> | null> {
  const contextJson = await axios
    .get(`/${categoryUrlPath}/context.json`)
    .then((res) => {
      return res.status === 200 ? (res.data as Record<string, unknown>) : null;
    });

  // console.log(
  //   "listing page server: get context json by url",
  //   `/${categoryUrlPath}/context.json`,
  //   contextJson,
  // );

  return contextJson;
}
