import HomepageFullWidthBanner from "@components/HomepageFullWidthBanner/HomepageFullWidthBanner";
import SectionNavigation from "@components/SectionNavigation/SectionNavigation";
import StaticPageNavigation from "@components/StaticPageNavigation/StaticPageNavigation";
import LandingPageTemplate from "@components/Templates/LandingPageTemplate";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { Fragment, ReactElement, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Accordion from "./FaqAccording";

// import StaticAccordian from "./StaticAccordian/StaticAccordian";

type FaqPageContextType = {
  url_path: string;
  url_history: [];
  results: MenuItemType[];
  label: string;
};
type MenuItemType = {
  id: string;
  tabtitle: string;
  tabcontent: qaItem[];
  key: number;
};
type qaItem = {
  qtn: string;
  ans: string;
};
type AccordionItem = {
  title: string;
  content: string;
};

const AboutUsLayout = (): ReactElement => {
  const {
    context: {
      payload: { results, label },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: FaqPageContextType;
  }> = useRouteContext();
  const menuItems = results.map((item) => {
    return {
      key: Number(item.id),
      label: item.tabtitle,
      href: item.id,
    };
  });
  // const [isOpen, setIsOpen] = useState(false);
  const renderFullWidthBanner = () => {
    return <HomepageFullWidthBanner additionalClasses="!mb-0" />;
  };
  const [currentKey, setCurrentKey] = useState<number>(1);
  const renderSectionNavigation: () => ReactNode = () => {
    return <SectionNavigation level={""} tagName={label} />;
  };
  const handleSelect = (key: number | string) => {
    setCurrentKey(Number(key));
  };

  const { search }: { search: string; pathname: string } = useLocation();

  const searchParams = new URLSearchParams(search);
  const tab: string | undefined = searchParams.get("tab") || undefined;
  useEffect(() => {
    if (tab === "gift") {
      setCurrentKey(6);
    }
  }, [tab]);
  const accordionItems: AccordionItem[] = results[
    Number(currentKey) - 1
  ]?.tabcontent.map((item) => ({
    title: item.qtn,
    content: item.ans,
  }));

  const renderPageContent: () => ReactNode = () => {
    console.log({ results });
    return (
      <Fragment>
        <div className="layout-container articlePage">
          <div className="row">
            <div className="overflow-x-hidden px-8 pt-[16px] lg:w-2/12 lg:overflow-visible">
              <StaticPageNavigation
                staticPageMenuItems={menuItems}
                handleSelect={handleSelect}
                currentKey={currentKey}
              />
            </div>
            <div className="w-full lg:w-10/12">
              <div className="articleBody staticPage box-border max-w-full flex-1 px-[20px] text-body1 lg:px-[20px]">
                <Accordion contentItems={accordionItems} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <LandingPageTemplate
      topSlots={[renderSectionNavigation, renderFullWidthBanner]}
      mainSlots={[renderPageContent]}
    />
  );
};

export default AboutUsLayout;
