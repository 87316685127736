import { BFF_BASE_URL } from "@constants/bff";
import useParams from "@hooks/useSafeParams";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { NewslettersType } from "@typings/Data";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";

import { UNSUBCRIBE_DESC, UNSUBSCRIBE_TITLE } from "../constants/newsletter";
import Introduction from "./NewsletterIntroduction";
import ResonForm from "./NewsletterReason";
import ReNew from "./NewsletterReNew";
import Newsletters from "./NewslettersCardList";
import ResultCom from "./ResultCom";

type ChoiceType = {
  message: string;
  data: string[];
  code: number;
};

function UnsubscribeCom() {
  const [choice, setChoice] = useState<string[]>([]);
  const [addedNews, setAddedNews] = useState<string[]>([]);
  const [removedNews, setRemovedNews] = useState<string[]>([]);
  const [description, setDescription] = useState<string[]>(UNSUBCRIBE_DESC);
  const [resultShow, setResultShow] = useState<boolean>(false);
  const [hasSubscribed, setHasSubscribed] = useState<boolean>(false);
  const [newChoice, setNewChoice] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { level2 = "" } = useParams();

  const {
    context: {
      payload: {
        newsletterList: { list = [] },
        subNewsletterList = [],
      },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: {
      newsletterList: NewslettersType;
      subNewsletterList: string[];
    };
  }> = useRouteContext();

  const stableList = useMemo(() => list, [list]);
  const previousChoice = useMemo(
    () => [...new Set([...subNewsletterList, ...newChoice])],
    [subNewsletterList, newChoice],
  );

  useEffect(() => {
    if (removedNews.length) {
      setDescription([]);
    }
  }, [removedNews]);

  const getPreviousChoice = () => {
    axios
      .get<ChoiceType>(`${BFF_BASE_URL}/newsletter/${level2}`)
      .then((res) => {
        setNewChoice(res.data.data);
      })
      .catch((err: Error) => {
        console.log(`get previous choice error:${err.message}`);
      });
  };

  useEffect(() => {
    if (hasSubscribed) {
      setHasSubscribed(false);
      setAddedNews([]);
      getPreviousChoice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choice]);

  const initData = () => {
    setChoice([]);
    setAddedNews([]);
    setRemovedNews([]);
    setDescription(UNSUBCRIBE_DESC);
    setHasSubscribed(false);
    setNewChoice([]);
  };

  const scrollToTop = () => {
    if (typeof window === "undefined") return;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);
  };

  const sendNewsletter = (fn: () => void) => {
    setIsLoading(true);
    axios
      .post(`${BFF_BASE_URL}/newsletter/update`, {
        user: level2,
        newsletter: choice,
      })
      .then(({ data: { message } }) => {
        if (message === "SUCCESS") {
          fn();
          scrollToTop();
        }
      })
      .catch((err: Error) => {
        console.error(`send newsletter error:${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {resultShow ? (
        <ResultCom></ResultCom>
      ) : (
        <>
          <Introduction
            title={UNSUBSCRIBE_TITLE}
            description={description}
            addedNews={addedNews}
            removedNews={removedNews}
          ></Introduction>
          {removedNews.length ? (
            <ResonForm
              setResultShow={setResultShow}
              sendNewsletter={sendNewsletter}
              isLoading={isLoading}
              removedNews={removedNews}
              initData={initData}
            ></ResonForm>
          ) : (
            <Newsletters
              newsletters={{ title: UNSUBSCRIBE_TITLE, list: stableList }}
              choice={choice}
              setChoice={setChoice}
              previousChoice={previousChoice}
            />
          )}
          {!removedNews.length && (
            <ReNew
              isLoading={isLoading}
              sendNewsletter={sendNewsletter}
              scrollToTop={scrollToTop}
              setHasSubscribed={setHasSubscribed}
              setAddedNews={setAddedNews}
              previousChoice={previousChoice}
              choice={choice}
              setRemovedNews={setRemovedNews}
              list={stableList}
            ></ReNew>
          )}
        </>
      )}
    </>
  );
}

export default UnsubscribeCom;
