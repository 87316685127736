/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { adSlotSizes } from "@constants/ads";
import scriptState from "@stores/script";
import loadScriptContent from "@utils/loadScriptContent";
import { useEffect } from "react";
import { useSnapshot } from "valtio";

export default function useGoogleAds() {
  const { jquery } = useSnapshot(scriptState);

  useEffect(() => {
    if (typeof window !== "undefined") {
      // hide wrapper top border until contentn rendered
      // wrapper id: {id}-wrapper
      // wraper style: display:none
      googletag.cmd.push(function () {
        googletag
          .pubads()
          .addEventListener("slotRenderEnded", function (event) {
            const slotId = event.slot.getSlotElementId();
            const wrapperId = `${slotId}-wrapper`;
            const wrapperElement = document.getElementById(wrapperId);
            console.log(slotId, "slotRenderEnded", event.isEmpty);
            if (!event.isEmpty && wrapperElement) {
              wrapperElement.style.display = "";
            }
          });
      });

      googletag.cmd.push(function () {
        const prefix = "dfp-ad-";

        googletag
          .pubads()
          .getSlots()
          .forEach((_slot) => {
            // console.log("_slot from getSlots", _slot);
            const id = _slot.getSlotElementId().replaceAll(prefix, "");

            const size = Object.keys(adSlotSizes[id]).map((_key, index) => {
              const sizes = adSlotSizes[id][index];
              return sizes.toString().replaceAll(",", "x");
            });

            if (!window.googletag.slots) {
              window.googletag.slots = {};
            }

            // console.log(
            //   "_slot.getSlotElementId().replaceAll",
            //   _slot.getSlotElementId().replaceAll(prefix, ""),
            // );
            window.googletag.slots[
              _slot.getSlotElementId().replaceAll(prefix, "")
            ] = _slot;

            window.dfpTagsObj[id] = {
              adunit: _slot.getAdUnitPath(),
              slotname: _slot.getSlotElementId(),
              size: size.join(","),
            };
          });

        if (window.loaded_dfp_tags) {
          // console.log("defineSlot completed", window.dfpTagsObj);
          window.loaded_dfp_tags = JSON.stringify(window.dfpTagsObj);
          // console.log("window.dfpTagsObj", window.dfpTagsObj);
          // TODO all ordinary ads, imu1. midarticle, catfish as well, all defined before setting the flag
          window.load_ad_tag = 1;
          // console.log("defineSlot completed load_ad_tag", window.load_ad_tag);
        }
        // we keep this here
        // betterads.js contents are for checking user type . If you can manage to include it to some other place, it will be good.
        // loadScript("/_web2/betterads_v2.js", () => true, "betterads");
        // globalState.isGoogleAdsReady = true;
      });
    }
  }, []);

  useEffect(() => {
    // somehow this is complaining about jquery being undefined
    if (jquery && typeof window !== "undefined") {
      loadScriptContent(
        "gtm",
        `
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-K4JWN4Q");
    `,
      );
    }
  }, [jquery]);
}
