import Arrow from "@assets/icons/arrow-faq.svg";
import React, { useMemo, useState } from "react";

type AccordionItem = {
  title: string;
  content: string;
};

type AccordionProps = {
  contentItems: AccordionItem[];
};

const Accordion: React.FC<AccordionProps> = ({ contentItems }) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

  const toggleAccordion = (index: number) => {
    setActiveIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((i) => i !== index)
        : [...prevIndexes, index],
    );
  };

  useMemo(() => {
    setActiveIndexes([]);
  }, [contentItems]);

  return (
    <div className="w-full">
      {contentItems.map((item, index) => (
        <div key={index} className="border-b border-gray-200">
          <button
            className="flex w-full items-center justify-between  pb-[8px] pt-[16px] text-left  focus:outline-none"
            onClick={() => {
              toggleAccordion(index);
            }}
          >
            <span className="w-full text-subheader2 text-emphasis">
              {item.title}
            </span>
            <div
              className={`h-12 w-12 transform transition-transform duration-300 ${
                activeIndexes.includes(index) ? "" : "rotate-180"
              }`}
            >
              <img src={Arrow} alt="toggle arrow" className="h-12 w-12" />
            </div>
          </button>
          <div
            className={`transition-max-height overflow-hidden duration-300 ease-in-out ${
              activeIndexes.includes(index) ? "max-h-full" : "max-h-0"
            }`}
          >
            <div
              dangerouslySetInnerHTML={{ __html: item.content }}
              className="faq-node-box"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
