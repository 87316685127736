import { useCallback } from "react";

/**
 * Hook for tracking GTM events
 * @returns {Function} trackEvent - Function to track GTM events
 */
const useGtmEvent = () => {
  const trackEvent = useCallback(
    (
      customEvent: string,
      eventCategory: string,
      eventAction: string,
      eventLabel: string,
    ) => {
      if (typeof window !== "undefined") {
        console.log("gtm hooks", { eventAction, eventLabel });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        window.dataLayer = window.dataLayer || [];
        // Push event to dataLayer
        window.dataLayer.push({
          event: customEvent,
          eventCategory: eventCategory,
          eventAction: eventAction,
          eventLabel: eventLabel,
        });
      }
    },
    [],
  ); // Empty dependency array since we don't use any external values

  return trackEvent;
};

export default useGtmEvent;
