import useAmp from "@hooks/useAmp";
import useParams from "@hooks/useSafeParams";
import AmpArticlePage from "@pages/AmpArticle/AmpArticle";
import ArticlePage from "@pages/Article/Article";
import Epapers from "@pages/Epapers/Epapers";
import HomePage from "@pages/Home/Home";
import AuthorPage from "@pages/Listing/Author/Author";
import KeywordsPage from "@pages/Listing/Keywords/Keywords";
import PublicationPage from "@pages/Listing/Publication/Publication";
import Signup from "@pages/Newsletter/Signup/Signup";
import Unsubscribe from "@pages/Newsletter/Unsubscribe/Unsubscribe";
import WebCategoryPage from "@pages/WebCategory/WebCategory";
import ZBClub from "@pages/ZBClub";
import { regexMatchArticle, regexMatchArticlePreview } from "@utils/article";
import { type ReactElement } from "react";

import StaticPage from "../Others/Static/StaticPage";

/**
 * Page component that determines whether to render an ArticlePage or WebCategoryPage or any kind of pages
 * based on the URL.
 *  - If the URL matches the regex for an article, render ArticlePage
 *  - Otherwise, render WebCategoryPage
 * @returns {ReactElement}
 */
export default function PrerenderPage(): ReactElement {
  const { level1, level2, level3, level4, level5, level6 } = useParams();
  const isAmp = useAmp();
  const allPaths = [level1, level2, level3, level4, level5, level6].filter(
    Boolean,
  );

  if (!level1) {
    return <HomePage />;
  }

  if (BlackListPages.includes(level1)) {
    return <></>;
  }

  const endingPath = allPaths.at(-1);

  if (endingPath?.match(regexMatchArticle) && isAmp) {
    return <AmpArticlePage />;
  }

  if (
    endingPath?.match(regexMatchArticle) ||
    endingPath?.match(regexMatchArticlePreview)
  ) {
    return <ArticlePage />;
  }

  switch (level1) {
    case "byline":
      return <AuthorPage />;
    case "keywords":
      return <KeywordsPage />;
    case "publication":
      return <PublicationPage />;
    case "epapers":
      return <Epapers />;
    case "zbclub":
      return <ZBClub />;
    default:
      if (level1 in StaticPages) {
        return StaticPages[level1]();
      }

      if (level1 in NewsletterPages) {
        return NewsletterPages[level1]();
      }

      return <WebCategoryPage />;
  }
}

export const StaticPages: { [key: string]: () => ReactElement } = {
  "about-us": StaticPage,
  products: StaticPage,
  "contact-us": StaticPage,
  advertise: StaticPage,
  contribute: StaticPage,
  faq: StaticPage,
};

export const NewsletterPages: { [key: string]: () => ReactElement } = {
  "newsletter-signup": Signup,
  "newsletter-unsubscribe": Unsubscribe,
};

export const BlackListPages = ["embed"];
