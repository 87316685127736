/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  adSizeMappings,
  adSlotSizes,
  adTargetings,
  AdvertisementTypeEnum,
} from "@constants/ads";
import clsx from "clsx";
import { useEffect } from "react";

export type GoogleAdProps = {
  rootClassName?: string;
  type: AdvertisementTypeEnum;
  slot: string;
  id?: string;
  hideAdHeader?: boolean;
  isRenderDOM?: boolean;
};

export type AdTargetingType = {
  key: string;
  value: string | string[];
};

/**
 * This component renders an IMU ad.
 * @param rootClassName - The root class name.
 * @param type - The type of ad unit.
 * @param slot - The ad slot.
 * @param id - The id of the ad.
 * @see https://developers.google.com/doubleclick-gpt/reference
 * @returns A IMU ad component. If the script is not ready, it returns error. If the script is ready, it returns the ad.
 */
export default function GoogleAd({
  rootClassName = "",
  type,
  slot = "",
  id = generateAdvertElementId(type),
  hideAdHeader = true,
  isRenderDOM = true,
}: GoogleAdProps) {
  const adSlotSize = adSlotSizes[type];
  const adSizeMapping = adSizeMappings[type];
  const adTarget = adTargetings[type];
  /**
   * This function is called when the Google script is loaded.
   * It defines the ad slot and loads the ad.
   * @returns void
   * @see https://developers.google.com/doubleclick-gpt/reference#googletag.cmd.push
   */
  const handleDisplayAd = () => {
    try {
      // check if slot DOM is ready
      if (!document.getElementById(id)) {
        console.error(`Ad slot ${id} not found`);
        return;
      }

      window.googletag = window.googletag || { cmd: [] };

      googletag.cmd.push(function () {
        const _adSlot = googletag.defineSlot(slot, adSlotSize, id);
        // console.log("defineSlot completed", slot, window.dfpTagsObj);
        if (_adSlot) {
          _adSlot.addService(googletag.pubads());

          if (adSizeMapping) _adSlot.defineSizeMapping(adSizeMapping);

          adTarget?.forEach((target: AdTargetingType) => {
            _adSlot.setTargeting(target.key, target.value);
          });

          googletag.display(id);
        }
      });
    } catch (_error: unknown) {
      // console.log("Please refresh");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleDisplayAd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isRenderDOM ? (
    <>
      <div
        className={clsx("google-ad w-full", rootClassName)}
        id={`${id}-wrapper`}
        style={{ display: "none" }}
      >
        <div
          className="border-t-[1px] border-divider-primary text-center"
          id="google-ad-header"
        >
          {hideAdHeader ? (
            <p className="py-[4px]"></p>
          ) : (
            <p className=" py-[8px] text-label6 text-secondary">广告</p>
          )}
        </div>

        <button className="flex w-full justify-center" id={id}></button>
      </div>
    </>
  ) : null;
}

function generateAdvertElementId(type: AdvertisementTypeEnum) {
  return `dfp-ad-${type}`;
}
