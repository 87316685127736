import add from "@assets/icons/add.svg";
import right from "@assets/icons/right.svg";
import { NewslettersType } from "@typings/Data";
import { useEffect } from "react";

import Title from "./NewsletterTitle";

function NewslettersCardList({
  newsletters: { title, list },
  choice,
  setChoice,
  previousChoice,
}: {
  newsletters: NewslettersType;
  choice: string[];
  setChoice: React.Dispatch<React.SetStateAction<string[]>>;
  previousChoice?: string[];
}) {
  useEffect(() => {
    const checkedList =
      previousChoice ||
      list
        .filter((item) => item.preSelected === "true")
        .map((item) => item.oneTrustKey);
    setChoice(checkedList);
  }, []);
  return (
    <section>
      <Title title={title} />

      <div className="layout-container flex flex-wrap">
        <section className="row">
          {list.map((item) => {
            return (
              <div
                key={item.dataId}
                className="w-full px-[20px] py-[16px] lg:w-1/4"
              >
                <img
                  src={item.imageUrl}
                  alt={item.label}
                  className="w-full rounded-[4px]"
                />
                <div className="flex justify-between pt-[16px]">
                  <div className="flex-1">
                    <h2 className="mb-[8px] text-header1 text-primary">
                      {item.label}
                    </h2>
                    <p className="mb-[8px] text-header3 text-primary">
                      {item.description}
                    </p>
                    <a
                      target="_blank"
                      href={item.previewLink}
                      className="cursor-pointer text-header3 text-brand-secondary underline"
                      rel="noreferrer"
                    >
                      预览
                    </a>
                  </div>
                  <button
                    className="h-[30px] w-[30px] cursor-pointer"
                    onClick={() => {
                      //如果存在，则删除，否则添加
                      setChoice((prevChoice) =>
                        prevChoice.includes(item.oneTrustKey)
                          ? prevChoice.filter((i) => i !== item.oneTrustKey)
                          : [...prevChoice, item.oneTrustKey],
                      );
                    }}
                  >
                    {choice.includes(item.oneTrustKey) ? (
                      <img src={right} alt="right" className="w-full" />
                    ) : (
                      <img src={add} alt="add" className="w-full" />
                    )}
                  </button>
                </div>
              </div>
            );
          })}
        </section>
      </div>
    </section>
  );
}

export default NewslettersCardList;
