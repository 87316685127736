import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { WebCategoryLevel1ContextType } from "@typings/Data";
import clsx from "clsx";

import ArticleRotator from "./components/AutoRotator";
import ZbclubFAQ from "./components/FAQ";
import KeywordArticleList from "./components/KeywordArticleList";
import MembershipCard from "./components/MembershipCard";
import FacebookWall from "./components/SocialWall";

export default function ZBClubContent() {
  const {
    context: {
      payload: { singleCarouselArticles = [], pageContentList = [] },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: WebCategoryLevel1ContextType;
  }> = useRouteContext();

  return (
    <main className="main layout-container">
      <section className="grid grid-cols-4 gap-x-[40px] px-0  ">
        <div className={clsx("col-span-full py-[16px] lg:col-span-1")}>
          {singleCarouselArticles.length > 0 ? (
            <ArticleRotator articles={singleCarouselArticles} />
          ) : null}
        </div>

        <div
          className={clsx("col-span-full py-[16px]", {
            "lg:col-span-3": singleCarouselArticles.length > 0,
          })}
        >
          <MembershipCard />
        </div>
      </section>
      {/* this extra setion layer is to get rid of unwanted Card padding */}
      <section className="-mx-8 ">
        {pageContentList.map((keywordSection) => {
          if (keywordSection.articles?.length)
            return (
              <KeywordArticleList
                sectionTitle={keywordSection.label!}
                moreHref={keywordSection.href!}
                articles={keywordSection.articles}
                key={keywordSection.key}
                numberPerRow={4}
              />
            );
        })}
      </section>
      <FacebookWall />
      <ZbclubFAQ />
    </main>
  );
}
