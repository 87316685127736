import ArticleTypeIcon from "@components/ArticleTypeIcon/ArticleTypeIcon";
import Card from "@components/Card/Card";
import Link from "@components/Link/Link";
import OptimisedImage from "@components/OptimisedImage/OptimisedImage";
import SubCategoryHeader from "@components/SubCategoryHeader/SubCategoryHeader";
// import SubCategoryHeader from "@components/SubCategoryHeader/SubCategoryHeader";
import { useSpecialTopicFromDrupal } from "@hooks/ClientSideFromDrupal/useSpecialTopicFromDrupal";
import clsx from "clsx";

export default function HomepageSpecialTopic() {
  const { specialTopic } = useSpecialTopicFromDrupal();
  // console.log("HomepageSpecialTopic: ", specialTopic);
  const shouldRender =
    specialTopic && specialTopic.articles && specialTopic.articles.length > 0;

  const shouldRenderFirstArticleImage =
    specialTopic?.articles?.[0]?.imageUrl != null;

  return (
    <div>
      {shouldRender ? (
        <section className="homepage-special-topic-section">
          <div className="layout-container">
            <div className="row">
              <Card
                referrer="special-report-category"
                heading={specialTopic.displayName || specialTopic.tagName}
                isMainTopic={true}
                headingLink={specialTopic.tagURL}
                headingComponent={
                  <SubCategoryHeader
                    categories={(specialTopic.subTags || []).map((it) => {
                      return { href: it.subTagURL, label: it.subTagName };
                    })}
                  />
                }
              >
                <div className="card-content">
                  <div className="main-image">
                    <Link
                      to={specialTopic.desktopUrl || ""}
                      className="hidden w-full lg:block"
                    >
                      <img
                        className="aspect-3/2 w-full rounded-[4px]"
                        alt={specialTopic.tagName}
                        src={specialTopic.desktopImageUrl}
                      />
                    </Link>
                    <Link
                      to={specialTopic.mobileUrl || ""}
                      className="block lg:hidden"
                    >
                      <img
                        className="aspect-3/2 w-full rounded-[4px] "
                        alt={specialTopic.tagName}
                        src={specialTopic.mobileImageUrl}
                      />
                    </Link>
                  </div>
                  <ul className="w-full">
                    {specialTopic.articles?.map((article, index) => {
                      return (
                        <li key={index}>
                          <Link to={article.url as string}>
                            <div
                              className={clsx("link-content", {
                                "min-h-[81px] lg:min-h-[161px]":
                                  index === 0 && shouldRenderFirstArticleImage,
                                "min-h-[54px] lg:min-h-[110px]": index !== 0,
                              })}
                            >
                              <div
                                className={clsx({
                                  "w-[60%] pr-[8px]":
                                    index === 0 &&
                                    shouldRenderFirstArticleImage,
                                  "w-full": index !== 0,
                                })}
                              >
                                <h2
                                  className={clsx(
                                    "line-clamp-2 text-header3 text-link-headline-default hover:text-link-headline-hover",
                                  )}
                                >
                                  {article.title}
                                </h2>
                                <div className="hidden lg:block">
                                  <p
                                    className={clsx(
                                      "text-body2 text-secondary",
                                      {
                                        "line-clamp-4":
                                          index === 0 &&
                                          shouldRenderFirstArticleImage,
                                        "line-clamp-2": index !== 0,
                                      },
                                    )}
                                  >
                                    {article.summary}
                                  </p>
                                </div>
                              </div>
                              {index === 0 && shouldRenderFirstArticleImage ? (
                                <div className="relative w-[40%]">
                                  <OptimisedImage
                                    pictureRootClassName="aspect-3/2 h-[162px] w-auto rounded-[4px]"
                                    rootClassName="h-auto rounded-[4px] object-cover object-center"
                                    src={article.imageUrl}
                                    alt={article.title}
                                    loading={"lazy"}
                                    suffix={
                                      <ArticleTypeIcon
                                        itemTypeProps={article.item_type}
                                      />
                                    }
                                  />
                                </div>
                              ) : null}
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Card>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
}
