import CatfishGoogleAd from "@components/CatfishGoogleAd/CatfishGoogleAd";
import Footer from "@components/Footer/Footer";
import GAData from "@components/GAData/GAData";
import GoogleAd from "@components/GoogleAd/GoogleAd";
import Header from "@components/Header/Header";
import MobileHeader from "@components/Header/MobileHeader";
import MetaTags from "@components/MetaTags/MetaTags";
import { AdvertisementTypeEnum, GoogleAdsSlotFactory } from "@constants/ads";
import useParams from "@hooks/useSafeParams";
import viewportState from "@stores/viewport";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { useSnapshot } from "valtio";

import AboutUsLayout from "./AboutusLayout";
import FaqLayout from "./FaqLayout";

const AboutUs = (): ReactElement => {
  const { level1 = "" } = useParams();
  const title = "关于我们 | 联合早报";
  const faqTitle = "早报应用常见问题与解答 | 联合早报";
  const sectionAdsName = `aboutus`;
  const description =
    "关于我们 关注我们 联络我们 广告 投函/投稿 |《联合早报》介绍 《联合早报》是新报业媒体的旗舰华文报，也是新加坡读者最多的中文新闻媒体。";
  const faqDescription =
    "常见技术问题文字转语音功能多媒体&amp;收藏夹电子报其他问题赠阅文章";
  const { isMobileView, isDesktopView, isInAppBrowser } =
    useSnapshot(viewportState);

  const getAdBlocker = () => {
    if (typeof window !== "undefined") {
      return (window as Window).canRunAds ?? 0;
    }
  };
  console.log({ level1 });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <MetaTags
        type="website"
        title={level1 === "faq" ? faqTitle : title}
        description={level1 === "faq" ? faqDescription : description}
        slug="/"
      ></MetaTags>

      <GAData level2_ga={level1} title={title} adblocker={getAdBlocker()} />

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad abm-google-ad"
          type={AdvertisementTypeEnum.ABM}
          slot={GoogleAdsSlotFactory.abm(sectionAdsName)}
        />
      )}

      {isInAppBrowser ? null : (
        <GoogleAd
          rootClassName="masthead-google-ad min-h-[50px] justify-center p-[10px]"
          type={AdvertisementTypeEnum.LB1}
          slot={GoogleAdsSlotFactory.lb1(sectionAdsName)}
        />
      )}

      {!isDesktopView ? (
        isInAppBrowser ? null : (
          <MobileHeader rootClassName="lg:hidden" />
        )
      ) : (
        <Header rootClassName="max-lg:hidden" />
      )}
      {level1 === "faq" ? <FaqLayout /> : <AboutUsLayout />}
      {isInAppBrowser ? null : <Footer />}

      {isMobileView ? (
        isInAppBrowser ? null : (
          <CatfishGoogleAd
            slot={GoogleAdsSlotFactory.catfish(sectionAdsName)}
          />
        )
      ) : null}
    </>
  );
};

export default AboutUs;
