import plus from "@assets/icons/jia.svg";
import reduction from "@assets/icons/jian.svg";
import clsx from "clsx";

function NewsletterInfo({ type, news }: { type: string; news: string[] }) {
  return (
    <>
      <section className="py-[16px]">
        <div
          className={clsx(
            "box-border flex items-center  px-[20px] py-[8px] text-header3",
            type === "add" || type === "stepOneAdd"
              ? "bg-success-secondary"
              : "bg-secondary",
          )}
        >
          {type === "add" || type === "stepOneAdd" ? (
            <img src={plus} className="mr-[8px] h-[24px] w-[24px]" alt="" />
          ) : (
            <img
              src={reduction}
              className="mr-[8px] h-[24px] w-[24px]"
              alt=""
            />
          )}
          <div>
            {type === "add" ? (
              <p>感谢您更新了新闻电邮速递订阅选项！以下是您新增的速递:</p>
            ) : type === "stepOneAdd" ? (
              <p>谢谢您更新了免费的精选新闻电邮速递</p>
            ) : (
              <p>以下是您确定要取消的新闻电邮速递:</p>
            )}
            <ul>
              {news.map((item, index) => {
                return (
                  <li key={index} className="flex items-center">
                    <span className="mx-[8px] h-[4px] w-[4px] rounded-[50%] bg-[#464646]"></span>{" "}
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsletterInfo;
