export const REASON_DATA = [
  {
    label: "内容无法正常显示",
    checked: false,
    index: 0,
  },
  {
    label: "对内容不感兴趣",
    checked: false,
    index: 1,
  },
  {
    label: "更喜欢《联合早报》网站",
    checked: false,
    index: 2,
  },
  {
    label: "更喜欢看《联合早报》电子报",
    checked: false,
    index: 3,
  },
  {
    label: "更喜欢看《联合早报》应用",
    checked: false,
    index: 4,
  },
  {
    label: "《联合早报》电邮太多",
    checked: false,
    index: 5,
  },
  {
    label: "其它",
    checked: false,
    index: 6,
  },
];

//signup component
export const SIGNUP_TITLE = "订阅新闻速递";

export const SIGNUP_DESC = [
  "订阅《联合早报》新闻速递，便能在电子邮箱免费接收重点新加坡新闻、国际时事、观点评论与多媒体内容，快捷便利，天下大事再多也了然于胸。",
  "今日要闻”新闻速递每逢周一至周五早上发送，下午则推出“早报新明午后推荐”；每周六发出“周末版”精选新闻速递；《联合早报》执行总编辑也会在每周一亲自撰文，为读者推荐每周深度报道与观点。您只需通过表格提交电邮地址，就能启动新闻速递服务。",
];

//unsubscribe component
export const UNSUBSCRIBE_TITLE = "新闻速递电邮管理";

export const UNSUBCRIBE_DESC = [
  "以下是您已订阅的新闻速递电邮分类。请勾选或留空相应的选项，点击更新，就能调整您的新闻速递订阅。",
];

// signup page

export const SIGNUP_PAGE_TITLE = "订阅新闻速递 | 联合早报";

export const SIGNUP_PAGE_DESC =
  "订阅 zaobao.sg 精选新闻简报，《联合早报》重点新加坡新闻、国际时事、观点评论与数码特制内容直接送达您的电子邮箱，快捷便利，天下大事再多也了然于胸。 新闻简报周一至周五每日早晚两次发出，每周六推出周末精选新闻简报，执行总编辑也会在周一亲自撰文，为订户推荐每周深度报道与观点。您只需在以下表格中填写姓名与电邮地址，就能启动这个免费的精选新闻简报服务。";

// unsubscribe page

export const UNSUBSCRIBE_PAGE_TITLE = "新闻速递电邮管理 | 联合早报";

export const UNSUBSCRIBE_PAGE_DESC =
  "新加坡、中国、亚洲和国际的即时、评论、商业、体育、生活、科技与多媒体新闻，尽在联合早报。";
