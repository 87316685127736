import { useEffect, useState } from "react";

import lockSVG from "../assets/lock.svg";
import readSVG from "../assets/read.svg";
import { LG_SCREEN_BREAK_POINT } from "../constants";

export default function RulesPopup({
  anchor,
  onClose,
}: {
  anchor: HTMLElement | null;
  onClose: () => void;
}) {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (anchor) {
      const rect = anchor.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY - 210,
        left: rect.left + window.scrollX + rect.width / 2,
      });
    }
  }, [anchor]);
  return (
    <>
      {/* Add backdrop overlay */}
      <div
        className="fixed inset-0 z-40 bg-black/10"
        onClick={onClose}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            onClose();
          }
        }}
        role="button"
        tabIndex={0}
      />
      <div
        className="absolute z-50  -translate-x-1/2 animate-[slideIn_200ms_ease-out] rounded-lg bg-white  shadow-lg"
        style={{
          top: `${position.top}px`,
          left:
            window.innerWidth >= LG_SCREEN_BREAK_POINT
              ? `${position.left}px`
              : "50%",
        }}
      >
        <div className=" w-[270px] bg-black/85 px-[20px] py-[16px]">
          <div className="pb-[16px] text-center text-label3 text-[#CCC] underline">
            条款
          </div>
          <div className="mb-4 flex items-start gap-[8px]">
            <div className="flex items-center justify-center rounded-full bg-secondary p-2">
              <img
                className="h-[24px] w-[24px] max-w-fit"
                src={lockSVG}
                alt="Locker"
              />
            </div>
            <div>
              <p className="text-label4 text-primary-inverse">
                解锁早报订户专享内容
              </p>
              <p className="text-label4 text-primary-inverse">
                此权益仅限《联合早报》数码订户
              </p>
            </div>
          </div>
          <div className="mb-[16px] flex items-start gap-[8px]">
            <div className="flex items-center justify-center rounded-full bg-secondary p-2">
              <img
                className="h-[24px] w-[24px] max-w-fit"
                src={readSVG}
                alt="Reader"
              />
            </div>
            <div>
              <p className="text-label4 text-primary-inverse">
                阅读长达两周的电子报
              </p>
              <p className="text-label4 text-primary-inverse">
                此权益仅限《联合早报》数码订户
              </p>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={onClose}
              className="h-[30px] rounded-[100px] bg-button-secondary-default px-[16px]  text-button1 text-button-secondary-label"
            >
              知道了
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
