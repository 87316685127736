import { useEffect } from "react";

/**
 * @name useEmbedInit
 * @description as we use dangerouslySetInnerHTML to render page, it works fine in free article, but when we get new content in premium article, for those
 * embed elments like fb/ins/twitter/tiktok which need script to dynamicly reload(render) the element, will not excute the script in terms of dangerouslySetInnerHTML.
 * For fb and ins, it has been dealt with, but for twitter and tiktok, need to re-init, if init funcion not provided, can reload excutive script again.
 *
 */
export function useEmbedInit(renderBodyContent: string) {
  useEffect(() => {
    // reload tiktok excute script
    const existTiktokEmbedCode = document.querySelectorAll(
      "blockquote.tiktok-embed",
    );
    console.log(existTiktokEmbedCode.length, "existTiktokEmbedCode");
    if (!existTiktokEmbedCode.length) {
      return;
    } else {
      const script = document.createElement("script");
      script.src = "https://www.tiktok.com/embed.js";
      script.id = "tiktokt-script";
      script.async = true;
      document.body.appendChild(script);
    }

    // reload tiktok excute script
    const existTwitterEmbedCode = document.querySelectorAll(
      "blockquote.twitter-tweet",
    );
    console.log(existTwitterEmbedCode.length, "existTwitterEmbedCode");
    if (!existTwitterEmbedCode.length) {
      return;
    } else {
      const script = document.createElement("script");
      script.src = "https://platform.twitter.com/widgets.js";
      script.id = "twitter-script";
      script.async = true;
      document.body.appendChild(script);
    }
  }, [renderBodyContent]);
  return;
}
