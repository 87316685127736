import AmpFooter from "@components/AmpFooter/AmpFooter";
import AmpGAData from "@components/AmpGAData/AmpGAData";
import AmpHeader from "@components/AmpHeader/AmpHeader";
import MetaTags from "@components/MetaTags/MetaTags";
import { DRUPAL_ENDPOINT } from "@constants/drupal";
import useGoogleAdsTargeting from "@hooks/useGoogleAdsTargeting";
import { useScript } from "@sphtech/web2-core/hooks";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ArticleDetailContextType } from "@typings/Data";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";

import AMPArticleDefaultLayout from "./AmpArticleDefaultLayout";

export default function ArticlePage(): ReactElement {
  const { extend } = dayjs;
  extend(timezone);
  extend(utc);

  useGoogleAdsTargeting({ targeting: { key: "page", value: "article" } });
  const {
    context: {
      payload: { article },
    },
  }: CustomContext<{
    type: string;
    statusCode: number;
    payload: ArticleDetailContextType;
  }> = useRouteContext();

  console.log("JON - article payload", article);

  // meta tag
  const title = article?.metatags.title || ""; // article?.headline ? `${article.headline} | 联合早报` : "";
  const description = article?.metatags.description || "";
  const canonical_url = article?.metatags.canonical_url || "";
  const keywords = article?.metatags.keywords || "";

  /* Outbrain */
  useScript({
    id: "article-outbrain",
    src: "https://widgets.outbrain.com/outbrain.js",
    async: true,
  });

  const createTime = article?.create_time;
  const renderCreateTime = createTime
    ? dayjs
        .unix(createTime)
        .tz("Asia/Singapore")
        .locale("en")
        .format("YYYY-MM-DD HH:mm:ss")
    : "";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <AmpGAData
        articleId={article?.document_id}
        articleCueId={article?.document_id}
        articleUrl={DRUPAL_ENDPOINT + article?.url_path + "?amp"}
        articleAuthorName={article?.author ? article.author : []}
        contentType={
          (article?.assets || []).includes("has_video") ? "video" : "article"
        }
        keywords={article?.tags ? article.tags : []}
        title={title}
        pubDate={renderCreateTime}
        sections={article?.sections ? article.sections : []}
        storyThreads={(article?.story_threads || [])
          .map((i) => i.name)
          .filter(Boolean)
          .join(",")}
      ></AmpGAData>

      <MetaTags
        type="website"
        title={title}
        description={description}
        keywords={keywords}
        slug={canonical_url}
        isArticlePage
        ogTitle={article?.og_tags.title}
        ogDescription={article?.og_tags.description}
        ogImageUrl={article?.og_tags.image}
      />

      <AmpHeader />

      <AMPArticleDefaultLayout />

      <AmpFooter />
    </>
  );
}
