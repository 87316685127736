import BrightcoveVideoCarousel from "@components/BrightcoveVideoCarousel/BrightcoveVideoCarousel";
import React, { ReactElement, useEffect, useState } from "react";
const StaticContent = ({
  htmlString,
}: {
  htmlString: string;
}): ReactElement => {
  const [elements, setElements] = useState<Element[]>([]);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const allElements = Array.from(doc.body.children);
    setElements(allElements);
  }, [htmlString]);

  const isNativeTag = (tag: string) =>
    [
      "div",
      "p",
      "span",
      "a",
      "img",
      "button",
      "ul",
      "li",
      "h1",
      "h2",
      "h3",
      "br",
    ].includes(tag);
  return (
    <>
      {elements.map((element, index) => {
        const tag = element.tagName.toLowerCase();
        const parseStyle = (style: string): React.CSSProperties => {
          const styleObject: Record<string, string> = {};
          style.split(";").forEach((rule) => {
            const [property, value] = rule.split(":");
            if (property && value) {
              styleObject[property.trim()] = value.trim();
            }
          });
          return styleObject;
        };
        const props = Object.fromEntries(
          Array.from(element.attributes).map((attr) => {
            if (attr.name === "style") {
              return [attr.name, parseStyle(attr.value)];
            }
            return [
              attr.name.toLowerCase() === "playlistid"
                ? "playlistId"
                : attr.name,
              attr.value,
            ];
          }),
        ) as { playlistId: number } | Record<string, string>;
        if (isNativeTag(tag)) {
          return React.createElement(tag, {
            key: index.toString(),
            ...props,
            dangerouslySetInnerHTML: { __html: element.innerHTML },
          }) as React.ReactElement;
        } else if (tag === "brightcove") {
          const brightcoveProps = props as { playlistId: number };
          if (brightcoveProps.playlistId) {
            return (
              <div className="clear-both mx-[-2rem]" key={index.toString()}>
                <BrightcoveVideoCarousel
                  {...brightcoveProps}
                ></BrightcoveVideoCarousel>
              </div>
            );
          }
          return null;
        }
        return <></>;
      })}
    </>
  );
};

export default StaticContent;
