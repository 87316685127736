import Card from "@components/Card/Card";
import userState from "@stores/user";
import { useSnapshot } from "valtio";
type QAItemType = {
  question: string;
  answer: string;
};
const contentList: QAItemType[] = [
  {
    question: "Q: 我要如何成为早报俱乐部的VIP会员？",
    answer:
      "A: 您只要向新报业媒体有限公司订阅《联合早报》，拥有数码账号，就可晋升为早报俱乐部VIP会员。若您不肯定自己是否已是VIP会员，可在办公时间拨打6319 1800 向我们的客服人员查询。",
  },
  {
    question:
      "Q: 早报俱乐部会员与VIP会员的资格是否终身制，还是有期限？是否需要经常更新？",
    answer:
      "A: 普通会员的资格不需要更新，只要您的mySPH 账号已设置，您就可以无限期享受普通会员的福利。VIP会员的资格则会随着您的订阅配套被更新或终止变动。一旦终止订阅，您将自动从VIP会员转为普通会员，享受普通会员的福利。",
  },
];
export default function ZbclubFAQ() {
  const { user } = useSnapshot(userState);
  const handleClick = () => {
    if (window._mySPHObj !== undefined) {
      // if already logged in, jump to top
      if (!user?.loginid) {
        // GA tracking
        const url = new URL(window.location.href);
        url.searchParams.set("utm_source", "faq");
        url.searchParams.set("utm_medium", "link");
        url.searchParams.set("utm_campaign", "ZBclub");
        window.history.replaceState({}, "", url.toString());
        window._mySPHObj.openSignUp();
      }
    }
  };
  return (
    <section className="-mx-8">
      <Card isLink={false} heading="常见问题">
        <div
          className="pb-[40px] font-normal"
          style={{ fontSize: "var(--font-size-18)" }}
        >
          <div className="py-[16px]">
            <p>Q: 我要如何成为早报俱乐部会员？</p>
            {user?.loginid ? (
              <p>A: 您已拥有早报的mySPH账号，已是会员。</p>
            ) : (
              <p>
                A: 您只需
                <button className="underline" onClick={handleClick}>
                  注册
                </button>
                一个早报的mySPH账号，就可立刻成为会员。
              </p>
            )}
          </div>
          {contentList.map((qaItem, index) => (
            <QAItem
              question={qaItem.question}
              answer={qaItem.answer}
              key={index}
            />
          ))}
        </div>
      </Card>
    </section>
  );
}

const QAItem = ({ question, answer }: QAItemType) => {
  return (
    <div className="py-[16px]">
      <p dangerouslySetInnerHTML={{ __html: question }} />
      <p dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
  );
};
